import React from 'react';

const InfosBexio = ({businessData, activeUser, handleUserClick, contactPeople}) => {
    return (
        <div>
            <div className={"mb-20"}>

                <h2 className={"text-left"}>Informations générales</h2>
                <ul>
                    <li
                        key={businessData}
                        className={businessData.id === activeUser.id ? 'md-user-selected-business gap-10' : 'gap-10'}
                        onClick={() => handleUserClick(businessData)}
                    >
                                        <span className={"user-pp bg-eee  border-solid-2px color-third"}>
                                            <i className={"fa-solid fa-industry md-avatar display-flex align-items-center justify-content-center m-0"}/>
                                        </span>
                        <div className="md-user-info">
                            <p className="md-user-name">{businessData.name_1}</p>
                            <p className="md-user-username">{businessData.name_2}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <h2 className={"text-left"}>Personnes de contact</h2>
            <ul>
                {contactPeople.map((user) => (<li
                    key={user.id}
                    className={user.id === activeUser.id ? 'md-user-selected gap-10' : 'gap-10'}
                    onClick={() => handleUserClick(user)}
                >
                    {
                        (user.name_1 && user.name_2) ? <span
                                className="user-pp display-flex" style={{fontSize: ".7rem", marginRight: 20}}
                            >{user.name_1.charAt(0).toUpperCase() + user.name_2.charAt(0).toUpperCase()}</span>
                            :
                            (!user.name_2) && <span
                                className="user-pp display-flex" style={{fontSize: ".7rem"}}
                            >{user.name_1.charAt(0).toUpperCase() + user.name_1.charAt(1).toUpperCase()}</span>
                    }

                    <div className="md-user-info">
                        <p className="md-user-name ">{user.name_1}</p>
                        <p className="md-user-username">{user.name_2}</p>
                    </div>
                </li>))}
            </ul>
        </div>
    );
};

export default InfosBexio;