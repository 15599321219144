import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import '../../assets/css/Customer.css';
import { useParams } from "react-router-dom";

const SectionTechniqueDetailsResume = () => {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const customerId = parseInt(id);

    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_customer_all_technical_data/`, { customer_id: customerId })
            .then((res) => {
                console.log(res.data.result);
                setData(res.data.result);
            })
            .catch((err) => console.error(err));
    }, [customerId]);

    return (
        <div className="customer-view-main-container">
            <div className="display-flex flex-direction-column gap-20">
                <div
                    className="customer-view-container display-flex flex-direction-column gap-20 p-20 border-color-ddd border-solid-2px border-radius-10"
                    style={{ minHeight: 1 }}>
                    <div className="customer-view-detail display-flex justify-content-space-between align-items-center">
                        <span className="customer-view-label">Prestataire de mails actuels</span>
                        <span
                            className="customer-view-value rounded display-flex align-items-center justify-content-center"
                            style={{ backgroundColor: data[0]?.customer_email_org_id ? "aqua" : "#e2fb28c4" }}>
                            {(data[0]?.customer_email_org_id && data[0]?.customer_email_org_id !== 5) ? (
                                <span>
                                    <img
                                        src={`https://x.solution-digitale.ch/assets/customers/orgs/${data[0]?.email_org_name}.png`}
                                        className="display-flex align-items-flex-end"
                                        style={{ height: 35, width: 150, objectFit: 'contain' }}
                                        alt="Email provider logo"
                                    />
                                </span>
                            ) : (data[0]?.customer_email_org_id === 5) ? (
                                <span className="display-flex justify-content-center align-items-center"
                                      style={{ height: 35, width: 150 }}>
                                    Autre
                                </span>
                            ) : (
                                <span className="display-flex justify-content-center align-items-center"
                                      style={{ height: 35, width: 150 }}>
                                    Non renseigné
                                </span>
                            )}
                        </span>
                    </div>
                    <div className="customer-view-detail display-flex justify-content-space-between align-items-center">
                        <span className="customer-view-label">Hébergeur web</span>
                        <span
                            className="customer-view-value rounded display-flex align-items-center justify-content-center"
                            style={{
                                backgroundColor: data[0]?.customer_provider ? "aqua" : "#e2fb28c4",
                                height: 35,
                                width: 150
                            }}>
                            <span className="text-center">
                                {data[0]?.customer_provider ? data[0]?.customer_provider : 'Non renseigné'}
                            </span>
                        </span>
                    </div>
                    <div className="customer-view-detail display-flex justify-content-space-between align-items-center">
                        <span className="customer-view-label">Zone DNS</span>
                        <span
                            className="customer-view-value rounded display-flex align-items-center justify-content-center"
                            style={{
                                backgroundColor: data[0]?.dns_zone ? "aqua" : "#e2fb28c4",
                                height: 35,
                                width: 150
                            }}>
                            <span className="text-center">
                                {data[0]?.dns_zone ? <i className="fas fa-check" /> : 'Non renseigné'}
                            </span>
                        </span>
                    </div>
                    <div className="customer-view-detail display-flex justify-content-space-between align-items-center">
                        <span className="customer-view-label">Domaine</span>{console.log(data[0])}
                        <span
                            className="customer-view-value rounded display-flex align-items-center justify-content-center"
                            style={{
                                backgroundColor: data[0]?.customer_domain_id ? "aqua" : "#e2fb28c4",
                                height: 35,
                                width: 150
                            }}>
                            <span className="text-center">
                                {data[0]?.customer_domain_id ? <i className="fas fa-check" /> : 'Non renseigné'}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="customer-view-container display-flex flex-direction-column gap-20 p-20 border-color-ddd border-solid-2px border-radius-10">
                    <div className="customer-view-detail display-flex flex-direction-column">
                        <span className="customer-view-label mb-20">Contact technique</span>

                        <span className="customer-view-value display-flex flex-direction-column gap-10">
                            <div className="display-flex gap-20">
                                                            <i className="fas fa-user color-third"/>
<div className={"display-flex gap-5"}>
                                {data[0]?.firstname && <span>{data[0]?.firstname}</span>}
                                {data[0]?.name && <span>{data[0]?.name}</span>}</div>
                            </div>
                            {data[0]?.mail && (
                                <div className="display-flex gap-20">
                                    <i className="fas fa-envelope color-third"/>
                                    <span>{data[0]?.mail}</span>
                                </div>
                            )}
                            {data[0]?.phone && (
                                <div className="display-flex gap-20">
                                    <i className="fas fa-phone color-third"/>
                                    <span>{data[0]?.phone}</span>
                                </div>
                            )}
                            {data[0]?.factory && (
                                <div className="display-flex gap-20">
                                    <i className="fas fa-building color-third"/>
                                    <span>{data[0]?.factory}</span>
                                </div>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionTechniqueDetailsResume;
