import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Chart from "../components/Chart";
import SearchBar from "../components/SearchBar";
import Table from "../components/Table";
import { API_BASE_URL } from "../config";
import Loading from "../components/Loading";

const SitesPublies = () => {
    const [prod, setProd] = useState(null);
    const [published, setPublished] = useState(null);
    const [sortBy, setSortBy] = useState({ column: null, ascending: true });
    const [loading, setLoading] = useState(false);

    const columnMapping = {
        "Publié le": "date",
        'Site URL': "url",
        "Admin URL": "url",
        "Version XGrid": "version",
        "Serveur": "server",
        "Secteur": "branch_names",
        "Console": "sended",
        "Client":"firm"
    };

    useEffect(() => {
        document.title = 'X | Sites publiés'
        fetchPublishedData();
    }, []);

    const fetchPublishedData = () => {
        axios.post(`${API_BASE_URL}/get_published`)
            .then(response => {
                const filteredData = response.data.filter(item =>
                    !item.url.includes('solution-digitale') &&
                    !item.url.includes('xgrid') &&
                    !item.url.includes('00_') &&
                    !item.url.includes('01')
                );

                const sortedData = filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

                const formattedData = sortedData.map(item => ({
                    ...item,
                    date: new Date(item.date).toLocaleDateString('ch-FR'),
                    firm: item.firm
                }));

                setProd(formattedData);
                setPublished(formattedData);

                if (formattedData.length > 0) {
                    const requests = formattedData.map(item => {
                        const bexio_id = item.bexio;
                        return axios.post(`${API_BASE_URL}/get_customer_via_bexio_id`, { bexio_id })
                            .then(res => ({
                                ...item,
                                customer: res.data,
                            }))
                            .catch(error => {
                                console.error(`Error fetching customer data for ${bexio_id}:`, error);
                                return { ...item, customer: null };
                            });
                    });

                    Promise.all(requests)
                        .then(allMergedData => {
                            setPublished(allMergedData);
                        });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({ column: actualColumn, ascending });
    };

    const updateWebsites = () => {
        setLoading(true);
        axios.post(`${API_BASE_URL}/update_published`)
            .then(response => {
                // Optionally handle response here
            })
            .catch(error => {
                console.error('Error updating published sites:', error);
            })
            .finally(() => {
                setLoading(false);
                // Refetch the published data after the update is done
                fetchPublishedData();
            });
    };

    const publishedArray = Array.isArray(published) ? published : [];
    const sortedArray = [...publishedArray].sort((a, b) => {

        if (sortBy.column === 'date') {
            return sortBy.ascending ? a.timestamp - b.timestamp : b.timestamp - a.timestamp;
        } else if (sortBy.column === 'version') {
            const versionA = a.version === null ? '0' : a.version;
            const versionB = b.version === null ? '0' : b.version;
            return sortBy.ascending ? versionA.localeCompare(versionB) : versionB.localeCompare(versionA);
        } else if (sortBy.column === 'url') {
            return sortBy.ascending ? a.url.localeCompare(b.url) : b.url.localeCompare(a.url);
        } else if (sortBy.column === 'firm') {
            return sortBy.ascending ? a.firm.localeCompare(b.firm) : b.firm.localeCompare(a.firm);
        }
        else if (sortBy.column === 'server') {
            return sortBy.ascending ? a.server.localeCompare(b.server) : b.server.localeCompare(a.server);
        } else if (sortBy.column === 'branch_names') {
            return sortBy.ascending
                ? (a.branch_names ?? '').localeCompare(b.branch_names ?? '')
                : (b.branch_names ?? '').localeCompare(a.branch_names ?? '');
        } else if (sortBy.column === 'sended') {
            return sortBy.ascending ? a.sended - b.sended : b.sended - a.sended;
        }
        return 0;
    });

    return (
        <Fragment>
            <div>
                <h2>Liste des sites publiés</h2>
                <Chart data={prod} type={'area-spline'} dateField={'date'} />
                <SearchBar
                    totalPositions={prod}
                    setPublished={setPublished}
                    totalPositionsLength={publishedArray}
                    searchLabel={'sites trouvés'}
                />
                <button className={"button-third"} style={{ marginBottom: 20 }} onClick={updateWebsites}>
                    {loading ? "Mise à jour en cours..." : "Mettre à jour"}
                </button>

                {loading ? (
                    <Loading />
                ) : (
                    <Table
                        typeOfPosition={"finished"}
                        data={sortedArray}
                        handleSort={handleSort}
                        sortBy={sortBy}
                        hidePagination={true}
                        headersData={['date', 'url', 'firm', 'branch_names', 'url_admin', 'version', 'server', 'sended']}
                        headers={["Publié le", "Site URL", 'Client', 'Secteur', "Admin URL", "Version XGrid", "Serveur", "Console"]}
                    />
                )}
            </div>
        </Fragment>
    );
};

export default SitesPublies;
