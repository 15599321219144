import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {API_BASE_URL} from "../config";

const CreationTaches = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        document.title = 'X | Création des tâches par défaut';
    }, []);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const downloadLatestTasks = () => {
        axios.get(`${API_BASE_URL}/download_default_tasks`, {
            responseType: 'blob', // Ensures the response is treated as a binary blob
        })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error('Failed to download file');
                }

                // Create a URL for the blob and initiate a download
                const url = window.URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'default_tasks.xlsx'; // Name of the file to be downloaded
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => console.error('Error downloading the file:', err));
    };


    const updateTasks = () => {
        if (!selectedFile) {
            alert('Veuillez sélectionner un fichier.');
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile); // Attach the file
        formData.append("user_id", localStorage.user_id); // Send user ID if needed

        axios.post(`${API_BASE_URL}/import_default_tasks_into_db`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then(response => {
                alert('Les tâches par défaut ont été importées avec succès.');
            })
            .catch(error => {
                console.log(error);
                alert('Une erreur s\'est produite lors de l\'importation.');
            });
    };

    return (<div className={""}>
        <div className={"content"}>
            <h2>Importation des tâches par défaut</h2>

            <div className={"display-flex flex-direction-column gap-10"}>

                <div className={"display-flex align-items-baseline gap-10"}>
                    1. Télécharger la dernière version en ligne actuellement

                    <span className={"gap-10 align-items-baseline download-btn mb-20 cursor-pointer"}
                          onClick={downloadLatestTasks}>
                            <i className="fa-solid fa-file-arrow-down"></i>
                        </span>


                </div>

                <div className={"display-flex"}>
                    <div className={"display-flex align-items-baseline gap-10"}>

                        2. Uploader le fichier mis à jour
                        <form id="uploadForm">
                            <input
                                type="file"
                                className="form-control"
                                accept=".xlsx, .xls"
                                onChange={handleFileChange}
                            />
                            <span className={"gap-10 align-items-baseline download-btn-inverted mb-20 cursor-pointer"}
                                    onClick={updateTasks}>Mettre à jour <i className="fa-solid fa-file-arrow-up"></i>
                            </span>
                        </form>
                    </div>
                </div>

            </div>


        </div>
    </div>);
};

export default CreationTaches;
