import React, {useEffect, useState, Fragment} from 'react';
import {Link, NavLink} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../config";
import MenuOption from "./MenuOption";

const MobileNavbar = ({toggleNavbar}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loadingAuth, setLoadingAuth] = useState(true); // New loading state for auth check

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('user_id');

            if (token && userId) {
                try {
                    const response = await axios.post(`${API_BASE_URL}/check_auth`, {token, userId});
                    if (response.data && response.data.status === 'ok') {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        localStorage.removeItem('token');
                        localStorage.removeItem('user_id');
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
            setLoadingAuth(false); // Set loading to false after auth check
        };

        checkAuth();
    }, []);

    // While the auth check is loading, return null or a placeholder
    if (loadingAuth) {
        return null; // or <div>Loading...</div> for a loading indicator
    }

    return (<Fragment>
        <style>
            {`
                a.active {
                    font-weight: bold;
                    color: #007bff;
                }

                a.active > .nav-link {
                    background-color: var(--primary);
                    border-radius: 10px;
                    transition: background-color .3s ease;
                    cursor: pointer;
                    color: white;
                }

                a.active > h4 {
                    color: #007bff;
                }

                .navbar-container {
                    transition: all 0.3s ease-in-out;
                }
            `}
        </style>

        <div className={'navbar-container'} style={{display: "grid"}}>
            <div className={"navbar-main-container"}>
                <Fragment>
                    <div className={"navbar-title"} onClick={toggleNavbar}>
                        <Link to={'/'} activeclassname="active">
                            <div className={"navbar-title"}>
                                <img
                                    src={'https://solution-digitale.ch/wp-content/uploads/2021/03/logo-solution-digitale-300-1.png'}
                                    style={{maxWidth: "200px"}}
                                    alt={'Logo Solution-Digitale'}
                                />
                            </div>
                        </Link>
                    </div>
                </Fragment>
                {isAuthenticated && (<Fragment>
                    <div className={"navbar-title title-first"}>
                        <div className={"navbar-sub-title"}>
                            <div className={"display-flex"}>
                                <i className="fa-regular fa-bars-progress mr-10 color-third"></i>
                                <div className={"display-grid flex"}>
                                    <div>
                                        <h3 className={"color-third h3-navbar"}>
                                            Tâches
                                        </h3>
                                    </div>
                                    <div className={"display-flex"}>
                                        <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                            <NavLink to={'/liste-taches'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Mes tâches</h4>
                                            </NavLink>
                                            {localStorage.user_role === "Administrateur" && (
                                                <NavLink to={'/liste-toutes-taches'} activeclassname="active">
                                                    <h4 className={"nav-link"} onClick={toggleNavbar}>Liste des tâches</h4>
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"navbar-title"}>
                        <div className={"navbar-sub-title"}>
                            <div className={"display-flex"}>
                                <i className="fa-regular fa-list-check mr-10 color-third"></i>
                                <div className={"display-grid flex"}>
                                    <div>
                                        <h3 className={"color-third h3-navbar"}>
                                            Bexio
                                        </h3>
                                    </div>
                                    <div className={"display-flex"}>
                                        <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                            <NavLink to={'/positions-en-cours'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Projets en cours</h4>
                                            </NavLink>
                                            <NavLink to={'/positions-en-suivi'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Projets en suivi</h4>
                                            </NavLink>
                                            <NavLink to={'/positions-terminees'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Projets terminés</h4>
                                            </NavLink>
                                            {localStorage.user_role === "Administrateur" && (
                                                <NavLink to={'/positions-supprimees'} activeclassname="active">
                                                    <h4 className={"nav-link"} onClick={toggleNavbar}>Projets supprimés récemment</h4>
                                                </NavLink>
                                            )}
                                            <NavLink to={'/clients'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Clients</h4>
                                            </NavLink>
                                            <NavLink to={'/produits'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Tous les produits</h4>
                                            </NavLink>
                                            <NavLink to={'/creation-taches'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Importer les tâches par défaut</h4>
                                            </NavLink>
                                            {localStorage.user_role === "Administrateur" && (
                                                <NavLink to={'/maj-bexio'} activeclassname="active">
                                                    <h4 className={"nav-link"} onClick={toggleNavbar}>Mise à jour Bexio</h4>
                                                </NavLink>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"navbar-title"}>
                        <div className={"navbar-sub-title"}>
                            <div className={"display-flex"}>
                                <i className="fa-solid fa-ticket mr-10 color-third"></i>
                                <div className={"display-grid flex"}>
                                    <div>
                                        <h3 className={"color-third h3-navbar"}>
                                            Tickets
                                        </h3>
                                    </div>
                                    <div className={"display-flex"}>
                                        <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                            <NavLink to={'/tickets'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Tickets</h4>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"navbar-title"}>
                        <div className={"navbar-sub-title"}>
                            <div className={"display-flex"}>
                                <i className="fa-regular fa-globe-pointer mr-10 color-third"></i>
                                <div className={"display-grid flex"}>
                                    <div>

                                        <h3 className={"color-third h3-navbar"}>
                                            Sites internet
                                        </h3>
                                    </div>
                                    <div className={"display-flex"}>
                                        <div className={"display-grid grid-navbar"} style={{width: "100%"}}>

                                            <NavLink to={'/sites-publies'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Sites publiés</h4>
                                            </NavLink>
                                            <NavLink to={'/sites-visu'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Sites visu</h4>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"navbar-title"}>
                        <div className={"navbar-sub-title"}>
                            <div className={"display-flex"}>
                                <i className="fa-regular fa-screwdriver-wrench mr-10  color-third"></i>
                                <div className={"display-grid flex"}>
                                    <div>
                                        <h3 className={"color-third h3-navbar"}>
                                            Outils
                                        </h3>
                                    </div>
                                    <div className={"display-flex"}>
                                        <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                            {/*
                        <NavLink to={'/calendrier'} activeclassname="active">
                            <h4 className={"nav-link"} onClick={toggleNavbar}>
                                <MenuOption type={'wip'}>Calendrier</MenuOption>
                            </h4>
                        </NavLink>

                        <NavLink to={'/trello'} activeclassname="active">
                            <h4 className={"nav-link"} onClick={toggleNavbar}>
                                <MenuOption type={'wip'}>Trello</MenuOption>
                            </h4>
                        </NavLink>
                        */}
                                            <NavLink to={'/seo'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Meta Generator</h4>
                                            </NavLink>
                                            <NavLink to={"/seo-checker"} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>SEO Checker</h4>
                                            </NavLink>
                                            <NavLink to={'/qrcodes'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>QRCodes</h4>
                                            </NavLink>
                                            <NavLink to={'/emails'} activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>Email</h4>
                                            </NavLink>
                                            <NavLink to="/dns-lookup" activeclassname="active">
                                                <h4 className={"nav-link"} onClick={toggleNavbar}>DNS Lookup</h4>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {localStorage.user_role === "Administrateur" && (

                        <Fragment>
                            <div className={"navbar-title"}>
                                <div className="navbar-sub-title">
                                    <div className={"display-flex"}>
                                        <i className="fa-solid fa-lock mr-10 color-third"></i>
                                        <div className={"display-grid flex"}>
                                            <div>
                                                <h3 className={"color-third h3-navbar"}>
                                                    Gestion
                                                </h3>
                                            </div>
                                            <div className={"display-flex"}>
                                                <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                                    <NavLink to="utilisateurs" activeclassname="active">
                                                        <h4 className={"nav-link"} onClick={toggleNavbar}>Liste des utilisateurs</h4>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"navbar-title"}>
                                <div className="navbar-sub-title">
                                    <div className={"display-flex"}>
                                        <i className="fa-solid fa-chart-simple mr-10 color-third"></i>
                                        <div className={"display-grid flex"}>
                                            <div>
                                                <h3 className={"color-third h3-navbar"}>
                                                    Reporting
                                                </h3>
                                            </div>
                                            <div className={"display-flex"}>
                                                <div className={"display-grid grid-navbar"} style={{width: "100%"}}>
                                                    <NavLink to="reporting" activeclassname="active">
                                                        <h4 className={"nav-link"} onClick={toggleNavbar}>
                                                            <MenuOption type={'wip'}>Vue de la production</MenuOption>
                                                        </h4>
                                                    </NavLink>
                                                    <NavLink to="productivite" activeclassname="active">
                                                        <h4 className={"nav-link"} onClick={toggleNavbar}>
                                                            <MenuOption type={'wip'}>Productivité</MenuOption>
                                                        </h4>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>)}
                </Fragment>)}
            </div>

        </div>
    </Fragment>);
};

export default MobileNavbar;
