import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import ReportingChart from "../components/ReportingChart";
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/css/Reporting.css';
import '../assets/css/ReportingGrid.css';
import {fr} from 'date-fns/locale';
import ReportingCard from "../components/ReportingCard";
import {formatDateBeautiful, getDateRange} from '../utils/dateUtils';
import Tasks from "../components/Tasks";
import {NavLink} from "react-router-dom";
import UserTimeAmountChart from "../components/UserTimeAmountChart";
import RenewalsChart from "../components/Reporting/RenewalsChart";
import Table from "../components/Table";
import SearchBar2 from "../components/SearchBar2";

registerLocale('fr', fr);

const Reporting = () => {
    const [totalTasks, setTotalTasks] = useState([]);
    const [datasFound, setDatasFound] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('thisWeek'); // Default filter
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [formattedStartDate, setFormattedStartDate] = useState(new Date());
    const [formattedEndDate, setFormattedEndDate] = useState(new Date());
    const [proratedData, setProratedData] = useState([]);
    const [renewals, setRenewals] = useState([]);
    const [isCustom, setIsCustom] = useState(false);
    const [renewalYear1, setRenewalYear1] = useState(0);  // Initialize to 0
    const [renewalYear2, setRenewalYear2] = useState(0);  // Initialize to 0
    const [renewalYear3, setRenewalYear3] = useState(0);  // Initialize to 0
    const [totalRenewalAmount, setTotalRenewalAmount] = useState(0);  // Initialize to 0
    const [dailyAvg, setDailyAvg] = useState(0);  // Initialize to 0
    const [totalAmount, setTotalAmount] = useState(0);  // Initialize to 0
    const datePickerRef = useRef(null);

    useEffect(() => {
        document.title = 'X | Vue de la production'
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsCustom(false);
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // Group tasks by user and calculate total time spent per user
    const userTimeMap = totalTasks.reduce((acc, {username, time_spent, task_price_prorata}) => {
        const [hours, minutes, seconds] = time_spent.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes + Math.floor(seconds / 60);

        if (!acc[username]) {
            acc[username] = {totalMinutes: 0, totalPrice: 0}; // Initialize if user doesn't exist
        }

        acc[username].totalMinutes += totalMinutes; // Add time in minutes
        acc[username].totalPrice += task_price_prorata; // Sum up task_price_prorata

        return acc;
    }, {});

    useEffect(() => {
        console.log(startDate)
        if (selectedFilter !== 'custom') {
            fetchData(selectedFilter); // Fetch data based on selected filter
        } else {
            fetchData('custom', startDate, endDate); // Fetch data for custom duration
        }
    }, [selectedFilter, startDate, endDate, formattedStartDate, formattedEndDate]);

    useEffect(() => {
        // Calculate the daily average whenever the total amounts or prorated data change
        const calculateUniqueTaskDays = () => {
            const uniqueDays = new Set();
            proratedData.forEach(task => {
                if (task.finish_date) {
                    const finishDate = new Date(task.finish_date).toISOString().split('T')[0];
                    uniqueDays.add(finishDate);
                }
            });
            return uniqueDays.size || 1;  // Avoid division by zero
        };

        const numOfDays = calculateUniqueTaskDays();
        const totalWithRenewal = totalAmount + totalRenewalAmount;
        const avg = numOfDays > 0 ? totalWithRenewal / numOfDays : 0;
        setDailyAvg(avg);

    }, [totalAmount, totalRenewalAmount, proratedData]);

    const fetchData = (filter, customStartDate, customEndDate) => {
        const dateRange = getDateRange(filter, customStartDate, customEndDate);
        const formattedStartDate = dateRange[0];
        const formattedEndDate = dateRange[dateRange.length - 1];

        setFormattedStartDate(formattedStartDate)
        setFormattedEndDate(formattedEndDate)

        axios.post(`${API_BASE_URL}/get_prorata_for_position`, {
            startDate: formattedStartDate, endDate: formattedEndDate
        })
            .then(response => {
                const proratedData = response.data.datas;
                setProratedData(proratedData);
                setRenewals(response.data.renewals)
                console.log(response.data)
                setTotalTasks(proratedData);
                setDatasFound(proratedData);

                // Calculate the sum of the prorated amounts
                const totalAmount = proratedData.reduce((sum, item) => sum + (parseFloat(item.task_price_prorata) || 0), 0);
                setTotalAmount(totalAmount);
            })
            .catch(error => {
                console.error('Error retrieving prorated task data:', error);
            });

        // API call: Get renewal amount data
        axios.post(`${API_BASE_URL}/get_renewals_details`, {
            startDate: formattedStartDate, endDate: formattedEndDate
        })
            .then(res => {
                const renewalData = res.data;

                // Extract all position_total values into an array
                const totalRenewalAmountArray = renewalData.map(item => item.amount);
                setTotalRenewalAmount(totalRenewalAmountArray);

                // Calcul du total de renouvellement
                const totalRenewal = totalRenewalAmountArray.reduce((sum, amount) => sum + amount, 0);
                setTotalRenewalAmount(totalRenewal);

                // Temporary arrays to hold renewals for each year
                const tempRenewals1 = [];
                const tempRenewals2 = [];
                const tempRenewals3 = [];

                // Loop through each renewal item and categorize it based on product_code
                renewalData.forEach(item => {

                    // 1è année
                    if (item.article_id === 262 || item.article_id === 247 || item.article_id === 245 || item.article_id === 410 || item.article_id === 239 || item.article_id === 235 || item.article_id === 237 || item.article_id === 243 || item.article_id === 241 || item.article_id === 274 || item.article_id === 229 || item.article_id === 400 || item.article_id === 223 || item.article_id === 412 || item.article_id === 226 || item.article_id === 402) {
                        tempRenewals1.push(item);
                    }
                    // 2è année
                    else if (item.article_id === 236 || item.article_id === 272|| item.article_id === 318 || item.article_id === 246 || item.article_id === 244 || item.article_id === 416 || item.article_id === 238 || item.article_id === 399 || item.article_id === 236 || item.article_id === 242 || item.article_id === 240 || item.article_id === 275 || item.article_id === 159 || item.article_id === 401 || item.article_id === 157 || item.article_id === 413 || item.article_id === 158 || item.article_id === 403) {
                        tempRenewals2.push(item);
                    }
                    // Renouvellements tacites
                    else if (item.article_id === 332 || item.article_id === 367 || item.article_id === 186 || item.article_id === 331 || item.article_id === 371 || item.article_id === 372 || item.article_id === 332 || item.article_id === 365 || item.article_id === 366 || item.article_id === 341 || item.article_id === 342 || item.article_id === 336 || item.article_id === 340 || item.article_id === 339) {
                        tempRenewals3.push(item);
                    }
                });
                setRenewalYear1 (tempRenewals1.reduce((total, item) => total + item.amount, 0));

// Sum for 2ème année
                setRenewalYear2(tempRenewals2.reduce((total, item) => total + item.amount, 0));

// Sum for Renouvellements tacites (3ème groupe)
                setRenewalYear3(tempRenewals3.reduce((total, item) => total + item.amount, 0));


            })
            .catch(error => {
                console.error('Erreur de récupération des renouvellements :', error);
            });
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setIsCustom(filter === 'custom');
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            setSelectedFilter('custom');
            setIsCustom(true);
        }
    };


    return (<div>
        <h2 className={"color-third"}>Vue de la production</h2>

        <div className="parent display-flex mb-20">
            <div className="flex-1">
                <ReportingCard header={"Revenus de production pure"}
                               data={`${(totalAmount).toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-sack-dollar'}/>
            </div>
            <div className="flex-1">
                <ReportingCard header={"Abonnements 1ère année"}
                               data={`${renewalYear1.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-money-bill'}/>
            </div>

            <div className="flex-1">
                <ReportingCard header={"Abonnements 2e année"}
                               data={`${renewalYear2.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-money-bills'}/>
            </div>

            <div className="flex-1">
                <ReportingCard header={"Renouvellements tacites"}
                               data={`${renewalYear3.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-regular fa-refresh'}/>
            </div>


            <div className="flex-1">
                <ReportingCard header={"Tâches terminées"}
                               data={`${totalTasks.length}`}

                               icon={'fa-list'}/>
            </div>
            <div className="flex-1">
                <ReportingCard header={"Moyenne quotidienne"}
                               data={`${dailyAvg.toLocaleString('en-CH', {minimumFractionDigits: 2})} CHF`}
                               icon={'fa-calendar-check'}/>
            </div>
            <div className="flex-1">
                <ReportingCard
                    header={"Total"}
                    data={`${(totalAmount + totalRenewalAmount).toLocaleString('en-CH', {
                        minimumFractionDigits: 2, maximumFractionDigits: 2
                    })} CHF`}
                    icon={'fa-sack-dollar'}
                />
            </div>
        </div>

        <div className="parent second-parent-container-reporting mb-20">
            <div className="display-flex position-relative report-chart flex-direction-row flex-2">
                <div className="flex-4 display-flex flex-direction-column w-100">
                    <div className={"bg-eee border-bottom-1px-ccc -radius-10"} style={{borderRadius: "10px 10px  0 0"}}>
                        <h2 className={"color-third p-10 mt-0 mb-0"}>Revenus générés</h2>
                    </div>
                    <div className={"p-20"}>

                        <ReportingChart data={{proratedData: proratedData, renewals: renewals}} selectedFilter={selectedFilter}
                                        customStartDate={startDate}
                                        customEndDate={endDate}/>
                    </div>

                    <div className={"display-grid p-20"}>

                        <div className={"display-flex mb-10"}>
                            <button
                                className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisWeek' ? 'active-reporting' : ''}`}
                                onClick={() => handleFilterChange('thisWeek')}
                            >
                                Cette semaine
                            </button>
                            <button
                                className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastWeek' ? 'active-reporting' : ''}`}
                                onClick={() => handleFilterChange('lastWeek')}
                            >
                                La semaine passée
                            </button>
                        </div>
                        <div className={"display-flex mb-10"}>

                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisMonth' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisMonth')}
                            >
                                Ce mois
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastMonth' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('lastMonth')}
                            >
                                Le mois passé
                            </button>

                        </div>
                        <div className={"display-flex mb-10"}>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'thisYear' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('thisYear')}
                            >
                                Cette année
                            </button>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn ${selectedFilter === 'lastYear' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('lastYear')}
                            >
                                L'année passée
                            </button>

                        </div>
                        <div className={"display-flex mb-10"}>
                            <button style={{width: "33%"}}
                                    className={`margin-0 flex-1 filter-btn-custom ${selectedFilter === 'custom' ? 'active-reporting' : ''}`}
                                    onClick={() => handleFilterChange('custom')}
                            >

                                Durée personnalisée
                            </button>
                        </div>
                        {isCustom && (<div className="date-picker-container" ref={datePickerRef}>
                            <DatePicker
                                locale="fr"
                                selected={startDate}
                                onChange={handleDateChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                calendarStartDay={1} // Monday as the first day of the week
                            />
                        </div>)}
                    </div>
                </div>

            </div>
        </div>

        <div className={"display-flex gap-20 mb-20 container-1400-col"}>
            <div
                className="display-flex position-relative report-chart flex-direction-column flex-1 bg-eee border-bottom-1px-ccc -radius-10"
                style={{alignItems: "unset", justifyContent: "flex-start"}}>
                <div
                    className="text-decoration-none bg-eee border-bottom-1px-ccc -radius-10"
                    style={{borderRadius: "10px 10px 0 0"}}>

                    <h3 className={"color-third p-10 mt-0"}>Collaborateurs</h3>
                </div>
                <div className={"mt-20 mb-20"}>

                    <div className={"p-20"}>

                        <UserTimeAmountChart userTimeMap={userTimeMap} label1={'Temps total (heures)'}
                                             label2={'Montant total (CHF)'}/>
                    </div>
                </div>
            </div>

            <div className="display-flex position-relative report-chart flex-direction-column flex-1"
                 style={{alignItems: "unset", justifyContent: "flex-start"}}>
                <NavLink
                    to={`/renouvellements?start=${formattedStartDate}&end=${formattedEndDate}`}
                    className="text-decoration-none bg-eee border-bottom-1px-ccc -radius-10 "
                    target="_blank"
                    style={{borderRadius: "10px 10px  0 0"}}
                >
                    <h3 className={"color-third p-10 mt-0"}>Renouvellements
                        <i style={{fontSize: ".8rem"}} className={"ml-10 fa-solid fa-external-link"}/></h3>
                </NavLink>

                <RenewalsChart renewalYear1={renewalYear1} renewalYear2={renewalYear2} renewalYear3={renewalYear3} type={'doughnut'} displayData />


            </div>
        </div>

        {

            <div className="display-flex position-relative report-chart flex-direction-row flex-1"
                 style={{alignItems: "unset", justifyContent: "flex-start"}}>


                <div
                    className="text-decoration-none bg-eee border-bottom-1px-ccc -radius-10"
                    style={{borderRadius: "10px 10px 0 0"}}>
                    <h3 className={"color-third p-10 mt-0"}>Personnel</h3>
                </div>
                <div className={"p-20 pt-0"}>
                    <table className="report-table">
                        <thead>
                        <tr>
                            <th>Nom d'utilisateur</th>
                            <th>Temps total</th>
                            <th>Montant total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.entries(userTimeMap)
                            .sort(([a], [b]) => a.localeCompare(b)) // Sort by username
                            .map(([username, {totalMinutes, totalPrice}]) => (<tr key={username}>
                                <td>{username}</td>
                                <td>{Math.floor(totalMinutes / 60)} h {totalMinutes % 60} min</td>
                                <td>{totalPrice.toFixed(2)} CHF</td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>}


        <div className="display-flex position-relative report-chart flex-direction-row flex-1 mt-20"
             style={{alignItems: "unset", justifyContent: "flex-start"}}>

            <div
                className="text-decoration-none bg-eee border-bottom-1px-ccc -radius-10"
                style={{borderRadius: "10px 10px 0 0"}}>
                <h3 className={"color-third p-10 mt-0"}>Tâches pour la
                    période <strong>{formatDateBeautiful(formattedStartDate)}</strong> au <strong>{formatDateBeautiful(formattedEndDate)}</strong>
                </h3>
            </div>

            <div className={"p-20"}>

                <SearchBar2 autoFocus={true} searchLabel={"projets trouvés."} totalDatas={totalTasks} datasFound={setDatasFound} datas={datasFound} totalPositionsLength={datasFound}/>
                <Table

                    maxEntries={20}
                    hidePagination={false}
                    data={datasFound} headersData={["name_1", "title", "article_code", "time_spent_reporting", "task_price_prorata", "finish_date", "username"]}
                    headers={["Client", "Tâche", "Code article", "Temps passé", "Prix", "Date de fin", "Responsable"]} />
            </div>

        </div>
    </div>)
        ;
};

export default Reporting;
