import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../../config";
import {useParams} from "react-router-dom";

const SectionTechniqueDetailsContactTechnique = ( ) => {
    // Initialize state with empty fields.

    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    const [data, setData] = useState({
        firstname: '',
        lastname: '',
        mail: '',
        phone: '',
        factory: ''
    });

    // Fetch technical partner record using its id.
    useEffect(() => {
        if (!id) return; // If no id provided, skip fetching.
        axios
            .post(`${API_BASE_URL}/get_customer_technical_partner/`, { id })
            .then((res) => {
                console.log('Galactic tech details fetched:', res.data.result);
                const result = res.data.result;
                // If result is an array, use the first element; otherwise, use result directly.
                const fetchedData = Array.isArray(result) ? result[0] : result;
                setData({
                    firstname: fetchedData.firstname || '',
                    // Use either lastname or name field from the backend.
                    lastname: fetchedData.lastname || fetchedData.name || '',
                    mail: fetchedData.mail || '',
                    phone: fetchedData.phone || '',
                    factory: fetchedData.factory || ''
                });
            })
            .catch((err) => console.error('Error fetching technical data:', err));
    }, [id]);

    // Handle input changes.
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    // Save function calls the new endpoint.
    const handleSave = () => {
        axios
            .post(`${API_BASE_URL}/set_customer_technical_partner/`, { id, ...data })
            .then((res) => {
                console.log('Technical partner saved:', res.data);
                // Optionally, update state with any returned info (like a new id after insertion).
            })
            .catch((err) => console.error('Error saving technical data:', err));
    };

    return (
        <>
        <label className={"text-left mb-20 mt-0 pt-0"}><h3>Personne technique à contacter si besoin</h3></label>

    <div className="" style={{width: "unset", maxWidth: "unset", padding: 0, marginTop: 20}}>
        <div className={"display-flex gap-20 w-100 flex-direction-column mb-20"}>
                <div className={"display-flex flex-direction-row gap-20"}>
                    <div className={"display-flex flex-direction-column w-100 gap-10"}>
                        <strong className={"text-left color-third"}>Prénom</strong>
                        <input
                            type="text"
                            name="firstname"
                            value={data.firstname}
                            onChange={handleChange}
                            placeholder="Prénom"
                            className="input p-10"
                        />
                    </div>
                    <div className={"display-flex flex-direction-column w-100 gap-10"}>

                        <strong className={"text-left color-third"}>Nom</strong>
                        <input
                            type="text"
                            name="lastname"
                            value={data.lastname}
                            onChange={handleChange}
                            placeholder="Nom de famille"
                            className="input p-10"
                        />
                    </div>
                </div>
            </div>
            <div className={"text-left mb-20 display-flex flex-direction-column gap-10"}>
                <strong className={"text-left color-third"}>Email</strong>
                <input
                    type="text"
                    name="mail"
                    value={data.mail}
                    onChange={handleChange}
                    placeholder="info@solution-digitale.ch"
                    className="input p-10"
                /></div>
        <div className={"text-left mb-20 display-flex flex-direction-column gap-10"}>
                <strong className={"text-left color-third"}>N° de téléphone</strong>
                <input
                    type="text"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                    placeholder="021/907.87.87"
                    className="input p-10"
                />
            </div>
        <div className={"text-left mb-20 display-flex flex-direction-column gap-10"}>
                <strong className={"text-left color-third"}>Entreprise</strong>
                <input
                    type="text"
                    name="factory"
                    value={data.factory}
                    onChange={handleChange}
                    placeholder="Solution Digitale SA"
                    className="input p-10"
                /></div>
            <button className={"button-third"} onClick={handleSave}>Enregistrer</button>
        </div></>
    );
};

export default SectionTechniqueDetailsContactTechnique;
