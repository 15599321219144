import React, { useState } from 'react';

const SectionTechnique = ({ onOptionSelect }) => {
    // Define the available options.
    const options = [
        { id: 'resume', label: 'Résumé', icon: 'fa-list-alt' },
        { id: 'contact_technique', label: 'Contact technique', icon: 'fa-wrench color-third' },
        { id: 'domain', label: 'Domaine', icon: 'fa-solid fa-globe color-third' },
        { id: 'hebergeur', label: 'Hébergeur web', icon: 'fa-regular fa-cloud color-third' },
        { id: 'emails', label: 'Emails', icon: 'fa-envelope color-third' },
        { id: 'zoneDNS', label: 'Zone DNS', icon: 'fa-server color-third' }
    ];

    // Set the first option as default.
    const [activeOption, setActiveOption] = useState(options[0].id);

    // Handle click events on each tab.
    const handleOptionClick = (option) => {
        setActiveOption(option.id);
        if (onOptionSelect) {
            onOptionSelect(option);
        }
    };

    return (
        <div className="section-technique">
            <ul className="technique-options">
                {options.map(option => (
                    <li
                        key={option.id}
                        className={`technique-option ${activeOption === option.id ? 'active' : ''}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        <i className={'fa ' + option.icon} style={{width: 30}}/>
                        {option.label}
                    </li>
                ))}
            </ul>

        </div>
    );
};

export default SectionTechnique;
