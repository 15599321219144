import React from 'react';
import {formatDateDDMMYYYY, formatDateBeautiful} from "../utils/dateUtils";

const RenewalCustomerCard = ({data, type, isChecked, handleToggle}) => {

    console.log(data)
    return (<div className={"flex-1 display-flex"}>
        <div className={" display-flex flex-direction-column p-0 w-100" }>
            {type === "website" ? <>
                    <strong className={"p-10 bg-ddd border-radius-10 no-radius-bottom"}>{data.url}</strong>
                <div className={"separator"}/>
                    <div className={"p-10 display-flex flex-direction-column"} style={{backgroundColor: "#F9FAFB", border: "solid 1px #ddd", borderTop: "none", borderRadius: "0 0 10px 10px"}}>

                    <span>
                    Mise en ligne le : {formatDateDDMMYYYY(data.date)}
                    </span>

                        <div className={"display-flex justify-content-center"}>

                            <label className="switch mt-20">
                                <input type="checkbox" checked={isChecked} onChange={handleToggle}/>
                                <span className="slider"></span>
                            </label>
                        </div>
                    </div>
                </>

                : type === "post" &&

                <>
                    {console.log(data)}
                    <strong className={"p-10 bg-ddd border-radius-10 no-radius-bottom"}
                            dangerouslySetInnerHTML={{__html: data.title}}/>
                    <div className={"separator"}/>
                    <div className={"p-10 display-flex flex-direction-column"} style={{
                        backgroundColor: "#F9FAFB",
                        border: "solid 1px #ddd",
                        borderTop: "none",
                        borderRadius: "0 0 10px 10px"
                    }}>
                        <span>Début le {formatDateDDMMYYYY(data.order_start_date)}</span>

                        <div className={"separator mt-20"}/>
                        <div className={"display-flex justify-content-center"}>
                        <label className="switch mt-20">
                            <input type="checkbox" checked={!data.is_canceled} onChange={handleToggle}/>
                            <span className="slider"></span>
                        </label>
                        </div>
                    </div>
                </>}
        </div>


    </div>);
};

export default RenewalCustomerCard;