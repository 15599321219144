import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import Table from "../components/Table";
import UserTimeAmountChart from "../components/UserTimeAmountChart";
import {NavLink} from "react-router-dom";

const Renouvellements = () => {
    const [renewals1, setRenewals1] = useState([]);
    const [renewals2, setRenewals2] = useState([]);
    const [renewals3, setRenewals3] = useState([]);
    const [totalRenewalsYear1, setTotalRenewalYear1] = useState([]);
    const [totalRenewalsYear2, setTotalRenewalYear2] = useState([]);
    const [totalRenewalsYear3, setTotalRenewalYear3] = useState([]);

    const params = new URLSearchParams(window.location.search);
    const startDate = params.get('start');
    const endDate = params.get('end');

    useEffect(() => {
        document.title = 'X | Renouvellements'
    }, []);
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_renewals_details`, {startDate, endDate})
            .then(response => {
                const renewalData = response.data;  // Assuming the data is an array

                console.log(renewalData)

                // Temporary arrays to hold renewals for each year
                const tempRenewals1 = [];
                const tempRenewals2 = [];
                const tempRenewals3 = [];

                // Loop through each renewal item and categorize it based on product_code
                renewalData.forEach(item => {

                    console.log(item)


                    // 1è année
                    if (item.article_id === 262 || item.article_id === 247 || item.article_id === 245 || item.article_id === 410 || item.article_id === 239 || item.article_id === 235 || item.article_id === 237 || item.article_id === 243 || item.article_id === 241 || item.article_id === 274 || item.article_id === 229 || item.article_id === 400 || item.article_id === 223 || item.article_id === 412 || item.article_id === 226 || item.article_id === 402) {
                        tempRenewals1.push(item);
                    }
                    // 2è année
                    else if (item.article_id === 236 || item.article_id === 272 || item.article_id === 318 || item.article_id === 246 || item.article_id === 244 || item.article_id === 416 || item.article_id === 238 || item.article_id === 399 || item.article_id === 236 || item.article_id === 242 || item.article_id === 240 || item.article_id === 275 || item.article_id === 159 || item.article_id === 401 || item.article_id === 157 || item.article_id === 413 || item.article_id === 158 || item.article_id === 403) {
                        tempRenewals2.push(item);
                    }
                    // Renouvellements tacites
                    else if (item.article_id === 332 || item.article_id === 367 || item.article_id === 186 || item.article_id === 331 || item.article_id === 371 || item.article_id === 372 || item.article_id === 332 || item.article_id === 365 || item.article_id === 366 || item.article_id === 341 || item.article_id === 342 || item.article_id === 336 || item.article_id === 340 || item.article_id === 339) {
                        tempRenewals3.push(item);
                    }
                });

                console.log(tempRenewals1)
                console.log(tempRenewals2)
                console.log(tempRenewals3)
                // Update state with categorized data
                setRenewals1(tempRenewals1);
                setRenewals2(tempRenewals2);
                setRenewals3(tempRenewals3);


                setTotalRenewalYear1(tempRenewals1.reduce((total, item) => total + item.amount, 0));

// Sum for 2ème année
                setTotalRenewalYear2(tempRenewals2.reduce((total, item) => total + item.amount, 0));

// Sum for Renouvellements tacites (3ème groupe)
                setTotalRenewalYear3(tempRenewals3.reduce((total, item) => total + item.amount, 0));

            })
            .catch(error => {
                console.error('Error fetching renewal details:', error);
            });
    }, []);  // Empty dependency array means this runs once when the component mounts


    return (<div>
        <h2 className={"color-third"}>Renouvellements</h2>

        <div className={'report-chart'}>
            <NavLink to={'/reporting'}>
                <button className={"button-third m-20 mb-0 position-relative    "}>Retour</button>
            </NavLink>

            <div className={"display-flex flex-direction-column w-100 mt-20"}>
                <div className={"p-20 pt-0"}>
                    <Table
                        hidePagination
                        title={"Abonnements 1ère année"}
                        data={renewals1}
                        headersData={['name_1_renew', 'position_reporting', 'start_date', 'amount']}
                        headers={["Client", "Nom du projet", 'Date de facturation', "Montant"]}
                    />
                    <span className={"text-right display-flex flex-direction-column my-20 gap-"}>

                        <div className={"display-flex text-right justify-content-end"}>
                            <span>
                                Total : &nbsp;
                            </span>
                            <strong>
                                {totalRenewalsYear1} CHF
                            </strong>
                        </div>
                    </span>
                </div>

            </div>

            <div className={"display-flex flex-direction-column w-100"}>
                <div className="p-20">
                    <Table
                        title={"Abonnements 2e année"}
                        data={renewals2}
                        hidePagination
                        headersData={['name_1_renew', 'position_reporting', 'start_date', 'amount']}
                        headers={["Client", "Nom du projet", 'Date de facturation', "Montant"]}
                    /><span className={"text-right display-flex flex-direction-column my-20 gap-"}>

                        <div className={"display-flex text-right justify-content-end"}>
                            <span>
                                Total : &nbsp;
                            </span>
                            <strong>
                                {totalRenewalsYear2} CHF
                            </strong>
                        </div>
                    </span>
                </div>

            </div>

            <div className={"display-flex flex-direction-column w-100"}>
                <div className="p-20">
                    <Table
                        title={"Renouvellements 3e année"}
                        data={renewals3}
                        hidePagination
                        headersData={['name_1_renew', 'position_reporting', 'start_date', 'amount']}
                        headers={["Client", "Nom du projet", 'Date du facturation', "Montant"]}
                    /><span className={"text-right display-flex flex-direction-column my-20 gap-"}>

                        <div className={"display-flex text-right justify-content-end"}>
                            <span>
                                Total : &nbsp;
                            </span>
                            <strong>
                                {totalRenewalsYear3} CHF
                            </strong>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>);
};

export default Renouvellements;