import React, {useState, useRef, Fragment, useEffect} from "react";

const SearchBar2 = ({autoFocus, totalPositionsLength = 0, totalDatas, datasFound, datas, searchLabel}) => {
    const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
    const inputRef = useRef(null); // Reference to the input field
    const [totalPositions, setTotalPositions] = useState()

    useEffect(() => {

        if (totalPositionsLength === undefined) {
            totalPositionsLength = 0
            console.log('undef')
            console.log(totalDatas)
            setTotalPositions(totalDatas);
        }
    }, []);
    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        setSearchTerm(searchValue);

        if (searchValue.trim() === "") {
            datasFound(totalDatas); // Reset when input is cleared
        } else {
            const filteredDatas = totalDatas.filter((data) => {
                return Object.values(data).some((value) => value && value.toString().toLowerCase().includes(searchValue.toLowerCase()));
            });
            setTotalPositions(filteredDatas.length);
            datasFound(filteredDatas); // Update the list with filtered results
        }
    };

    return (<Fragment>

            <div className="search-bar-container search-bar p-20 mb-0">
                <label htmlFor="search"></label>
                <input
                    id="search searchbar2"
                    ref={inputRef}
                    className="form-control search-form"
                    type="text"
                    autoFocus={autoFocus}
                    placeholder="Rechercher..."
                    value={searchTerm}
                    onInput={handleSearchChange}
                />
                <p className={"color-third text-left mb-0 pt-0 mt-10"} style={{fontWeight: 500}}>
                    {(totalPositionsLength.length ?? 0) + " " + searchLabel}
                </p>
            </div>

        </Fragment>

    );
};

export default SearchBar2;
