import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Modal from "react-modal";
import axios from "axios";
import {API_BASE_URL} from "../../config";
import ImageResize from 'quill-image-resize-module-react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../assets/css/Ticket.css'
import Select from "react-select";
import isEmpty from "lodash/isEmpty";


// Register the image resize module
Quill.register("modules/imageResize", ImageResize);


// Add the Quill modules configuration
const quillModules = {
    toolbar: [[{header: [1, 2, 3, false]}], ['bold', 'italic', 'underline', 'strike'], // Text styling
        [{list: 'ordered'}, {list: 'bullet'}], ['blockquote', 'code-block'], ['link', 'image'], // Links and images
        [{align: []}], [{color: []}, {background: []}], // Color options
        ['clean'], // Remove formatting
    ], clipboard: {
        matchVisual: false,
    }, // Enable image resizing
    imageResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
    },
};

// Formats allowed in the editor
const quillFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'blockquote', 'code-block', 'link', 'image', 'align', 'color', 'background',];
const TicketModal = ({closeModal}) => {
    const quillRef = useRef(null);
    const [department, setDepartment] = useState(-1);
    const [departments, setDepartments] = useState([]);
    const [emergencyLevel, setEmergencyLevel] = useState(null);
    const [priorityLevels, setPriorityLevels] = useState([])
    const [departementUsers, setDepartementUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [description, setDescription] = useState()
    const [title, setTitle] = useState()
    const [customer, setCustomer] = useState([])
    const [customers, setCustomers] = useState([])
    const [ticketType, setTicketType] = useState(null)
    const [ticketTypes, setTicketTypes] = useState([])

    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (file) {
                try {
                    const formData = new FormData();
                    formData.append("image", file);

                    // Upload the image to your backend.
                    const response = await axios.post(
                        `${API_BASE_URL}/upload_image`,
                        formData,
                        {
                            headers: { "Content-Type": "multipart/form-data" },
                        }
                    );

                    // Assuming the response returns the image URL in response.data.url
                    const imageUrl = response.data.url;

                    // Insert the image into the editor at the current cursor position.
                    const quill = quillRef.current.getEditor();
                    const range = quill.getSelection();
                    quill.insertEmbed(range.index, "image", imageUrl);
                } catch (error) {
                    console.error("Image upload failed:", error);
                }
            }
        };
    }, []); // No dependencies because the function doesn't rely on external state

    // Use useMemo to create the modules configuration.
    // This ensures that the modules object is only recreated when its dependencies change.
    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["blockquote", "code-block"],
                    ["link", "image"],
                    [{ align: [] }],
                    [{ color: [] }, { background: [] }],
                    ["clean"],
                ],
                handlers: {
                    image: imageHandler,
                },
            },
            clipboard: {
                matchVisual: false,
            },
            imageResize: {
                modules: ["Resize", "DisplaySize", "Toolbar"],
            },
        }),
        [imageHandler]
    );

    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_all_customers_without_no_homes`)
            .then(res => {
                const filteredData = res.data.datas.filter(item => item.url !== '307.solution-digitale.ch' && item.url !== '308.solution-digitale.ch');
                const sortedData = filteredData.sort((a, b) => b.timestamp - a.timestamp);
                setCustomers(sortedData);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });

        axios.post(`${API_BASE_URL}/get_all_departments`)
            .then(res => {
                setDepartments(res.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
        axios.post(`${API_BASE_URL}/get_tickets_priority_levels`)
            .then(res => {
                setPriorityLevels(res.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            });
    }, []);

    useEffect(() => {
        console.log(department)
        axios
            .post(`${API_BASE_URL}/get_users_per_departement`, {department})
            .then((res) => {
                console.log(res)
                setDepartementUsers(res.data);
                console.log(res.data)
            })
            .catch((error) => {
                console.error("Error fetching department users:", error);
            });
    }, [department]);


    const saveTicket = () => {
        const user_id = localStorage.user_id;
        const customer_id = customer.id
        const datas = {
            department,
            selectedUser,
            user_id,
            emergencyLevel,
            customer_id,
            ticketType, // Ensure you have a state variable for title
            description, // Ensure you have a state variable for description
        };

        axios
            .post(`${API_BASE_URL}/create_ticket_from_x`, { datas })
            .then((res) => {
                console.log("Ticket created successfully:", res.data);
                if (res.status === 201) {
                    closeModal("saved")
                }
                // Show a success message or handle the response
            })
            .catch((error) => {
                console.error("Error creating ticket:", error);
                // Show an error message
            });
    };

    useEffect(() => {
        console.log('ok')
        axios
            .post(`${API_BASE_URL}/get_tickets_types`, {department})
            .then((res) => {
                console.log(res)
                setTicketTypes(res.data)
            })


    }, [department]);

    const handleCreateTicket = (e) => {
        e.preventDefault();
        saveTicket();
    };

    const options = customers.map(client => ({
        value: client.id,
        label: client.name_1,
    }));
    const selectedOption = options.find(option => option.value === (customer ? customer.id : null)) || null;
// Inside your component (so handleImageUpload is in scope)


    return (<div>
        <Modal
            isOpen
            onRequestClose={closeModal}
            contentLabel="Créer un ticket"
            className="modal"
            overlayClassName="overlay"
            shouldCloseOnOverlayClick={true}
        >
            <div className="register-container">
                <form className="ticket-form" onSubmit={handleCreateTicket}>
                    <h2 className="title mt-0">Nouveau ticket</h2>

                    {/* Department Selection */}
                    <div className={"display-grid"}>
                        <div className={"display-flex justify-content-space-between mb-20 gap-20"}>

                            <div
                                className="input-container mb-0 department w-100 technique"
                                style={{ position: "relative"}}
                            >
                                <div
                                    className="input-icon"
                                    style={{padding: "0 10px 0 20px", position: "absolute", zIndex: 1}}
                                >
                                    <i className="fas fa-building color-third"></i>
                                </div>

                                <Select
                                    noOptionsMessage={() => 'Aucun résultat trouvé'}
                                    placeholder="Choisir un département"
                                    options={departments.map((dept) => ({
                                        value: dept.id,
                                        label: dept.name,
                                    }))}
                                    onChange={(option) => setDepartment(option ? option.value : null)}

                                    isClearable={true}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                            position: "relative",
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                            textAlign: "left",
                                            height: "51px",
                                            borderRadius: 8,
                                            // Padding left to leave space for the icon
                                            paddingLeft: "45px",
                                        }),
                                    }}
                                />
                            </div>


                            <div
                                className="input-container mb-0 ticket-type w-100"
                                style={{ position: "relative"}}
                            >
                                <div
                                    className="input-icon"
                                    style={{padding: "0 10px 0 20px", position: "absolute", zIndex: 1}}
                                >
                                    <i className="fas fa-info-circle color-third"></i>
                                </div>
                                <Select
                                    noOptionsMessage={() => 'Aucun résultat trouvé'}
                                    placeholder="Type de ticket"
                                    options={ticketTypes.map((type) => ({
                                        value: type.id,
                                        label: type.name,
                                    }))}
                                    onChange={(option) =>
                                        setTicketType(option ? option.value : null)
                                    }
                                    isClearable={true}
                                    styles={{
                                        container: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                            position: "relative"
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                            textAlign: "left",
                                            height: "51px",
                                            borderRadius: 8,
                                            // Add left padding to account for the icon
                                            paddingLeft: "45px"
                                        }),
                                    }}
                                />
                            </div>


                        </div>
                        <div className={"display-flex justify-content-space-between gap-20"}>
                            {/* clients  */}
                            {(ticketType && department !== -1) && (
                                <div className="customer-ticket input-container mb-0" style={{width: "calc(50% - 25px)"}}>
                                    <div className="input-icon"
                                         style={{padding: "0 10px 0 20px", position: "absolute", zIndex: 1}}>
                                        <i className="fas fa-info-circle color-third"></i>
                                    </div>
                                    <Select
                                        noOptionsMessage={() => 'Aucun résultat trouvé'}
                                        placeholder="Client (facultatif)"
                                        options={options}
                                        value={selectedOption}
                                        onChange={(option) => {
                                            console.log(departementUsers)
                                            if (!option) {
                                                setCustomer(null);
                                                return;
                                            }
                                            const selectedCustomer = customers.find(
                                                client => client.id === option.value
                                            );
                                            setCustomer(selectedCustomer);
                                        }}
                                        isClearable
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                position: "relative"
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                textAlign: "left",
                                                height: "51px",
                                                borderRadius: 8,
                                                // Add left padding to account for the icon
                                                paddingLeft: "45px"
                                            }),
                                        }}
                                    />
                                </div>
                            )}

                            {(ticketType && department !== -1) && (
                                <div
                                    className="input-container mb-0"
                                    style={{width: "calc(50% - 25px)", position: "relative" }}
                                >
                                    <div className="input-icon" style={{ padding: "0 10px 0 20px", position: "absolute", zIndex: 1 }}>
                                        <i className="fas fa-user color-third"></i>
                                    </div>
                                    <Select
                                        noOptionsMessage={() => 'Aucun résultat trouvé'}
                                        placeholder="Collaborateur (facultatif)"
                                        options={departementUsers.map(user => ({
                                            value: user.id,
                                            label: user.username_display
                                        }))}
                                        value={
                                            selectedUser
                                                ? { value: selectedUser, label: departementUsers.find(user => user.id === selectedUser)?.username_display }
                                                : null
                                        }
                                        onChange={(option) => setSelectedUser(option ? option.value : "")}
                                        isClearable={true}
                                        isDisabled={departementUsers.length === 0}
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                position: "relative"
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                textAlign: "left",
                                                height: "51px",
                                                borderRadius: 8,
                                                // Add left padding to account for the icon
                                                paddingLeft: "45px"
                                            }),
                                        }}
                                    />
                                </div>
                            )}

                            {(ticketType && department !== -1) && (
                                <div
                                    className="input-container mb-0"
                                    style={{ width: "calc(50% - 25px)", position: "relative" }}
                                >
                                    <div
                                        className="input-icon"
                                        style={{ padding: "0 10px 0 20px", position: "absolute", zIndex: 1 }}
                                    >
                                        <i className="fa-solid fa-light-emergency-on color-third"></i>
                                    </div>
                                    <Select
                                        noOptionsMessage={() => 'Aucun résultat trouvé'}
                                        placeholder="Priorité"
                                        options={priorityLevels.map((lvl) => ({
                                            value: lvl.id,
                                            label: lvl.name,
                                        }))}
                                        value={
                                            emergencyLevel
                                                ? {
                                                    value: emergencyLevel,
                                                    label:
                                                    priorityLevels.find((lvl) => lvl.id === emergencyLevel)
                                                        ?.name,
                                                }
                                                : 2
                                        }

                                        onChange={(option) =>
                                            setEmergencyLevel(option ? option.value : "")
                                        }
                                        // Removing isClearable if a selection is required; otherwise, you can enable it.
                                        isClearable={false}
                                        styles={{
                                            container: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                position: "relative",
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                width: "100%",
                                                textAlign: "left",
                                                height: "51px",
                                                borderRadius: 8,
                                                // Add left padding to make room for the icon
                                                paddingLeft: "45px",
                                            }),
                                        }}
                                    />
                                </div>
                            )}

                        </div>

                        {(ticketType && department !== -1) && (
                            <div className="input-container mb-0  mb-20" style={{}}>
                            <div className="input-icon" style={{padding: "0 10px 0 20px", display: "none"}}>
                                <i className="fa-solid fa-heading color-third"></i>
                            </div>
                            <input
                                style={{fontSize: "1rem", marginBottom: 15, display: "none"}}
                                type="text"
                                className="input input-ticket my-10"
                                placeholder="Titre du ticket"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}

                            />
                        </div>)}


                    </div>
                    {(ticketType && department !== -1) && (<div
                        className="input-container mb-0 ticket-description-container"
                        style={{width: "100%", position: "relative"}}
                    >
                        <div
                            className="input-icon"
                            style={{
                                padding: "0 10px 0 20px",
                                position: "absolute",
                                zIndex: 1,
                                top: "10px"
                            }}
                        >
                            <i className="fa-solid fa-text color-third"/>
                        </div>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            style={{marginBottom: "10px"}}
                            modules={modules}
                            value={description}
                            onChange={(content) => setDescription(content)}
                            formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "list",
                                "bullet",
                                "blockquote",
                                "code-block",
                                "link",
                                "image",
                                "align",
                                "color",
                                "background",
                            ]}
                        />
                    </div>)}

                    {/* Buttons */}
                    <div className="display-flex justify-content-end gap-20 btn-container mt-15">
                        <button
                            className="btn-submit-account cancel"
                            style={{backgroundColor: "#ce3b2a"}}
                            onClick={closeModal}
                        >
                            Annuler
                        </button>
                        <button className="btn-submit-account" type="submit">
                            Créer le ticket
                        </button>
                    </div>
                </form>
            </div>
        </Modal>

    </div>);

};

export default TicketModal;
