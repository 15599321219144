import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import {useParams} from "react-router-dom";

const SectionTechniqueDetailsEmails = ( ) => {
    const [providers, setProviders] = useState([]);
    const [selectedEmailProvider, setSelectedEmailProvider] = useState(null);

    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    // Fetch the list of organizations
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_customer_emails_orgs/`, { id })
            .then((res) => {
                console.log('Organization list fetched:', res.data.result);
                setProviders(res.data.result || []);
            })
            .catch((err) => console.error('Error fetching organizations:', err));
    }, [id]);

    // Fetch the current email provider (default radio selection)
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_customer_emails`, { customer_id: id })
            .then((res) => {
                console.log('Current email provider:', res.data.result[0].customer_email_org_id);
                // Assuming res.data.result is a number between 1 and 5
                setSelectedEmailProvider(res.data.result[0].customer_email_org_id);
            })
            .catch((err) => console.error('Error fetching current email provider:', err));
    }, [id]);

    const handleRadioChange = (e) => {
        const newProvider = parseInt(e.target.value, 10);
        setSelectedEmailProvider(newProvider);
        console.log(id)
        axios
            .post(`${API_BASE_URL}/set_customer_emails`, { customer_id: id, email_provider: newProvider })
            .then((res) => {
                console.log('Email provider updated:', res.data.result);
            })
            .catch((err) => console.error('Error updating email provider:', err));
    };

    return (
        <>
            <div className="display-flex flex-direction-column">
                <h3>Sélectionner le prestataire de mails</h3>

                <h4 className={"color-third mb-10 mt-20"}>Gestion</h4>
                <div className={"display-flex gap-20 flexwrap-wrap container-1400-col mb-20"}>

                    <label>Mail rattaché au nom de domaine ?</label>
                    <label>
                        <input type="radio" name="mailRattache" value="oui"/> Oui
                    </label>
                    <label>
                        <input type="radio" name="mailRattache" value="non"/> Non
                    </label>


                </div>

                <h4 className={"color-third mb-10"}>Adresses email</h4>
                <div className={"display-flex gap-20 flexwrap-wrap container-1400-col mb-20"}>
                    <div className={"flex-1 w-100 gap-10 display-flex flex-direction-column"}>
                        Mail 1 rattaché
                        <input className={"input pl-10"} type={"text input p-10"}/>
                    </div>
                    <div className={"flex-1 w-100 gap-10 display-flex flex-direction-column"}>
                        Mail 2 rattaché (optionnel)
                        <input className={"input pl-10"} type={"text input p-10"}/>
                    </div>
                </div>


                <div className={"flex-1 w-100 gap-10 display-flex flex-direction-column"}>

                    <span>Mail d'envoi <strong>hors nom de domaine</strong></span>
                    <input className={"input pl-10"} type={"text input p-10"}/>
                </div>

                <h4 className={"color-third mb-20 mt-20"}>Prestataire</h4>
                {providers.map((el) => (
                    <div key={el.id} className="display-flex align-items-center" style={{marginBottom: '0px'}}>
                        <label className="display-flex align-items-center">
                            <input
                                type="radio"
                                name="org"
                                value={el.id}
                                checked={selectedEmailProvider === el.id}
                                onChange={handleRadioChange}
                            />
                            <img
                                src={`https://x.solution-digitale.ch/assets/customers/orgs/${el.name}.png`}
                                alt={el.name}
                                className="display-flex align-items-flex-end"
                                style={{
                                    height: 60,
                                    width: 200,
                                    objectFit: 'contain',
                                    marginRight: '8px',
                                }}
                            />
                        </label>
                    </div>
                ))}

                <label className="display-flex align-items-center" style={{height: 60}}>
                    <input
                        type="radio"
                        name="org"
                        value={5}
                        checked={selectedEmailProvider === 5}
                        onChange={handleRadioChange}
                    /><span
                    style={{marginLeft: 32}}>Autre</span>
                </label>

            </div>
            <button className={"button-third"}>Enregistrer</button>
        </>
    )
        ;
};

export default SectionTechniqueDetailsEmails;
