import React, { useState, useRef, useEffect, Fragment } from "react";
import { QRCodeCanvas } from "qrcode.react";
import '../assets/css/QR.css';

const QrCodes = () => {
    const [url, setUrl] = useState("");
    const [QRSize, setQRSize] = useState(128);
    const qrRef = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    const [placeUrl, setPlaceUrl] = useState("");
    const [qrGenerated, setQrGenerated] = useState(false);

    useEffect(() => {
        document.title = 'X | QR Codes'
    }, []);
    useEffect(() => {
        if (activeTab === 1 && window.google) {
            const input = document.getElementById("place-input");
            const autocomplete = new window.google.maps.places.Autocomplete(input);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place && place.place_id) {
                    const reviewUrl = `https://search.google.com/local/writereview?placeid=${place.place_id}`;
                    setPlaceUrl(reviewUrl);
                } else {
                    alert("Rien n'a été trouvé pour cet emplacement.");
                }
            });
        }
    }, [activeTab]);

    const downloadQRCode = () => {
        let canvas = qrRef.current.querySelector("canvas");
        let image = canvas.toDataURL("image/png");
        let anchor = document.createElement("a");
        anchor.href = image;
        anchor.download = `QRCode-${activeTab === 0 ? url : placeUrl}.png`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const toggleTab = (index) => {
        setActiveTab(index);
        setQrGenerated(false); // Reset QR generation state when tab changes
    };

    const generateQRCode = () => {
        setQrGenerated(true);
    };

    return (
        <Fragment>
            <div>
                <h2>QR Generator</h2>
                <div>
                    <div className={"display-flex"}>
                        <span
                            onClick={() => toggleTab(0)}
                            className={`cursor-pointer onglet-qr ${activeTab === 0 ? 'qr-active-tab' : ''}`}
                        >
                            QR site
                        </span>
                        <span
                            onClick={() => toggleTab(1)}
                            className={`cursor-pointer onglet-qr ${activeTab === 1 ? 'qr-active-tab' : ''}`}
                        >
                            QR avis
                        </span>
                    </div>
                    <div className={'seo-search-container search-bar-qr m-0 display-flex'}>
                        {activeTab === 0 ? (
                            <>
                                <div className={"flex-1"}>
                                    <div className={"display-grid"}>
                                        <label className={'label-qr display-flex gap-20'}>
                                            <input
                                                style={{ border: "solid 2px var(--secondary)" }}
                                                autoFocus
                                                placeholder={"Entrez l'URL du site, par exemple : https://solution-digitale.ch/"}
                                                className={'input search-bar-seo flex-3'}
                                                type="url"
                                                value={url}
                                                onChange={(e) => setUrl(e.target.value)}
                                            />
                                            <button
                                                className={'btn-generate-meta display-flex justify-content-center gap-20 color-third'}
                                                style={{cursor: "pointer", margin: "10px 0", height: "38px!important"}}
                                                onClick={generateQRCode}
                                            >
                                                <i className="fa-solid fa-magnifying-glass"
                                                   style={{fontSize: "20px"}}></i>
                                            </button>
                                        </label>
                                        {qrGenerated && (
                                            <div className="display-flex flex-direction-column">
                                                <div className={"display-flex gap-10 mb-10 mt-20"}>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{ cursor: "pointer", flex: 1, marginLeft: 0 }}
                                                        onClick={() => setQRSize(128)}
                                                    >
                                                        128px
                                                    </button>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{ cursor: "pointer", flex: 1 }}
                                                        onClick={() => setQRSize(256)}
                                                    >
                                                        256px
                                                    </button>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{ cursor: "pointer", flex: 1 }}
                                                        onClick={() => setQRSize(512)}
                                                    >
                                                        512px
                                                    </button>
                                                    <input
                                                        autoFocus={true}
                                                        onChange={(e) => setQRSize(Number(e.target.value))}
                                                        type={"number"}
                                                        placeholder={"Par ex. 512"}
                                                        style={{
                                                            width: "80px",
                                                            marginLeft: "0",
                                                            border: "none",
                                                            flex: 1,
                                                            borderRadius: "3px",
                                                            paddingLeft: "5px",
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className={'btn-generate-meta mt-20 color-third gap-20 display-flex justify-content-center align-items-center'}
                                                    onClick={downloadQRCode}
                                                    disabled={!url}
                                                >
                                                    <i className="fa-solid fa-download"
                                                       style={{fontSize: "25px"}}/><strong>Télécharger</strong>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"display-flex"}>
                                    <div className={'flex-1 qr-btns'}></div>
                                    <div className={"display-qr"}>
                                        <QRCodeCanvas size={QRSize} value={url} />
                                        <i style={{ fontSize: ".8rem", paddingTop: "20px" }}>
                                            (Le code QR se régénère automatiquement dès que l'URL est modifiée)
                                        </i>
                                    </div>
                                    <div ref={qrRef} className={'d-none'}>
                                        <div className={"display-qr"}>
                                            <QRCodeCanvas size={QRSize} value={url} />
                                            <i style={{ fontSize: ".8rem", paddingTop: "20px" }}>
                                                (Le code QR se régénère automatiquement dès que l'URL est modifiée)
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <Fragment>
                                <div style={{ flex: 2 }}>
                                    <div className={'display-grid'}>
                                        <div className={""}>
                                            <label className={'label-qr'}>
                                                <input
                                                    style={{ border: "solid 2px var(--secondary)" }}
                                                    autoFocus={true}
                                                    id="place-input"
                                                    placeholder={"Entrez l'adresse ou le nom de l'entreprise"}
                                                    className={'input search-bar-seo flex-3 mr-10'}
                                                    type="text"
                                                />
                                                <button
                                                    className={'btn-generate-meta'}
                                                    style={{ cursor: "pointer", margin: "10px 0", height: "38px!important" }}
                                                    onClick={generateQRCode}
                                                >
                                                    <i className="fa-solid fa-magnifying-glass" style={{ fontSize: "20px" }}></i>
                                                </button>
                                            </label>
                                        </div>
                                        {placeUrl && (
                                            <div className="display-flex flex-direction-column">
                                                <div className={"display-flex gap-10 mb-10 mt-20"}>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{cursor: "pointer", flex: 1, marginLeft: 0}}
                                                        onClick={() => setQRSize(128)}
                                                    >
                                                        128px
                                                    </button>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{cursor: "pointer", flex: 1}}
                                                        onClick={() => setQRSize(256)}
                                                    >
                                                        256px
                                                    </button>
                                                    <button
                                                        className={'btn-qr'}
                                                        style={{cursor: "pointer", flex: 1}}
                                                        onClick={() => setQRSize(512)}
                                                    >
                                                        512px
                                                    </button>
                                                    <input
                                                        autoFocus={true}
                                                        onChange={(e) => setQRSize(Number(e.target.value))}
                                                        type={"number"}
                                                        placeholder={"Par ex. 512"}
                                                        style={{
                                                            width: "80px",
                                                            marginLeft: "0",
                                                            border: "none",
                                                            flex: 1,
                                                            borderRadius: "3px",
                                                            paddingLeft: "5px",
                                                        }}
                                                    />
                                                </div>
                                                <div className={"display-flex gap-10"}></div>
                                                <button
                                                    className={'btn-generate-meta display-flex align-items-center justify-content-center gap-20 color-third mt-20'}
                                                    onClick={downloadQRCode}
                                                    disabled={!placeUrl}
                                                >
                                                    <i className="fa-solid fa-download"
                                                       style={{fontSize: "25px"}}></i><strong>Télécharger</strong>
                                            </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div style={{ flex: 2 }}>
                                    <div className={"display-grid"}>
                                        {placeUrl && (
                                            <div className={"display-qr"}>
                                                <QRCodeCanvas size={QRSize} value={placeUrl} />
                                                <i style={{ fontSize: ".8rem", paddingTop: "20px" }}>
                                                    (Le code QR se régénère automatiquement dès que l'adresse est modifiée)
                                                </i>
                                            </div>
                                        )}
                                        <div ref={qrRef} className={'d-none'}>
                                            <div className={"display-qr"}>
                                                <QRCodeCanvas size={QRSize} value={placeUrl} />
                                                <i style={{ fontSize: ".8rem", paddingTop: "20px" }}>
                                                    (Le code QR se régénère automatiquement dès que l'adresse est modifiée)
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default QrCodes;
