import React from 'react';
import '../assets/css/XError.css'

const XError = () => {
    return (
        <div>
            <iframe src={'https://xerror.solution-digitale.ch/update.html'} className={'iframe-xerror'} />
        </div>
    );
};

export default XError;