import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../../config";

const TicketsNavbar = ({toggleTicketModal}) => {
    const [categories, setCategories] = useState([]);
    const [departmentTickets, setDepartmentTickets] = useState([]);

    // Fetch categories and their corresponding ticket data
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_all_tickets_categories`)
            .then((res) => {
                console.log(res.data);
                setCategories(res.data);

                // Fetch tickets for each department category
                const fetchTickets = res.data.map((category) =>
                    axios
                        .post(`${API_BASE_URL}/get_all_tickets_per_category`, {id: category.id})
                        .then((res) => ({
                            id: category.id,
                            name: category.name,
                            tickets: res.data,
                        }))
                        .catch((error) => {
                            console.error(`Error fetching tickets for department ${category.name}:`, error);
                            return {id: category.id, name: category.name, tickets: []};
                        })
                );

                // Wait for all requests to complete
                Promise.all(fetchTickets).then((results) => {
                    console.log(results);
                    setDepartmentTickets(results); // Store tickets by department
                });
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    return (
        <div>

                    {
                    }
        </div>
    );
};

export default TicketsNavbar;