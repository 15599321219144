import { getDateRange, formatDate } from '../utils/dateUtils';
import React, { useEffect, useRef } from 'react';
import c3 from 'c3';
import 'c3/c3.css';

const ReportingChart = ({ data, selectedFilter, customStartDate, customEndDate }) => {
    const chartRef = useRef(null);

    // Process proratedData: group by finish_date and sum up task_price_prorata
    const processProratedData = (proratedData) => {
        const dailySums = {};
        const dateRange = getDateRange(selectedFilter, customStartDate, customEndDate);
        dateRange.forEach(date => dailySums[date] = 0);
        proratedData.forEach(({ finish_date, task_price_prorata }) => {
            const date = formatDate(new Date(finish_date));
            dailySums[date] += parseFloat(task_price_prorata) || 0;
        });
        return { dailySums, dateRange };
    };

    // Process renewals: group by start_date and sum up amount
    const processRenewals = (renewals) => {
        const dailySums = {};
        const dateRange = getDateRange(selectedFilter, customStartDate, customEndDate);
        dateRange.forEach(date => dailySums[date] = 0);
        renewals.forEach(({ start_date, amount }) => {
            const date = formatDate(new Date(start_date));
            dailySums[date] += parseFloat(amount) || 0;
        });
        return { dailySums, dateRange };
    };

    useEffect(() => {
        if (data && data.proratedData && data.renewals) {
            const { dailySums: proratedSums, dateRange } = processProratedData(data.proratedData);
            const { dailySums: renewalSums } = processRenewals(data.renewals);

            createChart(dateRange, proratedSums, renewalSums);
        }
    }, [data, selectedFilter, customStartDate, customEndDate]);

    const createChart = (dateRange, proratedSums, renewalSums) => {
        // Format date labels for the x-axis
        const formattedDates = dateRange.map(dateStr => {
            const date = new Date(dateStr);
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        });
        // Build arrays of amounts matching the dateRange order
        const proratedAmounts = dateRange.map(date => proratedSums[date]);
        const renewalAmounts = dateRange.map(date => renewalSums[date]);

        c3.generate({
            bindto: chartRef.current,
            data: {
                x: 'x',
                columns: [
                    ['x', ...formattedDates],
                    ['Montant généré par les tâches réalisées', ...proratedAmounts],
                    ['Renouvellements', ...renewalAmounts]
                ],
                type: 'area-spline'
            },
            axis: {
                x: {
                    type: 'category'
                }
            },
            point: {
                show: true
            }
        });
    };

    return <div ref={chartRef}></div>;
};

export default ReportingChart;
