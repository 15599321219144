import React from 'react';
import {useNavigate} from "react-router-dom";

const BackButton = ({to}) => {
    const navigate = useNavigate();

    return (<a className={"display-flex text-decoration-none"}><button
            onClick={() => navigate(to)}
            className="no-bg display-flex align-items-center p-0 cursor-pointer"
            style={{borderRadius: "5px 0 0 5px"}}>
            <span className="display-flex flex-direction-row h-100 align-items-center bg-primary"
                  style={{borderRadius: "5px 0px 0px 5px"}}>
                <i className="fa-regular fa-angle-left pl-10 color-white"/>
                <div className="h-separator m-10 mr-0" style={{backgroundColor: "white"}}></div>
            </span>
            <span className="p-0 cursor-pointer w-100 h-100 display-flex align-center">
                <span className="back-button p-10 bg-primary color-white" style={{borderRadius: "0 5px 5px 0"}}>
                    Retour
                </span>
            </span>
        </button></a>
    );
};

export default BackButton;