import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {API_BASE_URL} from "../config";
import axios from "axios";
import ReactQuill from "react-quill";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackToTickets from "../components/Ticketing/TicketsTopButtonsBar";
import Button from "../components/Elements/Button";
import CommentsSection from "../components/Ticketing/CommentsSection";
import Loading from "../components/Loading";
import {Tooltip} from 'react-tooltip';
import {formatDateDDMMYYYY} from "../utils/dateUtils";
import Select from "react-select";
import 'react-quill/dist/quill.snow.css';
import 'react-tooltip/dist/react-tooltip.css';

// Add the Quill modules configuration
const quillModules = {
    toolbar: [
        [{header: [1, 2, 3, false]}],
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        ['blockquote', 'code-block'],
        ['link', 'image'],
        [{align: []}],
        [{color: []}, {background: []}],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
    },
};

// Formats allowed in the editor
const quillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet',
    'blockquote', 'code-block', 'link', 'image', 'align', 'color', 'background',
];

const Ticket = () => {
    const {id} = useParams();
    const [toggleHoverPriority, setToggleHoverPriority] = useState(false);
    const [toggleHoverStatus, setToggleHoverStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [hoveredUser, setHoveredUser] = useState(null);
    const [ticket, setTicket] = useState({});
    const [description, setDescription] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [assignedUserIds, setAssignedUserIds] = useState([]);
    const [priorityLevels, setPriorityLevels] = useState([]);
    const [priorityLevel, setPriorityLevel] = useState("");
    const [lastUpdatedAt, setLastUpdatedAt] = useState("");
    const [statusLevel, setStatusLevel] = useState("");
    const [statusLevels, setStatusLevels] = useState([]);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [ticketTimes, setTicketTimes] = useState([]);
    const [customerTickets, setCustomerTickets] = useState([]);
    const [replyTo, setReplyTo] = useState(null);
    const [totalTimeSpent, setTotalTimeSpent] = useState("00:00");
    const [addTime, setAddTime] = useState(false);
    const [timeSpent, setTimeSpent] = useState('');
    const [timeComment, setTimeComment] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [ticketTitles, setTicketTitles] = useState([]);
    const [ticketDepartments, setTicketDepartments] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    // Update fields
    const [ticketTitle, setTicketTitle] = useState("");
    const [ticketDepartment, setTicketDepartment] = useState("");
    const [ticketCustomer, setTicketCustomer] = useState(0);
    const [ticketCustomerEmail, setTicketCustomerEmail] = useState("");
    const [options, setOptions] = useState([]);
    const [optionsTitles, setOptionsTitles] = useState([]);
    const [optionsDepartments, setOptionsDepartments] = useState([]);
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        setLoading(true);

        axios.post(`${API_BASE_URL}/get_ticket_info`, {id})
            .then(res => {
                const ticketData = res.data[0];

                console.log('ok reload?')

                setTicket(ticketData);
                setCustomer(res.data[0].customer)
                setPriorityLevel(res.data[0].name)

                if (ticketData.mail)
                    setTicketCustomerEmail(ticketData.mail);
                if (ticketData.customer_email)
                    setTicketCustomerEmail(ticketData.customer_email);
                setStatusLevel(ticketData.status_name || "");
                setDescription(ticketData.description || "");
                setLastUpdatedAt(ticketData.ticket_updated_at || new Date());

                // Handle ticket type
                if (ticketData.ticket_type) {
                    setTicketTitle(ticketData.ticket_type);
                }

                // Handle assigned users
                const userIds = typeof ticketData.assigned_user === "string"
                    ? ticketData.assigned_user.split(",").map(id => id.trim())
                    : [ticketData.assigned_user];
                setAssignedUserIds(userIds);

                // Set ticket department
                if (ticketData.department) {
                    setTicketDepartment(ticketData.department);

                    // Fetch ticket types based on department
                    return axios.post(`${API_BASE_URL}/get_tickets_types`, {
                        department: ticketData.department
                    });
                }
                return null;
            })
            .then(res => {
                if (res) {
                    setTicketTitles(res.data);

                    setOptionsTitles (res.data.length > 0 ? res.data.map(type => ({
                        value: type.id,
                        label: type.name,
                    })) : []);

                    // Now that we have all data, we can set the dropdown selections
                    setTimeout(() => {
                        // Set selected department
                        if (ticket.department && optionsDepartments.length > 0) {
                            const depOption = optionsDepartments.find(opt =>
                                String(opt.value) === String(ticket.department)
                            );
                            setSelectedDepartment(depOption || null);
                        }

                        // Set selected ticket type
                        if (ticket.ticket_type_id && optionsTitles.length > 0) {
                            const typeOption = optionsTitles.find(opt =>
                                String(opt.value) === String(ticket.ticket_type_id)
                            );
                            setSelectedTitle(typeOption || null);
                        }

                        // Set selected customer
                        if (ticket.customer_id && options.length > 0) {
                            const clientOption = options.find(opt =>
                                String(opt.value) === String(ticket.customer_id)
                            );
                            setSelectedOption(clientOption || null);
                        }

                        setLoading(false);
                    }, 500);
                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error("Error fetching ticket info:", error);
                toast.error("Impossible de charger les informations du ticket");
                setLoading(false);
            });
    }, [id, dataChanged]);


    // Load customers
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_customers_without_no_homes`)
            .then(res => {
                const filteredData = res.data.datas.filter(item =>
                    item.url !== '307.solution-digitale.ch' &&
                    item.url !== '308.solution-digitale.ch'
                );
                const sortedData = filteredData.sort((a, b) => b.timestamp - a.timestamp);
                setCustomers(sortedData);

                console.log()

                setOptions (sortedData.length > 0 ? sortedData.map(client => ({
                    value: client.id,
                    label: client.name_1,
                })) : [])

            })
            .catch(error => {
                console.error("Error fetching customers:", error);
                toast.error("Impossible de charger les clients");
            });
    }, []);

    // Load departments
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_departments`)
            .then(response => {


                setOptionsDepartments( response.data.length > 0 ? response.data.map(type => ({
                    value: type.id,
                    label: type.name,
                })) : []);
                setTicketDepartments(response.data);
            })
            .catch(error => {
                console.error("Error fetching departments:", error);
                toast.error("Impossible de charger les départements");
            });
    }, []);

    // When department changes, fetch ticket types
    useEffect(() => {
        if (ticketDepartment) {
            axios.post(`${API_BASE_URL}/get_tickets_types`, {
                department: ticketDepartment
            })
                .then(res => {
                    setTicketTitles(res.data);
                    // Clear selected title if department changed
                    if (selectedTitle && selectedTitle.departmentId !== ticketDepartment) {
                        setSelectedTitle(null);
                    }
                })
                .catch(error => {
                    console.error("Error fetching ticket types:", error);
                });
        }
    }, [ticketDepartment]);

    // Load comments
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_ticket_comments`, {id})
            .then(res => {
                setComments(res.data);
            })
            .catch(error => {
                console.error("Error fetching comments:", error);
            });
    }, []);

    // Load priority levels
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_priority_levels`, {id})
            .then(res => {
                setPriorityLevels(res.data);
            })
            .catch(error => {
                console.error("Error fetching priority levels:", error);
            });
    }, [priorityLevel]);

    // Load status levels
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_status_levels`, {id})
            .then(res => {
                setStatusLevels(res.data);
            })
            .catch(error => {
                console.error("Error fetching status levels:", error);
            });
    }, []);

    // Load ticket updates
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_ticket_updates`, {id})
            .then(res => {
                setUpdates(res.data);
            })
            .catch(error => {
                console.error("Error fetching ticket updates:", error);
            });
    }, []);

    // Load ticket times
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_ticket_times_spent`, {id})
            .then(res => {
                // Convert time string (HH:MM:SS) to seconds
                const timeStringToSeconds = (timeStr) => {
                    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
                    return hours * 3600 + minutes * 60 + (seconds || 0);
                };

                // Sum all time values in seconds
                const totalSeconds = res.data.reduce((total, record) => {
                    return total + timeStringToSeconds(record.time_spent);
                }, 0);

                // Convert total seconds back to HH:MM format
                const formatSecondsToTime = (seconds) => {
                    const hrs = Math.floor(seconds / 3600);
                    const mins = Math.floor((seconds % 3600) / 60);
                    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
                };

                const totalTimeFormatted = formatSecondsToTime(totalSeconds);
                setTotalTimeSpent(totalTimeFormatted);

                // Remove seconds from each record's time_spent
                const formattedData = res.data.map(record => ({
                    ...record,
                    time_spent: record.time_spent.slice(0, 5) // Slices "HH:MM:SS" to "HH:MM"
                }));

                setTicketTimes(formattedData);
            })
            .catch(error => {
                console.error("Error fetching ticket times:", error);
            });
    }, []);

    // Load customer tickets
    useEffect(() => {
        if (ticket && ticket.customer) {
            const customer_id = ticket.customer;
            axios.post(`${API_BASE_URL}/get_tickets_per_customer`, {customer_id})
                .then(res => {
                    setCustomerTickets(res.data);
                })
                .catch(error => {
                    console.error("Error fetching customer tickets:", error);
                });
        }
    }, [ticket]);

    // Load assigned users
    useEffect(() => {
        // Ensure `userIds` is always an array
        let userIds = Array.isArray(assignedUserIds)
            ? assignedUserIds
            : typeof assignedUserIds === 'string'
                ? assignedUserIds.split(',').map(id => id.trim())
                : [assignedUserIds];

        if (userIds.length > 0 && userIds[0]) {
            // Fetch usernames for the IDs
            axios.post(`${API_BASE_URL}/get_user_name`, {ids: userIds})
                .then(userRes => {
                    setAssignedUsers(userRes.data.usernames);
                })
                .catch(error => {
                    console.error("Error fetching usernames:", error);
                });
        }
    }, [assignedUserIds]);

    // Handle user input changes
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        fetchUsers(value);
    };

    // Handle key press in input
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && inputValue) {
            saveTicket(inputValue);
        }
    };

    // Delete assigned user
    const deleteAssignedUser = (username) => {
        axios.post(`${API_BASE_URL}/delete_ticket_assigned_user`, {id, username})
            .then(res => {
                setAssignedUserIds(res.data.updatedUsers);
                toast.success("Utilisateur retiré avec succès");
            })
            .catch(error => {
                console.error("Error removing user:", error);
                toast.error("Impossible de retirer l'utilisateur");
            });
    };

    // Save ticket with new assigned user
    const saveTicket = (userName) => {
        axios.post(`${API_BASE_URL}/save_ticket_info`, {
            id,
            usernames_assigned: userName,
        })
            .then(res => {
                // Parse the response to update assigned user IDs
                const assignedIdUsersArray = typeof res.data[0].assigned_user === "string"
                    ? res.data[0].assigned_user.split(",").map(id => id.trim())
                    : [res.data[0].assigned_user];

                setAssignedUserIds(assignedIdUsersArray);
                setIsModalOpen(false);
                setInputValue("");
                toast.success("Utilisateur ajouté avec succès");
            })
            .catch(error => {
                console.error("Error saving ticket:", error);
                toast.error("Impossible d'ajouter l'utilisateur");
            });
    };

    // Show add user modal
    const showAddUserModal = () => {
        setIsModalOpen(true);
        fetchUsers("");
    };

    // Fetch users
    const fetchUsers = async (query = "") => {
        try {
            const res = await axios.post(`${API_BASE_URL}/get_users`);
            setUsers(res.data);

            // Filter users based on the query
            const filtered = res.data.filter(user =>
                user.username_display.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredUsers(filtered);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // Change priority level
    const changePriorityLevel = (priority) => {
        setToggleHoverPriority(false);
        const user_id = localStorage.getItem("user_id");

        axios.post(`${API_BASE_URL}/update_ticket_priority_level`, {
            id,
            priorityLevel: priority,
            user_id
        })
            .then(res => {
                setPriorityLevel(res.data[0].name);
                toast.success("Priorité mise à jour avec succès");
            })
            .catch(error => {
                console.error("Error updating priority:", error);
                toast.error("Impossible de mettre à jour la priorité");
            });
    };

    // Change status
    const changeStatus = (status) => {
        setToggleHoverStatus(false);
        const user_id = localStorage.getItem("user_id");

        axios.post(`${API_BASE_URL}/update_ticket_status`, {
            id,
            status,
            user_id
        })
            .then(res => {
                setStatusLevel(res.data[0].name);
                setLastUpdatedAt(Date.now());
                toast.success("Statut mis à jour avec succès");
            })
            .catch(error => {
                console.error("Error updating status:", error);
                toast.error("Impossible de mettre à jour le statut");
            });
    };

    // Save comment
    const saveComment = () => {
        if (!comment) {
            toast.error("Le commentaire ne peut pas être vide");
            return;
        }

        const user_id = localStorage.getItem("user_id");

        axios.post(`${API_BASE_URL}/save_comment_ticket`, {
            id,
            user: user_id,
            comment,
            reply_to: replyTo
        })
            .then(res => {
                setComments(res.data);
                setComment("");
                setReplyTo(null);
                toast.success("Commentaire ajouté avec succès");
            })
            .catch(error => {
                console.error("Error saving comment:", error);
                toast.error("Impossible d'ajouter le commentaire");
            });
    };

    // Toggle add time form
    const toggleAddTime = () => {
        setAddTime(prev => !prev);
    };

    // Handle time form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!timeSpent) {
            toast.error("La durée ne peut pas être vide");
            return;
        }

        const user_id = localStorage.getItem("user_id");

        axios.post(`${API_BASE_URL}/add_time_spent`, {
            id,
            time_spent: timeSpent,
            user_id,
            comment: timeComment,
        })
            .then(res => {
                setTicketTimes(res.data);
                setTimeSpent('');
                setTimeComment('');
                setAddTime(false);
                toast.success("Temps ajouté avec succès");
            })
            .catch(error => {
                console.error('Error logging time:', error);
                toast.error("Impossible d'ajouter le temps");
            });
    };

    // Handle reply to comment
    const handleReply = (comment) => {
        setReplyTo(comment.id);
        setComment("");
    };

    // Toggle edit mode
    const toggleParentEditMode = () => {
        setEditMode(prev => !prev);
    };


    // Update selected options whenever dropdown data changes
    useEffect(() => {
        const depOption = optionsDepartments.find(opt =>
            String(opt.value) === String(ticket.department)
        );
        setSelectedDepartment(depOption || null);
        const typeOption = optionsTitles.find(opt =>
            String(opt.value) === String(ticket.ticket_type_id)
        );
        setSelectedTitle(typeOption || null);
        const clientOption = options.find(opt =>
            String(opt.value) === String(ticket.customer_id)
        );
        setSelectedOption(clientOption || null);

    }, [id, editMode]);

    const saveTicketInfo = () => {
        console.log(customer)
        // Force empty string ("") when there's no value:
        const data = [{
            // Either use optional chaining or standard conditional checks
            title: ticketTitle || "",
            customer: customer || "",
            description: description || "",
            customer_email: ticketCustomerEmail || "",
            department: selectedDepartment.value || "",
            ticket_type: selectedTitle || "",
        }];

        console.log("Data being sent:", data);

        axios.post(`${API_BASE_URL}/update_ticket`, { id, data })
            .then(res => {
                setEditMode(false);
                setDataChanged((prev) => !prev);
                toast.success("Ticket mis à jour avec succès");
            })
            .catch(error => {
                console.error("Error updating ticket:", error);
                toast.error("Impossible de mettre à jour le ticket");
            });
    };


    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Loading/>
            </div>
        );
    }

    return (
        <div className="ticket-page">
            <ToastContainer position="top-right" autoClose={3000}/>
            <div className="ticket-content">
                <div className="ticket-main">
                    <div className="container-back-ticket">
                        {
                            ticket ?
                            <BackToTickets
                                ticket={ticket}
                                toggleParentEditMode={toggleParentEditMode}
                                saveTicketInfo={saveTicketInfo}
                            />
                                : <Loading />
                        }
                    </div>
                    <div className="p-20 container-ticket display-flex flex-direction-column">
                        <div>
                            <div className="display-flex flex-direction-column">
                                {editMode ? (
                                    <>
                                        <div className={"display-flex gap-20 justify-content-end mb-20"}>
                                            <Button onClick={() => setEditMode(false)} icon={"fa-xmark"} label={"Annuler"} textClassNames={"bg-orangered"} iconBackground={'bg-warning text-white'} disableLink/>
                                            <Button onClick={saveTicketInfo} icon={"fa-floppy-disk"} label={"Enregistrer"} textClassNames={"button-third text-white btn-save"} iconBackground={'bg-fifth'} disableLink/>
                                        </div>
                                        <div className="display-flex gap-20 mb-20">

                                            <div className={"display-flex flex-direction-column flex-1"}>
                                                <span className={"mb-10"}>Département</span>
                                                <Select
                                                    noOptionsMessage={() => 'Aucun résultat trouvé'}
                                                    value={selectedDepartment}
                                                    placeholder="Département"
                                                    options={optionsDepartments}
                                                    onChange={(option) => {
                                                        setSelectedDepartment(option);
                                                        setTicketDepartment(option ? option.value : null);
                                                    }}
                                                    isClearable={true}
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            width: "100%",
                                                            position: "relative"
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            width: "100%",
                                                            textAlign: "left",
                                                            height: "51px",
                                                            borderRadius: 8,
                                                            paddingLeft: "10px"
                                                        }),
                                                    }}
                                                /></div>
                                            <>
                                                <div className={"display-flex flex-direction-column flex-1"}>
                                                    <span className={"mb-10"}>Type de ticket</span>
                                                    <Select
                                                        noOptionsMessage={() => 'Aucun résultat trouvé'}
                                                        value={selectedTitle}
                                                        placeholder="Type de ticket"
                                                        options={optionsTitles}
                                                        onChange={(option) => {
                                                            setSelectedTitle(option);
                                                            setTicketTitle(option ? option.value : null);
                                                        }}
                                                        isClearable={true}
                                                        styles={{
                                                            container: (provided) => ({
                                                                ...provided,
                                                                width: "100%",
                                                                position: "relative"
                                                            }),
                                                            control: (provided) => ({
                                                                ...provided,
                                                                width: "100%",
                                                                textAlign: "left",
                                                                height: "51px",
                                                                borderRadius: 8,
                                                                paddingLeft: "10px"
                                                            }),
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        </div>
                                    </>) : (
                                    <span className="ticket-title">{ticketTitle}</span>
                                )}

                                {console.log(ticket)}
                                {editMode ? (
                                    <>
                                        {(ticketCustomerEmail && ticketCustomerEmail !== '0') ? (
                                            <div className={"display-flex flex-direction-column"}>
                                                <span className={"mb-10"}>Email</span>
                                                <input
                                                    onChange={(e) => setTicketCustomerEmail(e.target.value)}
                                                    className="ticket-title input"
                                                    style={{
                                                        borderRadius: 8,
                                                        fontWeight: 1,
                                                        fontSize: "1rem",
                                                        padding: "15px 0 15px 18px"
                                                    }}
                                                    value={ticketCustomerEmail}
                                                /></div>
                                        ) : ticketCustomerEmail && (<div className={"display-flex flex-direction-column"}>
                                                <span className={"mb-10"}>Email</span>
                                                <input
                                                    onChange={(e) => setTicketCustomerEmail(e.target.value)}
                                                    className="ticket-title input"
                                                    style={{
                                                        borderRadius: 8,
                                                        fontWeight: 1,
                                                        fontSize: "1rem",
                                                        padding: "15px 0 15px 18px"
                                                    }}
                                                    value={ticketCustomerEmail}
                                                /></div>
                                        )}
                                    </>
                                ) : (
                                    (ticketCustomerEmail && ticketCustomerEmail !== '0') ? (
                                        <h3 className="ticket-title">De : {ticketCustomerEmail}</h3>
                                    ) : ticketCustomerEmail && (
                                        <h3 className="ticket-title">De : {ticketCustomerEmail}</h3>
                                    )
                                )}

                                {editMode && (
                                    <div className={"display-flex flex-direction-column"}>
                                        <span className={"mb-10"}>Entreprise</span>
                                        <Select
                                            className="mb-20"
                                            placeholder="Client"
                                            noOptionsMessage={() => 'Aucun résultat trouvé'}
                                            options={options}
                                            value={selectedOption}
                                            onChange={(option) => {
                                                if (!option) {
                                                    setSelectedOption(null);
                                                } else {
                                                    const selectedCustomer = customers.find(
                                                        client => client.id === option.value
                                                    );
                                                    setSelectedOption({
                                                        value: selectedCustomer.id,
                                                        label: selectedCustomer.name_1
                                                    });
                                                    setCustomer(selectedCustomer);
                                                    setTicketCustomer(selectedCustomer.id);
                                                }
                                            }}
                                            isClearable
                                            styles={{
                                                container: (provided) => ({
                                                    ...provided,
                                                    width: "100%",
                                                    position: "relative"
                                                }),
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: "100%",
                                                    textAlign: "left",
                                                    height: "51px",
                                                    borderRadius: 8,
                                                    paddingLeft: "10px"
                                                }),
                                            }}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="ticket-description">
                                <ReactQuill
                                    value={description}
                                    onChange={content => setDescription(content)}
                                    theme="snow"
                                    readOnly={!editMode}
                                />
                            </div>
                        </div>

                        <div className="ticket-resolution-notes">
                            {comments.length > 0 && (
                                <>
                                    <h2>Commentaires</h2>
                                    <div style={{paddingBottom: 30, display: "flex", flexDirection: "column"}}>
                                        <CommentsSection
                                            comments={comments}
                                            onReply={handleReply}
                                            replyTo={replyTo}
                                            comment={comment}
                                            setComment={setComment}
                                            saveComment={saveComment}
                                        />
                                        <div className="separator"/>
                                    </div>
                                </>
                            )}

                            <span className="my-20"><h2>Nouveau commentaire...</h2></span>

                            <ReactQuill
                                value={comment}
                                readOnly={false}
                                style={{minHeight: 200}}
                                modules={quillModules}
                                formats={quillFormats}
                                theme="snow"
                                onChange={setComment}
                            />

                            <button
                                style={{zIndex: 9, marginLeft: 0}}
                                className="mt-0 button-third"
                                onClick={saveComment}
                            >
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>

                {/* Right Column */}
                <div className="ticket-meta">
                    {/* Details section */}
                    <div className="p-20 ticket-details display-flex flex-direction-column">
            <span className="mb-20">
              <strong className="title-ticket">Détails</strong>
            </span>
                        {/* Priority and Status */}
                        <div className="display-flex gap-10">
                            <div
                                className="flex-1"
                                style={{position: "relative"}}
                                onMouseEnter={() => setToggleHoverPriority(true)}
                                onMouseLeave={() => setToggleHoverPriority(false)}
                            >
  <span
      style={{
          backgroundColor: priorityLevel,
          border: `solid 3px ${ticket ? ticket.color : "#ccc"}`,
          transition: "all 0.3s ease",
      }}
      className="priority-ticket ticket-status"
  >
    <strong>{priorityLevel}</strong>
  </span>

                                {toggleHoverPriority && (
                                    <div
                                        className="ticket-status-child display-flex"
                                    >
                                        {priorityLevels.map((priority) => (
                                            <span
                                                key={priority.id}
                                                style={{
                                                    border: `solid 3px ${priority.color}`,
                                                    backgroundColor: `${priority.color}`,
                                                    transition: "all 0.3s ease",
                                                }}
                                                className="priority-ticket ticket-status crop-text"
                                                onClick={() => changePriorityLevel(priority.id)}
                                            >
          {priority.name}
        </span>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div
                                className="flex-1"
                                style={{position: "relative"}}  // <-- Add this
                                onMouseEnter={() => setToggleHoverStatus(true)}
                                onMouseLeave={() => setToggleHoverStatus(false)}
                            >
  <span
      className="priority-ticket ticket-status crop-text"
      style={{
          border: `solid 3px ${ticket ? ticket.status_color : '#ccc'}`,
          transition: "all 0.3s ease",
      }}
  >
    <strong>{statusLevel}</strong>
  </span>

                                {toggleHoverStatus && (
                                    <div
                                        className="ticket-status-child display-flex"
                                        // dropdown to match the exact width of the parent container.)
                                    >
                                        {statusLevels.map((status) => (
                                            <span
                                                key={status.id}
                                                style={{
                                                    border: `solid 3px ${status.color}`,
                                                    backgroundColor: `${status.color}`,
                                                    color: `${status.text_color}`,
                                                    transition: "background-color 0.3s ease, transform 0.2s ease",
                                                }}
                                                className={`priority-ticket ticket-status crop-text ticket-${status.color}`}
                                                onClick={() => changeStatus(status.id)}
                                            >
          {status.name}
        </span>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </div>

                        <p className="mt-15">
                            {ticket && ticket.created_at ? (
                                <span>
                                  Ouvert le {new Date(ticket.created_at).toLocaleString()} par{" "}
                                    <strong>{ticket.created_username}</strong>
                                </span>
                            ) : (
                                <Loading/>
                            )}
                        </p>
                    </div>

                    {/* Assigned persons section */}
                    <div>
                        <div className="p-20 ticket-details display-flex flex-direction-column">
              <span className="mb-20">
                <strong className="title-ticket">Personne(s) assignée(s)</strong>
              </span>
                            <div className="display-grid">
                <span
                    className="ticket-icon-people-container pt-0 mt-0"
                    onMouseLeave={() => setHoveredUser(null)}
                >
                  {assignedUsers.map((username_display, index) => (
                      <span
                          key={index}
                          className="ticket-icon-people"
                          onMouseEnter={() => setHoveredUser(username_display)}
                      >
                      {username_display}

                          {hoveredUser === username_display && (
                              <span
                                  className="delete-user-icon display-flex align-center-center justify-content-center"
                                  onClick={() => deleteAssignedUser(username_display)}
                                  style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      height: 20,
                                      width: 20
                                  }}
                              >
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                          )}
                    </span>
                  ))}
                    {assignedUsers.length < 2 && (
                        <span
                            className="add-user-icon ticket-icon-people"
                            onClick={showAddUserModal}
                            style={{cursor: "pointer", fontSize: 10}}
                        >
                      <i className="fa-solid fa-plus"></i>
                    </span>
                    )}
                </span>

                                {isModalOpen && (
                                    <div style={{marginBottom: "20px"}}>
                                        <input
                                            autoFocus
                                            type="text"
                                            className="no-outline border-bottom-1px-third"
                                            placeholder="Ajouter..."
                                            value={inputValue}
                                            onChange={handleInputChange}
                                            onKeyDown={handleKeyDown}
                                            style={{padding: "5px", fontSize: "14px"}}
                                        />
                                        <ul style={{
                                            marginTop: 5,
                                            paddingLeft: 0,
                                            listStyleType: "none"
                                        }}>
                                            {filteredUsers.map((user, index) => (
                                                <li
                                                    className="mb-5"
                                                    key={index}
                                                    onClick={() => saveTicket(user.username_display)}
                                                    style={{cursor: "pointer", padding: "5px"}}
                                                >
                          <span className="ticket-icon-people add-ticket-icon-people">
                            {user.username_display}
                          </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <div className="display-flex gap-5" style={{fontSize: 14}}>
                                <strong>Dernière mise à jour:</strong>{" "}
                                {new Date(lastUpdatedAt).toLocaleString()}
                            </div>
                        </div>
                    </div>

                    {/* Time tracking section */}
                    <div className="p-20 ticket-details">
                        <div className="display-flex flex-direction-column">
              <span className="mb-20">
                <strong className="title-ticket">Temps</strong>
              </span>
                            {ticketTimes.length > 0 && (
                                <div className="mt-10 mb-10">
                                    {ticketTimes.map((time) => (
                                        <span
                                            key={time.id}
                                            className="ticket-status-historic-span display-flex justify-content-space-between align-items-baseline search-item-found flex-direction-row"
                                        >
                      <span style={{width: "60%"}}>
                        <span className="p-5-10 display-flex justify-content-center flex-2 crop-text">
                          <span id={`anchor-${time.id}`}>{time.comment}</span>
                          <Tooltip anchorSelect={`#anchor-${time.id}`} place="left">
                            {time.comment}
                          </Tooltip>
                        </span>
                      </span>
                      <div
                          style={{width: "40%"}}
                          className="flex-direction-row display-flex flex-direction-row-reverse justify-content-end"
                      >
                        <span
                            className="ticket-status-historic-span display-flex justify-content-space-between align-items-baseline">
                          <strong className="p-5-10 display-flex justify-content-center flex-2">
                            {time.time_spent}
                          </strong>
                        </span>
                        <span
                            className="ticket-status-historic-span display-flex justify-content-space-between align-items-baseline">
                          <strong className="p-5-10 display-flex justify-content-center flex-2">
                            {time.user}
                          </strong>
                        </span>
                      </div>
                    </span>
                                    ))}
                                </div>
                            )}

                            <span
                                onClick={toggleAddTime}
                                className="add-time-ticket add-user-icon ticket-icon-people display-flex"
                                style={{cursor: "pointer", height: 30, width: "fit-content"}}
                            >
                <span className="display-flex align-items-baseline gap-10 p-10">
                  {addTime ? (
                      <span>Annuler</span>
                  ) : (
                      <>
                          <i className="fa-solid fa-plus"></i>
                          <span>Ajouter du temps</span>
                      </>
                  )}
                </span>
              </span>
                        </div>
                        <div className="add-time-container">
                            {addTime && (
                                <form className="time-spent-form" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="time_spent" className="">
                                            Durée
                                        </label>
                                        <input
                                            type="time"
                                            id="time_spent"
                                            value={timeSpent}
                                            onChange={(e) => setTimeSpent(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="comment">Commentaire</label>
                                        <textarea
                                            id="comment"
                                            placeholder="Travail réalisé..."
                                            value={timeComment}
                                            onChange={(e) => setTimeComment(e.target.value)}
                                        />
                                    </div>

                                    <button onClick={handleSubmit} className="submit-btn mx-0">
                                        Enregistrer
                                    </button>
                                </form>
                            )}
                        </div>
                        {ticketTimes.length > 0 && (
                            <div className="separator mt-10" style={{backgroundColor: "#ccc"}}></div>
                        )}

                        <div className="p-20 text-right display-flex justify-content-space-between pb-0">
                            <strong>Total</strong>
                            <strong>{totalTimeSpent}</strong>
                        </div>
                    </div>

                    {/* History section */}
                    <div className="p-20 ticket-details">
                        <div className="display-flex flex-direction-column">
              <span className="mb-20">
                <strong className="title-ticket">Historique</strong>
              </span>
                            <div className="gap-10 display-flex flex-direction-column">
                                {updates.map((update) => (
                                    <span
                                        key={update.id}
                                        className="ticket-status-historic-container display-flex gap-20 flexwrap-wrap justify-content-space-between align-items-center mb-10"
                                    >
                    <span
                        className="ticket-status-historic-span flex-2 display-flex justify-content-space-between align-items-baseline">
                      <strong
                          className="p-5-10 display-flex justify-content-center flex-2 border-radius-round border-solid-2px crop-text text-center"
                          style={{
                              borderColor: `${update.past_update_color}`,
                              maxWidth: 170
                          }}
                      >
                        {update.past_update_name}
                      </strong>
                      <span className="m-10 display-flex justify-content-center flex-1 ticket-status-historic-icon">
                        <i className="fa-solid fa-right color-third"></i>
                      </span>
                      <strong
                          className="p-5-10 display-flex justify-content-center flex-2 border-radius-round border-solid-2px crop-text text-center"
                          style={{
                              borderColor: `${update.new_update_color}`,
                              maxWidth: 170
                          }}
                      >
                        {update.new_update_name}
                      </strong>
                    </span>

                    <span className="flex-1" style={{fontSize: ".8rem"}}>
                      Le{" "}
                        <strong>{new Date(update.date).toLocaleString()}</strong>{" "}
                        par <strong>{update.updated_by}</strong>
                    </span>
                  </span>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Customer tickets section */}
                    <div className="p-20">
                        <div className="display-flex flex-direction-column">
              <span className="mb-20">
                <strong className="title-ticket">
                  Tickets de {ticket && ticket.name_1}
                </strong>
              </span>

                            <div
                                className="display-flex flexwrap-wrap justify-content-space-between align-items-baseline flex-direction-column">
                                {customerTickets.map((customerTicket) => (
                                    <a
                                        key={customerTicket.id}
                                        className="text-decoration-none w-100"
                                        href={`/ticket/${customerTicket.id}`}
                                    >
                    <span
                        className="p-0 w-100 display-flex search-item-found justify-content-space-between align-items-center">
                      <p className="ticket-status-historic-container w-100">
                        <div
                            className="p-5-10 display-flex justify-content-space-between align-items-baseline gap-20 flex-direction-row">
                          <div className="display-flex gap-10">
                            <span
                                className="ticket-status ticket-span-alias p-5-10 bg-color-admin crop-text"
                                style={{backgroundColor: "#66c4c6"}}
                            >
                              {formatDateDDMMYYYY(ticket.created_at)}
                            </span>
                            <span
                                style={{
                                    minWidth: 80,
                                    backgroundColor: customerTicket.color
                                }}
                                className="ticket-status ticket-span-alias p-5-10"
                            >
                              {customerTicket.department_alias}
                            </span>
                          </div>

                          <span
                              className="ticket-status-historic-span flex-2 display-flex justify-content-space-between align-items-baseline"
                              style={{width: 100}}
                          >
                            <strong className="crop-text">
                              {customerTicket.title}
                            </strong>
                          </span>
                        </div>
                      </p>
                    </span>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ticket;