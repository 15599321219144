import React, {useState, useEffect} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../../../config";

const Modal = ({cardId, onClose}) => {

    const [cardDetails, setCardDetails] = useState({title: '', description: ''});

    console.log(cardDetails, cardId)
    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_trello_card_infos`, {id: cardId})
            .then(res => {
                if (res.data) {
                    setCardDetails({title: res.data.title, description: res.data.description})
                }
                console.log(res.data)
            })
        // Fetch card details based on cardId
        // This is a placeholder. Replace with actual data fetching logic.

    }, [cardId]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const handleSave = () => {
        // Save the updated card details
        console.log('Card details saved:', cardDetails);
        onClose();
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <div className={"display-flex trello-modal-title"}>

                    <h2>{cardDetails.title}</h2>
                    <i className="close-trello-modal fa-light fa-xmark display-flex" onClick={onClose}></i>
                </div>

                <div className={"display-grid"}>
                    <label>Dates</label>
                    <div className={"display-flex"}>

                        <input type={"checkbox"}/>
                        <input type={"date"}/>
                    </div>
                </div>
                <div className={"display-grid"}>
                    <label>Description</label>
                    <div className={"display-flex"}>

                        <input
                            type="text"
                            name="title"
                            value={cardDetails.title}
                            onChange={handleChange}
                            placeholder="Title"
                        />
                    </div>
                </div>
                <div className={"display-grid "}>
                    <div className={" line-trello"}>
                        <i className="fa-regular fa-tag"></i>
                        <label>Etiquettes</label>
                    </div>

                    <textarea
                        name="description"
                        value={cardDetails.description}
                        onChange={handleChange}
                        placeholder="Description"
                    ></textarea>
                    <button onClick={handleSave}>Save</button>
                </div>
                <div className={"display-grid"}>
                    <div className={" line-trello"}>
                        <i className="fa-sharp-duotone fa-solid fa-list"></i>
                        <label>Description</label>
                    </div>

                    <textarea
                        name="description"
                        value={cardDetails.description}
                        onChange={handleChange}
                        placeholder="Description"
                    ></textarea>
                    <button onClick={handleSave}>Save</button>
                </div>


            </div>
        </div>
    );
};

export default Modal;
