import React, {useEffect, useState} from "react";
import TicketModal from "../components/Ticketing/TicketModal";
import TicketsTable from "../components/Ticketing/TicketsTable";
import axios from "axios";
import {API_BASE_URL} from "../config";
import TicketsSideBar from "../components/Ticketing/TicketsSideBar";
import {useLocation} from "react-router-dom";

const MesTicketsAssignes = () => {
    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [fetchTicketsTrigger, setFetchTicketsTrigger] = useState(false); // Trigger for re-fetching tickets
    const location = useLocation(); // React Router hook to access current location

    // Toggle Ticket Modal and trigger ticket fetch on successful creation
    const toggleTicketModal = (state, status) => {
        setIsTicketModalOpen((prev) => !prev);
        if (status === "saved") {
            setFetchTicketsTrigger((prev) => !prev);
        }
    };

    // Fetch tickets based on URL `id`
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id"); // Get `id` from URL
        const queryParams = id ? `?id=${id}` : ""; // Construct query string

        setLoading(true); // Set loading state
        axios
            .post(`${API_BASE_URL}/get_all_tickets${queryParams}`)
            .then((res) => {
                setTickets(res.data.tickets || []); // Default to empty array if no tickets
            })
            .catch((error) => {
                console.error("Pas de tickets trouvés:", error);
                setTickets([]); // Clear tickets on error
            })
            .finally(() => {
                setLoading(false); // Clear loading state
            });
    }, [location.search, fetchTicketsTrigger]); // Re-fetch when URL or trigger changes

    return (
        <div>
            <h2 className="color-third">Tickets</h2>

            <div className="display-flex content tickets-tab gap-20 p-0 ">
                <TicketsSideBar toggleTicketModal={toggleTicketModal}/>

                <TicketsTable tickets={tickets}/>

                {isTicketModalOpen && (
                    <TicketModal closeModal={(status) => toggleTicketModal(false, status)}/>
                )}
            </div>
        </div>
    );
};

export default MesTicketsAssignes;
