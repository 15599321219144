import React, { Fragment, useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import axios from "axios";
import BackButton from "../BackButton";

const TicketsTopButtonsBar = ({ ticket, toggleParentEditMode, saveTicketInfo }) => {
    const [editMode, setEditMode] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const isAdmin = localStorage.user_role === "Administrateur";
    const dropdownRef = useRef();

    const toggleEditMode = (status) => {
        setShowDropdown(false)
        setEditMode(prev => !prev);
        if (status !== "cancel") {
            toggleParentEditMode({ status });
        }
    };

    console.log(ticket)

    const toggleDropdown = () => setShowDropdown(prev => !prev);

    const handleDelete = async () => {
        const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce ticket ? Cette action est irréversible.");
        if (confirmed) {
            try {
                setShowDropdown(false)
                await axios.post("/delete_ticket", { id: ticket.id });
                alert("Ticket supprimé.");
                // Redirect or refresh logic if needed
            } catch (err) {
                setShowDropdown(false)
                console.error(err);
                alert("Erreur lors de la suppression.");
            }
        }
    };

    // Hide dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    return (
        <div style={{
            width: "100%",
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            flexDirection: "column",
            backgroundColor: "#F9FAFB"
        }}>
            <div className="display-flex p-20" style={{ flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                <BackButton to={'/tickets'} />

                <div className={"display-flex gap-10"}>
                <div className="display-flex gap-20 align-items-center">
                    <NavLink target={"_blank"} to={`/client/${ticket.id}`} className="display-flex text-decoration-none">
                        <button className="no-bg display-flex align-items-center w-100 p-0 cursor-pointer" style={{ borderRadius: "5px 0 0 5px" }}>
                            <span className="display-flex flex-direction-row h-100 align-items-center bg-third" style={{ borderRadius: "5px 0px 0px 5px" }}>
                                <i className="pl-10 fa-solid fa-up-right-from-square color-white"></i>
                                <div className="h-separator m-10 mr-0" style={{ backgroundColor: "white" }}></div>
                            </span>
                            <span className="p-10 button-action w-100 h-100 display-flex align-center bg-third">
                                <span className="color-white" style={{ fontSize: ".9rem" }}>{ticket.name_1} {ticket.name_2}</span>
                            </span>
                        </button>
                    </NavLink>


                </div>
                {isAdmin && (
                    <div className="relative display-flex" style={{ position: "relative" }} ref={dropdownRef}>
                        <button onClick={toggleDropdown} className="no-bg cursor-pointer" style={{ fontSize: "1.2rem", background: "none", border: "none" }}>
                            <i className="fa-solid fa-ellipsis-vertical color-aaa"></i>
                        </button>

                        {showDropdown && (
                            <div style={{
                                position: "absolute",
                                right: 0,
                                top: "100%",
                                background: "#fff",
                                boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                                borderRadius: "5px",
                                zIndex: 1000,
                                minWidth: "240px"
                            }}>
                                <div onClick={() => toggleEditMode()} className="dropdown-option pt-10 pl-10 pr-10 cursor-pointer hover:bg-light" style={{paddingBottom: 0}}>
                                    <div className={"display-flex gap-10 hover-lightgray p-10 border-radius-5 align-items-center"}>

                                        <i className="fa-regular fa-pen pr-10 color-third" style={{width:30}}/>
                                        <span>

                                            Modifier
                                                </span>
                                    </div>
                                </div>

                                <div onClick={handleDelete} className="dropdown-option p-10 pt-0 cursor-pointer hover:bg-light color-danger">
                                    <div className={"display-flex gap-10 hover-lightgray p-10 border-radius-5 align-items-center"}>

                                        <i className="fa-regular fa-trash-can pr-10 color-red" style={{width:30}}/>
                                        <span>Supprimer le ticket</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            </div>
            <div className="separator"></div>
        </div>
    );
};

export default TicketsTopButtonsBar;
