import React, {Fragment, useEffect, useState} from 'react';
import axios from 'axios';
import {Navigate, NavLink, useParams} from "react-router-dom";
import ExportSaveButtons from "../components/ExportSaveButtons";
import OpenPositions from "../components/OpenPositions";
import {API_BASE_URL} from "../config";
import '../assets/css/Position.css'
import Tasks from "../components/Tasks";
import ClosedPositions from "../components/ClosedPositions";
import ClientCard from "../components/ClientCard";
import PositionTimingInfo from "../components/PositionTimingInfo";
import AdditionalTask from "../components/AdditionalTask";
import Loading from "../components/Loading";

const PositionDetail = () => {
    const {order_id} = useParams(); // Extract order_id from URL params
    const [position, setPosition] = useState([]);
    const [loading, setLoading] = useState(true);
    const [positionNote, setPositionNote] = useState('');
    const [finishedTasks, setFinishedTasks] = useState([])
    const [inProgressTasks, setInProgressTasks] = useState([]);
    const [isOpenTimingInfo, setIsOpenTimingInfo] = useState(true);
    const [isOpenProgressTask, setIsOpenProgressTask] = useState(true);
    const [isOpenFinishedTask, setIsOpenFinishedTask] = useState(false);
    const [isOpenOpenPositions, setIsOpenOpenPositions] = useState(false);
    const [isOpenClosedPositions, setIsOpenClosedPositions] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [totalTimeSpent, setTotalTimeSpent] = useState('');
    const [totalEstimatedPerTasksFinished, setTotalEstimatedPerTasksFinished] = useState('');
    const [totalEstimatedTime, setTotalEstimatedTime] = useState('');
    const [totalRemainingEstimatedTime, setTotalRemainingEstimatedTime] = useState('');
    const [showAdditionnalTasks, setShowAdditionnalTasks] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const formatDate = (isoDate) => {
        const date = new Date(isoDate); // Create a Date object from the ISO string
        const day = String(date.getUTCDate()).padStart(2, '0'); // Get the day and pad with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get the month (0-11, hence +1) and pad
        const year = date.getUTCFullYear(); // Get the full year
        return `${day}/${month}/${year}`; // Return in the format DD/MM/YYYY
    };

    const savePosition = () =>{
        setIsSaved(true)
        window.location.reload()
    }

    const toggleOpenInfo = () => {
        setIsOpenInfo(!isOpenInfo);
    };
    const toggleTimingInfo = () => {
        setIsOpenTimingInfo(!isOpenTimingInfo);
    };
    const toggleOpenPositions = () => {
        setIsOpenOpenPositions(!isOpenOpenPositions);
    };

    const toggleClosedPositions = () => {
        setIsOpenClosedPositions(!isOpenClosedPositions);
    };
    const toggleIsOpenProgressTask = () => {
        setIsOpenProgressTask(!isOpenProgressTask);
    };
    const toggleIsOpenFinishedTask = () => {
        console.log(isOpenFinishedTask)
        setIsOpenFinishedTask(!isOpenFinishedTask);
    };

    useEffect(() => {

        //console.log(localStorage)
        axios.post(`${API_BASE_URL}/get_positions/${order_id}`)
            .then(response => {
                console.log(response);
                const {data} = response;
                if (data.status === 'ok') {
                    console.log(data);

                    setPosition(data.datas);
                    setPositionNote(data.datas[0]?.production_note); // Optional chaining in case datas is empty

                    if (data.datas[0].progress === 100)
                    {
                        if (data.datas[0].article_id === 300 || data.datas[0].article_id === 312 || data.datas[0].article_id === 313 || data.datas[0].article_id === 314 || data.datas[0].article_id === 358){
                            setShowAdditionnalTasks(true)
                        }
                    }

                    document.title = `X | ${data.datas[0]?.name_1} | ${data.datas[0]?.name_1}`
                } else {
                    console.error('Error fetching position details:', data.message);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [order_id]); // Fetch data when order_id changes

    useEffect(() => {

        refreshTasks()
    }, [order_id])

    const refreshTasks = () => {
        const currentUrl = window.location.href;
        const position_id = currentUrl.split('/positions/')[1]; // Extract position_id from the URL

        axios.post(`${API_BASE_URL}/get_all_tasks`, {positionId: position_id})
            .then(res => {
                const tasks = res?.data?.datas || []; // Fallback to empty array if tasks are undefined

                if (Array.isArray(tasks)) {

                    // Update state with the calculated values
                    setFinishedTasks(tasks.filter(task => parseInt(task.progress, 10) === 100));
                    setInProgressTasks(tasks.filter(task => parseInt(task.progress, 10) < 100));
                } else {
                    console.error('Tasks is not an array:', tasks);
                }
            })
            .catch(error => console.error('Error fetching tasks:', error));

    }
    const fetchTasks = () => {
        const position_id = window.location.href.split('/positions/')[1]; // Extract position_id from URL

        axios
            .post(`${API_BASE_URL}/get_all_tasks`, { positionId: position_id })
            .then((res) => {
                const tasks = res?.data?.datas || []; // Fallback to an empty array if undefined

                if (!Array.isArray(tasks)) {
                    console.error('Tasks is not an array:', tasks);
                    return;
                }

                // Initialize accumulators
                let totalEstimatedTime = 0;
                let totalTimeSpentInMinutes = 0;
                let totalEstimatedForFinishedTasks = 0;

                tasks.forEach((task) => {
                    // Add the task's estimated duration to the total estimated time
                    totalEstimatedTime += task.estimated_duration || 0;

                    // If the task has been worked on (time_spent > 0)
                    if (task.time_spent && task.time_spent !== '00:00:00') {
                        const [hours, minutes] = task.time_spent.split(':').map(Number);
                        const timeSpentMinutes = (hours * 60) + minutes;
                        totalTimeSpentInMinutes += timeSpentMinutes;
                    }

                    // Add the estimated time for finished tasks
                    if (parseInt(task.progress, 10) === 100) {
                        totalEstimatedForFinishedTasks += task.estimated_duration || 0;
                    }
                });

                // Calculate remaining estimated time (total estimated time - finished tasks' estimated time)
                const totalEstimatedTimeRemaining = totalEstimatedTime - totalEstimatedForFinishedTasks;

                // Format times
                const formatTime = (minutes) => {
                    const hours = Math.floor(minutes / 60);
                    const remainingMinutes = minutes % 60;
                    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
                };

                const formattedTotalEstimatedTime = formatTime(totalEstimatedTime);
                const formattedTotalTimeSpent = formatTime(totalTimeSpentInMinutes);
                const formattedRemainingTime = formatTime(totalEstimatedTimeRemaining);
                const formattedTotalEstimatedForFinishedTasks = formatTime(totalEstimatedForFinishedTasks);

                // Filter finished and in-progress tasks
                const finishedTasks = tasks.filter((task) => parseInt(task.progress, 10) === 100);
                const inProgressTasks = tasks.filter((task) => parseInt(task.progress, 10) < 100);

                // Update state with all calculated data
                setTotalEstimatedTime(formattedTotalEstimatedTime); // Temps total planifié
                setTotalRemainingEstimatedTime(formattedRemainingTime); // Temps planifié restant
                setTotalEstimatedPerTasksFinished(formattedTotalEstimatedForFinishedTasks); // Estimated time for completed tasks
                setTotalTimeSpent(formattedTotalTimeSpent); // Total time spent
                setFinishedTasks(finishedTasks);
                setInProgressTasks(inProgressTasks);
            })
            .catch((error) => console.error('Error fetching tasks:', error));
    };


    const handleCommentSection = (value) => {
        // Update the local state with the new value
        setPositionNote(value);
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        // Assuming you have an API endpoint to update the position note
        axios.post(`${API_BASE_URL}/update_position_note`, {
            note: value, id: positionId
        }).then(response => {
            const {data} = response;
            if (data.status === 'ok') {
            } else {
                console.error('Error updating position note:', data.message);
            }
        }).catch(error => {
            console.error('Error updating position note:', error);
        });
    };
    const deleteTasks = (position) => {
        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        const res = window.confirm("Voulez-vous vraiment supprimer toutes les tâches en cours ? Cela n'impactera pas les tâches déjà terminées.");

        if (res) {
            if (!positionId || isNaN(positionId)) {
                throw new Error('Invalid position ID in URL');
            }

            axios.post(`${API_BASE_URL}/delete_position_tasks`, {id: positionId})
                .then(response => {
                    fetchTasks();
                })
                .catch(error => {
                    console.error('Error fetching default tasks:', error);
                });
        }
    }


    // Fetch tasks when component mounts
    useEffect(() => {
        fetchTasks();
    }, [position]); // Fetch tasks when the position changes


    const createDefaultTasks = () => {

        const currentUrl = window.location.href;
        const positionId = currentUrl.split('/').pop();

        if (!positionId || isNaN(positionId)) {
            throw new Error('Invalid position ID in URL');
        }

        axios.post(`${API_BASE_URL}/get_default_tasks`, {positionId})
            .then(response => {
                const defaultTasks = response.data.default_tasks; // It's an array

                if (defaultTasks.length > 0) {
                    const tasksArray = defaultTasks.map(task => task.id);

                    console.log(tasksArray)

                    axios.post(`${API_BASE_URL}/set_default_tasks`, {tasks: tasksArray, positionId})
                        .then(() => {
                            // Fetch updated tasks after creating default tasks
                            fetchTasks();
                        })
                        .catch(error => {
                            console.error('Error creating tasks:', error);
                        });
                } else {
                    console.log('No default tasks found.');
                }
            })
            .catch(error => {
                console.error('Error fetching default tasks:', error);
            });

    };

    return (<Fragment>
        {position.map((position) => (<Fragment>

                {position.is_archived === 1 && <div>
                    <span className={"archived-div"}><p>🗑️ Attention, ce projet est archivé</p></span></div>}
                {position.progress === 100 && <div>
                        <span
                            className={"finished-div"}><p>🎉 Ce projet a été terminé le {formatDate(position.end_date ? position.end_date : position.updated_at)}.</p></span>
                </div>}
                <div key={position.id}>
                    <div>
                        <h1 className={"h1-position-title"} dangerouslySetInnerHTML={{__html: position.name_1}}/>

                        <span className={"text-primary position-id-text"}>{position.order_id} - </span>
                        <span className={"text-primary position-id-text"}
                              dangerouslySetInnerHTML={{__html: position.title}}/><br/>
                        <hr/>
                        <div className={"position-container"}>

                            <div className={"flexwrap-wrap w-100"}
                                style={{
                                    display: "flex",
                                    justifyContent: localStorage.user_role === 'Administrateur' ? "space-between" : "initial"
                                }}>

                                <div style={{padding: "20px 0"}} className={"display-flex gap-20 w-100 container-position-buttons-time"}>

                                    <Fragment>
                                        <div className={"display-flex flex-direction-column "}>
                                            <div
                                                className={'gap-10 display-flex inline-buttons justify-content-space-evenly h-100'}>
                                                <button
                                                    className={"no-bg  display-flex align-items-center w-100 p-0 cursor-pointer"}
                                                    onClick={() => createDefaultTasks(position)}>
                                                    <span
                                                        style={{
                                                            backgroundColor: "rgb(72, 159, 68)",
                                                            borderRadius: "5px 0px 0 5px"
                                                        }}
                                                        className={"display-flex flex-direction-row h-100 align-items-center "}>

                                                    <i className="fa-regular fa-plus pl-10 color-white"></i>
                                                    <div className={"h-separator m-10 mr-0"}
                                                         style={{backgroundColor: "white"}}/>
                                                    </span>
                                                    <span
                                                        className={"create-task p-0 button-action w-100 h-100 display-flex text-left"}>
                                                    <span className={"p-10 "}>Créer les tâches par défaut</span>
                                                    </span>
                                                </button>

                                                <button
                                                    className={"no-bg display-flex align-items-center w-100 p-0 cursor-pointer"}
                                                    onClick={() => deleteTasks(position)}>
                                                    <span
                                                        style={{
                                                            backgroundColor: "orangered",
                                                            borderRadius: "5px 0px 0 5px"
                                                        }}
                                                        className={"display-flex flex-direction-row h-100 align-items-center "}>

                                                    <i className="fa-regular fa-trash-can pl-10 color-white"></i>
                                                    <div className={"h-separator m-10 mr-0"}
                                                         style={{backgroundColor: "white"}}/>
                                                    </span>
                                                    <span
                                                        className={"delete p-0 button-action w-100 h-100 display-flex align-center"}>
                                                    <span className={"p-10 "}>Supprimer toutes les tâches</span>
                                                    </span>
                                                </button>

                                                <button
                                                    className={"no-bg bg-third display-flex align-items-center w-100 p-0 cursor-pointer"} style={{borderRadius: 8}}
                                                    onClick={() => window.location.href = (`/client/${position.contact_id}`)}
                                                >

                                                    <span
                                                        style={{
                                                            backgroundColor: "var(--third)",
                                                            borderRadius: "5px 0px 0 5px"
                                                        }}
                                                        className={"display-flex flex-direction-row h-100 align-items-center "}>

                                                    <i className="fa-regular fa-external-link pl-10 color-white" style={{fontSize: "12.6px"}}></i>
                                                    <div className={"h-separator m-10 mr-0"}
                                                         style={{backgroundColor: "white"}}/>
                                                    </span>
                                                    <span
                                                        className={"see-customer p-0 button-action w-100 h-100 display-flex align-center"}>
                                                    <span className={"p-10"}>Voir la fiche client</span>
                                                    </span>
                                                </button>
                                                <button
                                                    className={"no-bg  display-flex align-items-center w-100 p-0 cursor-pointer"}
                                                    onClick={savePosition}>
                                                    <span
                                                        style={{
                                                            backgroundColor: "#03a1d5",
                                                            borderRadius: "5px 0px 0 5px"
                                                        }}
                                                        className={"display-flex flex-direction-row h-100 align-items-center "}>

                                                    <i className="fa-regular fa-floppy-disk pl-10 color-white"></i>
                                                    <div className={"h-separator m-10 mr-0"}
                                                         style={{backgroundColor: "white"}}/>
                                                    </span>
                                                    <span
                                                        className={"save p-0 button-action w-100 h-100 display-flex align-center"}>
                                                    <span className={"p-10 "}>Sauvegarder</span>
                                                    </span>
                                                </button>

                                            </div>


                                        </div>

                                        {isOpenTimingInfo && <PositionTimingInfo data={{
                                            totalTimeSpent: totalTimeSpent,
                                            totalRemainingEstimatedTime: totalRemainingEstimatedTime,
                                            totalEstimatedTime: totalEstimatedTime,
                                            totalEstimatedPerTasksFinished: totalEstimatedPerTasksFinished
                                        }}/>}

                                    </Fragment>
                                </div>


                            </div>

                            <div className={"display-flex container-position "}>
                                <div>
                                    <div className={"position-container left container-positions flex-1 pr-20"}
                                         style={{height: "fit-content"}}>

                                        <div className={"flex border-radius-10"}>
                                            <ClientCard client={position} displayButtonFiche={true}
                                                        headClasses={"border-221-1px border-radius-10 hover-lightgray no-radius-bottom border-bottom-none"}
                                                        contentClasses={"border-radius-10 no-radius-top border-solid-1px border-color-ddd no-border-top"}/>
                                        </div>
                                        <div className={"position-component mb-20"}>
                                            <div
                                                className={`border-221-1px display-flex gap-20 color-third p-20 bg-eee border-radius-10 hover-lightgray cursor-pointer ${isOpenInfo ? 'no-radius-bottom' : ''}`}
                                                onClick={toggleOpenInfo}>
                                                <h3 className={"title-positions-menus color-third"}>
                                                    <i className="fa-solid fa-circle-info"
                                                       style={{marginRight: "10px"}}></i>
                                                    Informations générales
                                                </h3>
                                                <i
                                                    className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenInfo ? 'rotate' : ''}`}
                                                    style={{marginLeft: "10px"}}
                                                ></i>
                                            </div>
                                            {isOpenInfo && (
                                                <div
                                                    className={"p-15 flex bg-white border-radius-10 border-solid-1px no-radius-top no-border-top h-400"}
                                                    style={{borderColor: "#ddd"}}>

                                        <textarea
                                            className="position-note-textarea p-5 m-0 no-outline no-border h-100"
                                            id="position_note"
                                            style={{width: "100%", marginTop: 20}}
                                            onChange={(e) => handleCommentSection(e.target.value)}
                                            value={positionNote}
                                        /></div>)}
                                        </div>
                                        {/*
                                        <div className={"position-component mb-20"}>
                                            <div
                                                className={`border-221-1px display-flex gap-20 color-third p-20 bg-eee border-radius-10 hover-lightgray cursor-pointer ${isOpenTimingInfo ? 'no-radius-bottom' : ''}`}
                                                onClick={toggleTimingInfo}>
                                                <h3 className={"title-positions-menus color-third"}>

                                                    <i className="fa-solid fa-clock" style={{marginRight: "10px"}}></i>
                                                    Temps
                                                </h3>
                                                <i
                                                    className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenTimingInfo ? 'rotate' : ''}`}
                                                    style={{marginLeft: "10px"}}
                                                ></i>
                                            </div>
                                            {isOpenTimingInfo && <PositionTimingInfo data={{totalTimeSpent: totalTimeSpent,totalRemainingEstimatedTime:totalRemainingEstimatedTime, totalEstimatedTime:totalEstimatedTime, totalEstimatedPerTasksFinished:totalEstimatedPerTasksFinished}}/>}
                                        </div>*/}
                                        <div className={"position-component mb-20"}>
                                            <div
                                                className={`border-221-1px display-flex gap-20 color-third p-20 bg-eee border-radius-10 hover-lightgray cursor-pointer ${isOpenOpenPositions ? 'no-radius-bottom' : ''}`}
                                                onClick={toggleOpenPositions}>
                                                <h3 className={"title-positions-menus color-third"}>

                                                    <i className="fa-solid fa-list" style={{marginRight: "10px"}}></i>
                                                    Autres projets en cours
                                                </h3>
                                                <i
                                                    className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenOpenPositions ? 'rotate' : ''}`}
                                                    style={{marginLeft: "10px"}}
                                                ></i>
                                            </div>
                                            {isOpenOpenPositions && <OpenPositions id={position}/>}
                                        </div>

                                        <div className={"position-component"}>
                                            <div
                                                className={`display-flex border-221-1px gap-20 color-third p-20 bg-eee border-radius-10 hover-lightgray cursor-pointer ${isOpenClosedPositions ? 'no-radius-bottom' : ''}`}
                                                onClick={toggleClosedPositions}>

                                                <h3 className={"title-positions-menus color-third"}>
                                                    <i className="fa-solid fa-check" style={{marginRight: "10px"}}></i>
                                                    Autres projets terminés
                                                </h3>
                                                <i
                                                    className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenClosedPositions ? 'rotate' : ''}`}
                                                    style={{marginLeft: "10px"}}
                                                ></i>
                                            </div>
                                            {isOpenClosedPositions && <ClosedPositions id={position}/>}
                                        </div>

                                    </div>
                                </div>
                                <div className={"display-flex flex-direction-column flex-3 overflow-auto border-radius-10"}>

                                    <div className={"display-flex flex-direction-column overflow-auto border-radius-10 mb-20 border-solid-1px border-color-ddd border-radius-10 bg-eee"}>
                                        <div
                                            className="color-third toggle-button display-flex  gap-20 color-third p-20 hover-lightgray no-radius-bottom justify-content-space-between bg-eee"
                                            onClick={() => toggleIsOpenProgressTask()}><h3
                                            className={"color-third m-0 top-0"}>Tâches en cours
                                            ({inProgressTasks.length}/{inProgressTasks.length + finishedTasks.length})</h3>
                                            <i
                                                className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenProgressTask ? 'rotate' : ''}`}
                                                style={{marginLeft: "10px"}}
                                            ></i>
                                        </div>

                                        <div className={isOpenProgressTask ? "border-solid-1px-top border-color-ddd" : ""}>
                                            <div className={"position-component overflow-auto"}>


                                                {(isOpenProgressTask) && <Tasks
                                                    fetchTasks={fetchTasks}
                                                    searchBar={false}
                                                    title={null}
                                                    tasks={inProgressTasks}
                                                    displayAdminBar={true}
                                                    displayCheckbox={true}
                                                    headers={['%', 'Nom de la tâche', 'Durée estimée', 'Durée passée', 'Date de réalisation', 'Terminé le', 'Personne en charge']}
                                                    headersData={['progress', 'title_open_position', 'estimated_duration', 'time_spent', 'finish_date_custom', 'finish_date', 'username']}
                                                />}   {
                                                showAdditionnalTasks && <AdditionalTask position={position} update={refreshTasks} />

                                            }

                                            </div>

                                        </div>
                                    </div>

                                    <div
                                        className={"display-flex flex-direction-column overflow-auto border-radius-10 border-solid-1px border-color-ddd border-radius-10 bg-eee"}>

                                        <div
                                            className="color-third toggle-button display-flex  gap-20 color-third p-20 bg-eee hover-lightgray no-radius-bottom justify-content-space-between"
                                            onClick={() => toggleIsOpenFinishedTask()}><h3
                                            className={"color-third m-0 top-0"}>Tâches terminées
                                            ({finishedTasks.length}/{inProgressTasks.length + finishedTasks.length})</h3>
                                            <i
                                                className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenFinishedTask ? 'rotate' : ''}`}
                                                style={{marginLeft: "10px"}}
                                            ></i>
                                        </div>
                                        <div className={isOpenFinishedTask ? "border-solid-1px-top border-color-ddd" : ""}>
                                            <div className={"position-component overflow-auto"}>
                                                {(isOpenFinishedTask) && (<Tasks
                                                    searchBar={false}
                                                    title={null}
                                                    tasks={finishedTasks}
                                                    headers={['%', 'Nom de la tâche', 'Durée estimée', 'Durée passée', 'Terminé le', 'Personne en charge']}
                                                    headersData={['progress', 'title_open_position', 'estimated_duration', 'time_spent', 'finished_at', 'username_ended']}
                                                />)}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>

        ))}
    </Fragment>);
};

export default PositionDetail;
