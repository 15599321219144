import React, {useEffect, useState} from 'react';
import axios from 'axios';
import '../assets/css/BexioUpdates.css'
import Loading from "../components/Loading";
import {API_BASE_URL} from "../config";

const BexioUpdate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        document.title = "X | Mettre à jour Bexio"

    }, []);
    // Define a function to handle API updates
    const bexioUpdate = (type, name) => async () => {
        setIsLoading(true)
        setMessage(null)
        const urlMap = {
            customers: 'https://bexio.solution-digitale.ch/contacts',
            orders: `${API_BASE_URL}/bexio/get_orders`,
            positions: `${API_BASE_URL}/bexio/get_positions`,
            products: 'https://bexio.solution-digitale.ch/articles',
            invoices: `${API_BASE_URL}/bexio/get_invoices`,
            customers_relations: `${API_BASE_URL}/bexio/get_customers_relations`,
        };

        const url = urlMap[type];
        if (!url) {
            console.error('Unknown type for Bexio update');
            return;
        }

        try {
            const response = await axios.get(url);
            console.log(response)
            setIsLoading(false)
            setMessage(`${name} ont correctement été mis à jour !`)
        } catch (error) {
            setIsLoading(false)
            setMessage(`${error.response.data.message} (l'API est très certainement surchargée).`)
        }
    }

    return (
        <div>
            <h2>Mise à jour des données Bexio</h2>

            <div className={"container p-20 shadow"}>
                <i className="fa-regular fa-circle-info mr-10 mb-10"/>
                Les mises à jour se font automatiquement toutes les <strong>10 minutes</strong>, mais pouvez accélérer la mise à jour manuellement en sélectionnant le type de données Bexio que vous souhaitez faire remonter.
            <div className={"display-flex gap-10 container-update"}>
                <div className={"flex-1 update-item update-customers"} onClick={bexioUpdate('customers', "Les clients")}>
                    Mise à jour des clients
                </div>
                <div className={"flex-1 update-item update-orders"} onClick={bexioUpdate('orders', "Les commandes")}>
                    Mise à jour des commandes
                </div>
                <div className={"flex-1 update-item update-projects"} onClick={bexioUpdate('positions', "Les projets")}>
                    Mise à jour des projets
                </div>
                <div className={"flex-1 update-item update-invoices"} onClick={bexioUpdate('invoices', "Les factures")}>
                    Mise à jour des factures
                </div>
                <div className={"flex-1 update-item update-products"} onClick={bexioUpdate('products', "Les produits")}>
                    Mise à jour des produits
                </div>
                <div className={"flex-1 update-item update-customer-relations"} onClick={bexioUpdate('customers_relations', "Les relations clients")}>
                    Mise à jour des relations clients/entreprises
                </div>
            </div>
        </div>

            <div className={"text-center mt-10"}>
                {message && <span >{message}</span>}
            </div>

            {isLoading && (
                <Loading    />
            )}

        </div>
    );
};

export default BexioUpdate;
