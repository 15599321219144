import React, {Fragment, useState} from "react";
import TicketsNavbar from "./TicketsNavbar";
import { NavLink } from "react-router-dom";

const TicketsSideBar = ({ toggleTicketModal, updateFilters }) => {

    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

    const toggleTicketsSidebar = () => {
        setSidebarCollapsed((prev) => !prev)
    }
    
    return (
        <Fragment>


            <div
                className={`bg-white tickets-sidebar${sidebarCollapsed ? '-collapsed' : ''} tickets-table-container display-flex flex-direction-column overflow-auto box-shadow p-20 border-radius-10`}>

                {
                    window.innerWidth > 1500 &&
                <button className={"p-10 cursor-pointer bg-secondary mb-20"} style={{width: 50}} onClick={toggleTicketsSidebar}>

            <span>{
                sidebarCollapsed ?
                    <i className="fa-solid fa-chevron-right"></i> :
                    <i className="fa-solid fa-chevron-left"></i>
            }</span>

                </button>
                }
                <TicketsNavbar toggleTicketModal={toggleTicketModal}/>
                <div className="sidebar-tickets">
                    {/* Button to open the ticket modal */}
                    <div className={"flex"}>

                <span className="btn-submit-account ml-0 p-10" onClick={toggleTicketModal}>
          <i className="fas fa-solid fa-plus mr-10 "/>{!sidebarCollapsed && "Créer un ticket"}
        </span>
                    </div>

                    <div className={"flex"} onClick={() => updateFilters({status: "Ouvert", assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
    <i className="fas fa-solid fa-bars mr-10"/>{!sidebarCollapsed && "Tickets ouverts"}
  </span>
                    </div>

                    <div className={"flex"} onClick={() => updateFilters({status: "MesAssignés", assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
    <i className="fas fa-user-tag mr-10"/> {!sidebarCollapsed && "Mes tickets assignés"}
  </span>
                    </div>


                    {/* Résolu button */}
                    <div className={"flex"} onClick={() => updateFilters({status: "Résolu", assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
    <i className="fas fa-check mr-10"/> {!sidebarCollapsed && "Tickets résolus"}
  </span>
                    </div>

                    {/* Filter: Open & assignés à moi (tickets that are open and assigned to the current user) */}
                    <div className={"flex"} onClick={() => updateFilters({status: "MesRésolus", assigned: true})}>
  <span className="btn-submit-account ml-0 p-10">
            <i className="fas fa-solid fa-check-double mr-10"/>{!sidebarCollapsed && "Mes tickets résolus"}
          </span>
                    </div>
                    {/* Clôturé button */}
                    <div className={"flex"} onClick={() => updateFilters({status: "Clôturé", assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
    <i className="fas fa-times-circle mr-10"/> {!sidebarCollapsed && "Tickets clôturés"}
  </span>
                    </div>
                    <div className={"flex"} onClick={() => updateFilters({status: "MesClôturés", assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
    <i className="fas fa-user-check mr-10"/> {!sidebarCollapsed && "Mes tickets clôturés"}
  </span>
                    </div>

                    {/* Button to clear filters */}
                    <div className={"flex"} onClick={() => updateFilters({status: null, assigned: false})}>
  <span className="btn-submit-account ml-0 p-10">
            <i className="fas fa-solid fa-list mr-10"/>{!sidebarCollapsed && "Tous les tickets"}
          </span>
                    </div>
                    {/* Button to clear filters */}
                    <div className={"flex"} onClick={() => updateFilters({status: null, assigned: false})}>
  <span className="btn-submit-account ml-0 p-10 reinit-filters">
            <i className="fas fa-solid fa-times mr-10"/> {!sidebarCollapsed && "Réinitialiser les filtres"}
          </span>
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default TicketsSideBar;
