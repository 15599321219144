import React, {useState, useEffect, useRef, Fragment} from 'react';
import axios from 'axios';
import 'c3/c3.css';
import c3 from 'c3';
import '../assets/css/Productivity.css'; // Import CSS for styling
import {API_BASE_URL} from "../config";
import SearchBar from "../components/SearchDropdown";
import SearchDropdown from "../components/SearchDropdown";
import WIP from "../components/WIP";
import Table from "../components/Table";
import Select from "react-select";

const Productivity = () => {
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [data, setData] = useState([]);
    const [dataForTable, setDataForTable] = useState([]);
    const chartRef = useRef(null);
    const [error, setError] = useState("Merci de sélectionner un projet");

    useEffect(() => {
        document.title = 'X | Productivité'
    }, []);
    // Fetch the list of projects
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_projects_distinct_with_finished_positions`)
            .then(response => {
                console.log(response.data)
                setProjects(response.data);
                console.log(response.message);
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
                setError('Error fetching projects');
            });

    }, []);

    // Fetch productivity data when a project is selected
    useEffect(() => {
        if (selectedProject) {
            console.log(selectedProject);
            axios.post(`${API_BASE_URL}/get_productivity_positions`, {articleCode: selectedProject})
                .then(response => {
                    console.log("Data fetched:", response.data); // Log the response data
                    setData(response.data);
                    const sortedData = response.data.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
                    setDataForTable(sortedData);
                    setError(null)
                })
                .catch(error => {
                    console.error('Error:', error);
                    setError('Error fetching data');
                });
        }
    }, [selectedProject]);

    useEffect(() => {
        if (chartRef.current) {
            if (data.length > 0) {
                // French month names for the x-axis
                const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

                // Group data by year and month
                const dataByYear = {};
                data.forEach(item => {
                    if (item.task_month) {
                        // Extract year and month (assuming task_month is "YYYY-MM")
                        const [year, month] = item.task_month.split('-');
                        const monthIndex = parseInt(month, 10) - 1;

                        // Parse the avg_time_spent ("HH:MM:SS")
                        const [hours, minutes, seconds] = item.avg_time_spent.split(':').map(Number);
                        const totalMinutesForTask = hours * 60 + minutes + seconds / 60;

                        // Initialize the year if needed with an array of 12 placeholders
                        if (!dataByYear[year]) {
                            dataByYear[year] = Array.from({ length: 12 }, () => ({ sum: 0, count: 0 }));
                        }

                        // Sum the minutes and count the items for that month
                        dataByYear[year][monthIndex].sum += totalMinutesForTask;
                        dataByYear[year][monthIndex].count += 1;
                    }
                });

                // Build the series for C3: one series per year, each with 12 values (average hours per month)
                let columns = [];
                Object.keys(dataByYear).forEach(year => {
                    const series = [year];
                    dataByYear[year].forEach(({ sum, count }) => {
                        // Calculate average (if count is 0, push null so that no bar is rendered)
                        series.push(count > 0 ? (sum / count) / 60 : null); // converting minutes to hours
                    });
                    columns.push(series);
                });

                // Optionally, sort the columns by year (ascending)
                columns.sort((a, b) => a[0] - b[0]);

                // Define colors for specific years (extend as needed)
                const colors = {
                    "2024": "var(--primary)",
                    "2025": "var(--third)"
                    // add more year-to-color mappings if needed
                };

                // Generate the chart with multiple series
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current,
                    data: {
                        columns: columns,
                        type: 'bar',
                        colors: colors
                    },
                    axis: {
                        x: {
                            type: 'category',
                            categories: frenchMonths,
                            tick: {
                                rotate: -55,
                                multiline: false
                            },
                            height: 130
                        },
                        y: {
                            label: {
                                text: 'Temps moyen (Heures)',
                                position: 'outer-middle'
                            },
                            tick: {
                                format: d => {
                                    const hours = Math.floor(d);
                                    const minutes = Math.round((d - hours) * 60);
                                    return `${hours}h ${minutes}m`;
                                }
                            }
                        }
                    },
                    tooltip: {
                        format: {
                            title: (d) => `Mois: ${frenchMonths[d]}`,
                            value: (value) => {
                                const hours = Math.floor(value);
                                const minutes = Math.round((value - hours) * 60);
                                return `${hours}h ${minutes}m`;
                            }
                        },
                        grouped: false
                    },
                    bar: {
                        width: {
                            ratio: 0.8
                        }
                    }
                });
            } else {
                // Clear the chart when there is no data
                chartRef.current.__chart = c3.generate({
                    bindto: chartRef.current,
                    data: {
                        columns: [['Heures totales', 0]],
                        type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category',
                            categories: [],
                        },
                        y: {
                            label: {
                                text: 'Heures totales',
                                position: 'outer-middle'
                            }
                        }
                    }
                });
            }
        }
    }, [data, chartRef, selectedProject]);



    return (<div>
        <h2>Productivité</h2>
        <div>
            <div className={"container chart-container p-10 mb-20"}>
                <div className={"display-flex gap-10"}>
                    {<div className={"search-bar container flex-1 m-0"}>

                        <Select
                            noOptionsMessage={() => 'Aucun résultat trouvé'}
                            placeholder='Par exemple "solutionWEB PRO"'
                            options={projects.map(project => ({
                                id: project.id,  // Use id for key
                                value: project.intern_code,  // Use intern_code for value
                                label: project.intern_code + " " + project.intern_name  // Display both intern_code and intern_name
                            }))}
                            value={selectedProject}
                            onChange={(selectedOption) => {

                                const selectedProject = selectedOption.value; // Use the intern_code directly
                                setSelectedProject(selectedProject);  // Set the correct project intern_code
                            }}
                            isClearable={true}
                            styles={{
                                container: (provided) => ({
                                    ...provided, width: "100%", position: "relative"
                                }), control: (provided) => ({
                                    ...provided, width: "100%", textAlign: "left", height: "51px", borderRadius: 8, // Add left padding to account for the icon
                                    paddingLeft: "45px"
                                }),
                            }}
                        />
                    </div>}


                </div>
                <div className="display-flex mt-20 flex-direction-column gap-10">
                    <span>Souvent recherchés…</span>
                    <div className="display-flex gap-20 flex-direction-row flexwrap-wrap">
                        <span className={`button-third ${selectedProject === "SWBC" ? "btn-active" : ""}`}
                              onClick={() => setSelectedProject("SWBC")}>
                          solutionWEB Basic
                        </span>
                        <span className={`button-third ${selectedProject === "SWSC" ? "btn-active" : ""}`}
                              onClick={() => setSelectedProject("SWSC")}>
                            solutionWEB Standard
                        </span>
                        <span className={`button-third ${selectedProject === "SWPC" ? "btn-active" : ""}`}
                              onClick={() => setSelectedProject("SWPC")}>
                            solutionWEB Pro
                        </span>
                        <span className={`button-third ${selectedProject === "SPOS24" ? "btn-active" : ""}`}
                              onClick={() => setSelectedProject("SPOS24")}>
                            solutionPOST 24
                        </span>
                        <span className={`button-third ${selectedProject === "SPOS48" ? "btn-active" : ""}`}
                              onClick={() => setSelectedProject("SPOS48")}>
                            solutionPOST 48
                        </span>
                    </div>
                </div>
            </div>
        </div>


        <div className={"chart-container"}>
            {error && <p>{error}</p>}
            {selectedProject && (<><p>{selectedProject}</p>
                <div className={""} style={{height: "500px"}} ref={chartRef}/>
            </>)}


        </div>

        {(data.length > 0) && (<div className={"chart-container mt-20"}>

                <h2 className={"text-left"}><i className={"fa-solid fa-chart-line"}/> Moyennes </h2>
                <Table
                    data={dataForTable}
                    showActions={false}
                    hidePagination={false}
                    maxEntries={10}
                    headers={["N° Bexio commande", "Projet", "Client", "Date de fin de projet", "Temps passé", "Montant", "Tarif/Temps passé (prix à l'heure)"]}
                    headersData={['order_id', 'position_id_reporting', 'name_1_reporting', 'end_date', 'avg_time_spent', 'position_total', "avg_price_per_time_spent"]}
                />
                <div className={"display-flex"}>
                </div>
            </div>

        )}

    </div>);
};

export default Productivity;
