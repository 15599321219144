import React from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";

const AdditionalTask = ({position, update}) => {

    const addTask = () => {
        axios.post(`${API_BASE_URL}/get_default_additional_tasks`, [position])
            .then(res => {

                update()
            });

    }
    console.log(position.id)
    return (
        <button className={"button-action create-task ml-20 mb-20"} onClick={() => addTask()}>
            <i className="fa-regular fa-plus mr-5" aria-hidden="true"></i>
            Ajouter une tâche supplémentaire
        </button>
    );
};

export default AdditionalTask;