import React, { useState } from 'react';

const MenuOption = ({ className, children, type }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const getTypeStyle = () => {
        switch (type) {
            case 'new':
                return {
                    backgroundColor: isHovered ? 'white' : 'var(--primary)',
                    color: isHovered ? 'var(--primary)' : 'white',
                };
            case 'wip':
                return {
                    backgroundColor: isHovered ? 'white' : 'orange', // Change background color to orangered
                    color: isHovered ? 'orange' : 'white', // Change text color to white
                };
            default:
                return {
                    backgroundColor: isHovered ? 'white' : 'var(--primary)',
                    color: isHovered ? 'var(--primary)' : 'white',
                };
        }
    };

    return (
        <div
            className={`dns-lookup ${className} display-flex align-items-center`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <span
                className="new-menu"
                style={{
                    ...getTypeStyle(),
                    padding: '5px',
                    borderRadius: '10px',
                    marginRight: "10px",
                    fontSize: ".75rem",
                    fontWeight: "bold"
                }}
            >
                {type.toUpperCase()}
            </span>
            {children}
        </div>
    );
};

export default MenuOption;
