import React, {useEffect, useState} from "react";

const PositionTimingInfo = ({data}) => {
    const [totalTimeSpentColor, setTotalTimeSpentColor] = useState("");
    const [timeSpentDifference, setTimeSpentDifference] = useState("");

    useEffect(() => {
        const parseTimeToMinutes = (time) => {
            const [hours, minutes] = time.split(":").map(Number);
            return hours * 60 + minutes; // Convert to total minutes
        };

        const estimatedMinutes = parseTimeToMinutes(data.totalEstimatedPerTasksFinished);
        const spentMinutes = parseTimeToMinutes(data.totalTimeSpent);

        const difference = spentMinutes - estimatedMinutes; // Calculate the difference
        setTimeSpentDifference(
            difference >= 0
                ? `+${difference} min` // Exceeded planned time
                : `${difference} min` // Within planned time
        );

        // Set color: red if exceeded, otherwise black
        setTotalTimeSpentColor(difference > 0 ? "red" : "green");
    }, [data.totalEstimatedPerTasksFinished, data.totalTimeSpent]);

    return (
        <div
            className={"flex border-radius-10 border-solid-1px bg-eee"}
            style={{borderColor: "#ddd"}}
        >
            <div
                className={"positions-list w-100 display-flex flex-direction-row h-100"}
                style={{textDecoration: "none", color: "var(--third)"}}
            >
                <div
                    className={
                        "p-10 border-radius-5 w-100 display-flex flex-direction-row gap-10 justify-content-space-evenly align-items-center timings-container"
                    }
                >
                    <div className={"display-flex flex-direction-column   gap-10 align-items-center"}>
                        <i
                            className="fa-regular fa-stopwatch"
                            style={{marginRight: "5px"}}
                        />
                        <span style={{fontSize: "1rem"}} className={"text-center"}>
                            Temps de réalisation actuel
                        </span>
                        <strong>{data.totalTimeSpent} </strong>
                    </div>

                    <div className={"display-flex flex-direction-column gap-10 align-items-center"}>
                        <i
                            className="fa-regular fa-hourglass-end"
                            style={{marginRight: "5px"}}
                        ></i>
                        <span style={{fontSize: "1rem"}} className={"text-center"}>
                            Temps total planifié
                        </span>
                        <strong>{data.totalEstimatedTime}</strong>
                    </div>
                    <div className={"display-flex flex-direction-column gap-10 align-items-center"}>
                        <i
                            className="fa-regular fa-hourglass-half"
                            style={{marginRight: "5px"}}
                        ></i>
                        <span style={{fontSize: "1rem"}} className={"text-center"}>
                            Temps planifié restant
                        </span>
                        <strong>{data.totalRemainingEstimatedTime}</strong>
                    </div>
                    <div className={"display-flex flex-direction-column gap-10 align-items-center"}>
                        <i
                            className="fa-solid fa-hourglass-clock"
                            style={{marginRight: "5px"}}
                        ></i>
                        <span style={{fontSize: "1rem"}} className={"text-center"}>
                            Retard ou avance

                        </span><strong
                        style={{color: totalTimeSpentColor}}> {timeSpentDifference} </strong>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PositionTimingInfo;
