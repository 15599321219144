import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../../config";
import {useParams} from "react-router-dom";

const SectionTechniqueDetailsDnsZone = () => {
    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    const [DNSZone, setDNSZone] = useState('');
    const [tempDNSZone, setTempDNSZone] = useState('');
    const [addDNS, setAddDNS] = useState(false);

    const toggleAddDNS = () => {
        setAddDNS((prev) => !prev);
        setTempDNSZone(DNSZone || ''); // Ensure textarea starts with the current DNS zone
    };

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_customer_dns_zone/`, { id })
            .then(res => {
                const zone = res.data.result[0].zone || ''; // Ensure fallback to empty string
                setDNSZone(zone);
                setTempDNSZone(zone);
            })
            .catch(err => console.error('Error fetching DNS zone:', err));
    }, [id]);

    const saveDNSZone = () => {
        axios.post(`${API_BASE_URL}/set_customer_dns_zone/`, { zone: tempDNSZone, customer_id: id })
            .then(res => {
                setDNSZone(tempDNSZone);
                console.log(res.data);
                setAddDNS(false); // Hide textarea after saving
            })
            .catch(err => console.error('Error saving DNS zone:', err));
    };

    return (
        <div>
            <div className={"display-flex flex-direction-column gap-20"}>
                <label><h3>Zone DNS (la plus complète possible)</h3></label>
                <textarea
                    style={{fontFamily: "Consolas"}}
                    className="position-note-textarea min-h-400"
                    value={tempDNSZone}
                    onChange={(e) => setTempDNSZone(e.target.value)}
                />
            </div>

            <button onClick={saveDNSZone} className="button-third mt-20">Enregistrer</button>
        </div>
    );
};

export default SectionTechniqueDetailsDnsZone;
