import React, { useEffect, useState } from "react";
import TicketModal from "../components/Ticketing/TicketModal";
import TicketsTable from "../components/Ticketing/TicketsTable";
import axios from "axios";
import { API_BASE_URL } from "../config";
import TicketsSideBar from "../components/Ticketing/TicketsSideBar";
import { useLocation } from "react-router-dom";

const Tickets = () => {
    const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fetchTicketsTrigger, setFetchTicketsTrigger] = useState(false);
    const location = useLocation();

    // Filter state:
    //   status: "Ouvert" for open tickets or null for no filtering on status.
    //   assigned: true to filter for tickets assigned to the current user.
    const [filters, setFilters] = useState({
        status: null,
        assigned: false,
    });

    // Update the filter state
    const updateFilters = (newFilter) => {
        setFilters((prev) => ({ ...prev, ...newFilter }));
    };

    // Toggle the modal and optionally re-fetch tickets if a ticket was saved
    const toggleTicketModal = (state, status) => {
        setIsTicketModalOpen((prev) => !prev);
        if (status === "saved") {
            setFetchTicketsTrigger((prev) => !prev);
        }
    };

    // Fetch tickets from the backend (filtering will be applied client‑side)
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const id = urlParams.get("id");
        const queryParams = id ? `?id=${id}` : "";

        setLoading(true);
        axios
            .post(`${API_BASE_URL}/get_all_tickets${queryParams}`)
            .then((res) => {
                setTickets(res.data.tickets || []);
            })
            .catch((error) => {
                console.error("Error fetching tickets:", error);
                setTickets([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [location.search, fetchTicketsTrigger]);

    // Convert the user id from localStorage to a number
    const currentUserId = Number(localStorage.getItem("user_id"));

    const filteredTickets = tickets.filter((ticket) => {
        let include = true;

        const assignedUsers = ticket.assigned_user
            ? ticket.assigned_user.split(',').map(Number)
            : [];

        if (filters.status === "nonRésolu") {
            return ticket.status !== "Résolu" && ticket.status !== "Clôturé";
        }

        if (filters.status === "MesRésolus") {
            return (ticket.status === "Résolu" || ticket.status === "Clôturé") &&
                assignedUsers.includes(currentUserId);
        }

        if (filters.status === "MesClôturés") {
            return ticket.status === "Clôturé" && assignedUsers.includes(currentUserId);
        }

        if (filters.status === "MesAssignés") {
            return assignedUsers.includes(currentUserId);
        }

        if (filters.status === "Résolu") {
            return ticket.status === "Résolu";
        }

        if (filters.status === "Clôturé") {
            return ticket.status === "Clôturé";
        }

        if (filters.status) {
            return ticket.status === filters.status;
        }

        if (filters.assigned) {
            include = include && assignedUsers.includes(currentUserId);
        }

        return include;
    });



    return (
        <div className={"h-100"}>
            <h2 className="color-third mt-0">Tickets</h2>
            <div className="display-flex tickets-tab gap-20 p-0">
                <TicketsSideBar
                    toggleTicketModal={toggleTicketModal}
                    updateFilters={updateFilters}
                />
                <TicketsTable tickets={filteredTickets} loading={loading} />
                {isTicketModalOpen && (
                    <TicketModal closeModal={(status) => toggleTicketModal(false, status)} />
                )}
            </div>
        </div>
    );
};

export default Tickets;
