import React from 'react';
import { NavLink } from 'react-router-dom';

const Button = ({
                    to,
                    label,
                    icon,
                    iconClasseNames,
                    textClassNames,
                    iconBackground,
                    disableLink,
                    onClick, // <-- accept onClick as a prop
                }) => {
    const buttonContent = (
        <button
            className="no-bg display-flex align-items-center p-0 cursor-pointer"
            style={{ borderRadius: '5px 0 0 5px' }}
            onClick={onClick} // <-- pass it here
        >
      <span
          className={`display-flex flex-direction-row h-100 align-items-center ${iconBackground}`}
          style={{ borderRadius: '5px 0px 0px 5px' }}
      >
        <i className={`pl-10 fa-solid ${icon} ${iconClasseNames}`} />
        <div
            className="h-separator m-10 mr-0"
            style={{ backgroundColor: 'white' }}
        />
      </span>
            <span
                className={`p-10 button-action w-100 h-100 display-flex align-center bg-third ${textClassNames}`}
            >
        <span className="color-white" style={{ fontSize: '.9rem' }}>
          {label}
        </span>
      </span>
        </button>
    );

    // If disableLink is true, don't wrap with NavLink; otherwise, wrap it.
    return disableLink ? (
        buttonContent
    ) : (
        <NavLink to={to} target="_blank" className="display-flex text-decoration-none">
            {buttonContent}
        </NavLink>
    );
};

export default Button;
