import React, {useRef, useEffect} from 'react';
import Chart from 'chart.js/auto';

const RenewalsChart = ({renewalYear1, renewalYear2, renewalYear3, type, displayData}) => {
    const chartRef = useRef(null);

    console.log(renewalYear1)
    console.log(renewalYear2)
    console.log(renewalYear3)
    useEffect(() => {

        // On met un try catch au cas ou si y a pas de données de renouvellement, ça affiche rien au lieu d'essayer de faire une ref sur un élément qui existe pas
        try {
            const ctx = chartRef.current.getContext('2d');
            Chart.defaults.font.family = "Montserrat, sans-serif";

            const doughnutChart = new Chart(ctx, {
                type: type || 'doughnut', data: {
                    labels: ['1e année', '2e année', 'Renouvellements tacites'], datasets: [{
                        data: [renewalYear1, renewalYear2, renewalYear3],
                        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)', 'rgba(255, 206, 86, 0.6)'],
                        borderWidth: 1
                    }]
                }, options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const label = context.label || '';
                                    const value = context.parsed;
                                    return `${label}: ${value.toLocaleString('en-CH', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} CHF`;
                                }
                            }
                        }
                    }

                }
            });
            return () => {
                doughnutChart.destroy();
            };
        } catch (error) {
            console.log(error)
        }

    }, [renewalYear1, renewalYear2, renewalYear3]);

    return (<div className={"display-flex align-items-center h-100"}>
            {(renewalYear1 > 0 && renewalYear2 > 0 && renewalYear3 > 0) && (
                <div className="display-flex flex-direction-column p-20">
                    <canvas ref={chartRef}></canvas>
                </div>)}
            {displayData && <div className={"display-flex flex-direction-column p-20"}>
                <div className={"display-grid text-left p-20 h-100 gap-20"}>

                    <div>
                        <h4 className={"mt-0" }>
                            1e année
                        </h4>
                        <div>{renewalYear1.toLocaleString('en-CH', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                        })} CHF
                        </div>
                    </div>
                    <div>

                        <h4>2e année</h4>
                        <div>{renewalYear2.toLocaleString('en-CH', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                        })} CHF
                        </div>
                    </div>
                    <div>

                        <h4>Renouvellements tacites</h4>
                        <div>{renewalYear3.toLocaleString('en-CH', {
                            minimumFractionDigits: 2, maximumFractionDigits: 2
                        })} CHF
                        </div>
                    </div>
                </div>
            </div>}
        </div>);
};

export default RenewalsChart;
