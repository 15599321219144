import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../../config";
import {useParams} from "react-router-dom";

const SectionTechniqueDetailsHebergeur = () => {
    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    const [provider, setProvider] = useState('')
    const [tempProvider, setTempProvider] = useState('')

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_customer_provider/`, {id})
            .then(res => {
                setProvider(res.data.results);
                setTempProvider(res.data.results);

            })
            .catch(err => console.error('Error fetching DNS zone:', err));
    }, [id]);

    const handleProvider = (e) => {
        setTempProvider(e)
    }

    const saveProvider = () => {
        //e.preventDefault();
        console.log(tempProvider)
        axios.post(`${API_BASE_URL}/set_customer_provider/`, {customer_id: id, customer_provider: tempProvider })
            .then(res => {
                console.log(res.data.results)
                setProvider(res.data.results);
                setTempProvider(res.data.results);

            })
            .catch(err => console.error('Error fetching DNS zone:', err));
    }

    return (<>
        <div className={"display-flex flex-direction-column gap-20"}>

            <div className={"display-flex gap-20 flex-direction-column mb-20"}>


                <label><h3>Fournisseur de services (exemple: Local, Wix, ...)</h3></label>

                <input className={"crop-text input p-10"} placeholder={"Wix..."}
                       value={tempProvider} onChange={(e) => handleProvider(e.target.value)}/>

            </div>

        </div>

        <button className={"button-third"} onClick={() => saveProvider()}>Enregistrer</button>
    </>);
};

export default SectionTechniqueDetailsHebergeur;