// Custom date formatting function
export const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
};

// Function to get date range based on filter
export const getDateRange = (filter, customStartDate, customEndDate) => {
    let endDate = customEndDate ? new Date(customEndDate) : new Date(); // Always create a new Date object
    const today = new Date();
    let startDate = new Date(endDate); // Create a copy of endDate
    let days = 0;

    switch (filter) {
        case 'lastMonth': {
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1); // First day of last month
            endDate = new Date(today.getFullYear(), today.getMonth(), 0); // Last day of last month
            break;
        }
        case 'lastWeek':

            const currentDayOfWeek = today.getDay();
            const lastMondayOffset = currentDayOfWeek === 0 ? -6 : 1 - currentDayOfWeek;

            startDate = new Date(today);
            startDate.setDate(today.getDate() + lastMondayOffset - 7); // Subtract 7 more days to get the previous week's Monday

            endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + 6); // Move forward 6 days to reach Sunday

            break;
        case 'lastYear':

            // Start date: January 1st of the last year
            startDate = new Date(today.getFullYear() - 1, 0, 1);

            // End date: December 31st of the last year
            endDate = new Date(today.getFullYear() - 1, 11, 31); // December is month 11 (0-indexed)
            break;
        case 'thisWeek': {
            // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
            const dayOfWeek = today.getDay();

            // Calculate the offset to the most recent Monday
            const mondayOffset = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;

            // Start date: Monday of the current week
            startDate = new Date(today); // Create a new date object
            startDate.setDate(today.getDate() + mondayOffset); // Adjust to Monday

            // End date: Sunday of the current week (6 days after Monday)
            endDate = new Date(startDate); // Create a new date object from startDate (Monday)
            endDate.setDate(startDate.getDate() + 6); // Move forward 6 days to reach Sunday

            break;
        }
        case 'thisMonth':
            startDate = new Date(today.getFullYear(), today.getMonth(), 1); // First day of last month
            endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            break;
        case 'thisYear':
// Start date: January 1st of the current year
            startDate = new Date(today.getFullYear(), 0, 1);

            // End date: December 31st of the current year
            endDate = new Date(today.getFullYear(), 11, 31); // December is month 11 (0-indexed)
            break;
        case 'custom': {
            if (customStartDate) {
                startDate = new Date(customStartDate); // Create a new Date from customStartDate
                days = (customEndDate - customStartDate) / (1000 * 60 * 60 * 24) + 1;
            }
            break;
        }
        default:
            return [];
    }

    // For date ranges other than 'custom', 'thisWeek', 'thisMonth', 'thisYear'
    if (!['custom', 'lastWeek', 'thisWeek', 'thisMonth', 'thisYear', 'lastMonth', 'lastYear'].includes(filter)) {
        startDate.setDate(endDate.getDate() - days); // Modify a new Date object
    }

    // Collect the date range
    const dateRange = [];
    let currentDate = new Date(startDate); // Create a copy of startDate for iteration

    while (currentDate <= endDate) {
        dateRange.push(formatDate(new Date(currentDate))); // Push formatted date
        currentDate.setDate(currentDate.getDate() + 1); // Increment the day
    }

    return dateRange; // Return the array of formatted dates
};
export function formatDateBeautiful(dateInput) {
    if (!dateInput) return "N/A";

    const date = new Date(dateInput);

    // Check if the date is valid
    if (isNaN(date)) return "N/A";

    // Get day, month (in French), and year
    const day = date.getDate();
    const month = date.toLocaleString("fr-FR", { month: "long" });
    const year = date.getFullYear();

    // Get hours and minutes with leading zeros
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} - ${hours}:${minutes}`;
}

export function displayDateHMM(dateInput) {
    const timeParts = dateInput.split(':'); // Split time string by ':'
    const hours = parseInt(timeParts[0], 10); // Get hours
    const minutes = parseInt(timeParts[1], 10); // Get minutes

    // Build the time string based on hours and minutes
    let displayTime = '';
    if (hours > 0) {
        displayTime += `${hours} h`; // Pluralize 'heure' if necessary
    }
    if (minutes > 0) {
        if (hours > 0) displayTime += ' '; // Add space between hours and minutes
        displayTime += `${minutes} min`; // Pluralize 'minute' if necessary
    }

    return displayTime

}
export function formatDateDDMMYYYY(dateInput) {
    if (!dateInput) return "N/A";

    const date = new Date(dateInput);

    // Check if the date is valid
    if (isNaN(date)) return "N/A";

    // Get day, month (in French), and year
    const day = date.getDate();
    const month = date.getMonth() +1;
    const year = date.getFullYear();

    // Get hours and minutes with leading zeros
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
}