import React, {useEffect, useState, Fragment} from 'react';
import {Link, NavLink, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {API_BASE_URL} from '../config';
import '../assets/css/Client.css';
import Loading from "../components/Loading";
import RenewalCustomerCard from "../components/RenewalCustomerCard";
import '../assets/css/Dashboard.css';
import {formatDateBeautiful, formatDateDDMMYYYY} from "../utils/dateUtils";
import TicketModal from "../components/Ticketing/TicketModal";
import loading from "../components/Loading";
import SectionTechnique from "../components/Client/SectionTechnique";
import SectionTechniqueDetails from "../components/Client/SectionTechniqueDetails";
import SectionTechniqueDetailsHebergeur from "../components/Client/SectionTechniqueDetailsHebergeur";
import SectionTechniqueDetailsEmails from "../components/Client/SectionTechniqueDetailsEmails";
import SectionTechniqueDetailsDNSZone from "../components/Client/SectionTechniqueDetailsDNSZone";
import SectionTechniqueDetailsResume from "../components/Client/SectionTechniqueDetailsResume";
import SectionTechniqueDetailsContactTechnique from "../components/Client/SectionTechniqueDetailsContactTechnique";
import SectionTechniqueDetailsDomaine from "../components/Client/SectionTechniqueDetailsDomaine";
import InfosBexio from "../components/Client/InfosBexio";
import DetailsEntreprise from "../components/Client/DetailsEntreprise";

const Client = () => {
    const navigate = useNavigate();
    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    const [positions, setPositions] = useState([]);
    const [totalPositions, setTotalPositions] = useState([]);
    const [totalPositionsSearch, setTotalPositionsSearch] = useState([]);
    const [customerName1, setCustomerName1] = useState(null);
    const [customerName2, setCustomerName2] = useState(null);
    const [email, setEmail] = useState('');
    const [consultantEmail, setConsultantEmail] = useState(null);
    const [feedback, setFeeback] = useState(null);
    const [businessData, setBusinessData] = useState([]);
    const [messageState, setMessageState] = useState('Envoyer');
    const [avis, setAvis] = useState([]); // Initialize as an empty array
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [customerTickets, setCustomerTickets] = useState([]);
    const [websites, setWebsites] = useState([])
    const [posts, setPosts] = useState([])
    const [modalTickets, setModalTickets] = useState(false)
    const [sortBy, setSortBy] = useState({column: null, ascending: true});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTicketTerm, setSearchTicketTerm] = useState('');
    const [totalTicketsSearch, setTotalTicketsSearch] = useState([]);
    const [contactPeople, setContactPeople] = useState([])
    const [activeUser, setActiveUser] = useState([]);
    const [activeTab, setActiveTab] = useState('home');

    const [activeTechOption, setActiveTechOption] = useState('resume');
    const [formData, setFormData] = useState({
        subject: '', url: '', clientEmail: '', clientName: '', contentFile: '', clientID: ''
    });


    const toggleModalTicket = () => {
        setModalTickets((prev) => !prev)
    }
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [siteUrl, setSiteUrl] = useState('');

    const handleTemplateChange = (e) => {
        const value = e.target.value;
        setSelectedTemplate(value);
        setFormData({...formData, subject: value});
        console.log(value)
        if (value !== "Votre nouveau site est en ligne") {
            setSiteUrl('');
        }
    };

    const handleSearchChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);

        if (term.length >= 1) {
            const filtered = totalPositions.filter(item => ((item.url && item.url.toLowerCase().includes(term.toLowerCase())) || (item.address && item.address.toLowerCase().includes(term.toLowerCase())) || (item.name_1 && item.name_1.toLowerCase().includes(term.toLowerCase())) || (item.branch_names && item.branch_names.toLowerCase().includes(term.toLowerCase())) || (item.city && item.city.toLowerCase().includes(term.toLowerCase())) || (item.version && item.version.toLowerCase().includes(term.toLowerCase())) || (item.server && item.server.toLowerCase().includes(term.toLowerCase())) || (item.username && item.username.toLowerCase().includes(term.toLowerCase())) || (item.title && item.title.toLowerCase().includes(term.toLowerCase())) || (item.order_id && item.order_id.toString().includes(term))));
            setTotalPositionsSearch(filtered);
        } else {
            setTotalPositionsSearch(totalPositions);
        }
    };
    const handleSearchTicketChange = (event) => {
        const term = event.target.value;
        setSearchTicketTerm(term);

        if (term.length >= 1) {
            const filtered = totalPositions.filter(item => ((item.url && item.url.toLowerCase().includes(term.toLowerCase())) || (item.address && item.address.toLowerCase().includes(term.toLowerCase())) || (item.name_1 && item.name_1.toLowerCase().includes(term.toLowerCase())) || (item.branch_names && item.branch_names.toLowerCase().includes(term.toLowerCase())) || (item.city && item.city.toLowerCase().includes(term.toLowerCase())) || (item.version && item.version.toLowerCase().includes(term.toLowerCase())) || (item.server && item.server.toLowerCase().includes(term.toLowerCase())) || (item.username && item.username.toLowerCase().includes(term.toLowerCase())) || (item.title && item.title.toLowerCase().includes(term.toLowerCase())) || (item.order_id && item.order_id.toString().includes(term))));
            setCustomerTickets(filtered);
        } else {
            setCustomerTickets(customerTickets);
        }
    };

    useEffect(() => {

        if (searchTerm === '') {
            setTotalPositionsSearch(totalPositions)
        }
        if (searchTicketTerm === '') {
            setTotalTicketsSearch(customerTickets)
        }
    }, []);

    useEffect(() => {

        axios.post(`${API_BASE_URL}/get_bexio_number`, {id: id})
            .then((res) => {
                const bexio_nr = (res.data[0].nr)
                axios.post(`${API_BASE_URL}/get_customer_websites`, {id: bexio_nr})
                    .then((res) => {
                        setWebsites(res.data)
                    })
                /*axios.post(`${API_BASE_URL}/get_customer_posts`, {id})
                    .then((res) => {
                        setPosts(res.data)
                    })*/

                axios.post(`${API_BASE_URL}/get_tickets_per_customer_client_query`, {id})
                    .then(res => {
                        if (res.data) {
                            setCustomerTickets(res.data)
                        }
                    });
            })


    }, []);


    const handleSiteUrlChange = (e) => {
        const value = e.target.value;
        setSiteUrl(value);
        setFormData({...formData, url: value});
    };

    const columnMapping = {
        "Identifiant": "id", "Nom du projet": "title", 'Date de création du projet': "creation_date"
        // Add more mappings as needed
    };
    const handleSort = (columnName) => {
        const actualColumn = columnMapping[columnName] || columnName;
        const ascending = actualColumn === sortBy.column ? !sortBy.ascending : true;
        setSortBy({column: actualColumn, ascending});
    };

    const publishedArray = Array.isArray(positions) ? positions : [];
    const sortedArray = [...publishedArray].sort((a, b) => {
        if (sortBy.column === 'id') {
            return sortBy.ascending ? a.id - b.id : b.id - a.id;
        } else if (sortBy.column === 'title') {
            return sortBy.ascending ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
        } else if (sortBy.column === 'creation_date') {
            const branchNameA = a.branch_names;
            const branchNameB = b.branch_names;

            if (branchNameA === null || branchNameA === undefined) return sortBy.ascending ? 1 : -1;
            if (branchNameB === null || branchNameB === undefined) return sortBy.ascending ? -1 : 1;

            return sortBy.ascending ? branchNameA.localeCompare(branchNameB) : branchNameB.localeCompare(branchNameA);
        }
        return 0; // Default case
    });
    const handleToggle = () => {
        const newValue = !isChecked ? 1 : 0;
        setIsChecked(!isChecked);
        axios.post(`${API_BASE_URL}/update_customer_avis`, {id, avis: newValue});
    };
    const handleToggleRenewal = (product) => {
        const newValue = !isChecked ? 1 : 0;
        setIsChecked(!isChecked);
        axios.post(`${API_BASE_URL}/update_product_cancellation`, {id, renewal: newValue});
    };


    useEffect(() => {
        // Fetch customer avis
        axios.post(`${API_BASE_URL}/get_customer_avis`, {id: id})
            .then(res => {
                setAvis(res.data.datas);

                // Check if res.data.datas exists and has email property
                if (res.data.datas && res.data.datas[0].email !== undefined) {
                    setEmail(res.data.datas[0].email);
                }

            });

        // Fetch customer info
        axios.post(`${API_BASE_URL}/get_customer_info`, {id})
            .then(res => {
                if (res.data[0]) {
                    setBusinessData(res.data[0])
                    setConsultantEmail(res.data[0].email)
                    setEmail(res.data[0].mail)
                    setSiteUrl(res.data[0].url)
                    console.log(res.data)
                    setFormData(res.data[0])
                    setCustomerName1(res.data[0].name_1);
                    setCustomerName2(res.data[0].name_2);
                    setIsChecked(res.data[0].avis === 1);
                    setActiveUser(res.data[0])
                    document.title = `X | Client - ${res.data[0].name_1}`;
                }
            });

        // Fetch customer positions
        axios.post(`${API_BASE_URL}/get_customer_positions`, {id})
            .then(res => {

                console.log(res)
                setPositions(res.data);
                setTotalPositions(res.data);
                setTotalPositionsSearch(res.data);
                setIsLoading(false)
            });
        // Fetch customer positions
        axios.post(`${API_BASE_URL}/get_contact_people`, {id})
            .then(res => {
                setPositions(res.data);
                setTotalPositions(res.data);
                setTotalPositionsSearch(res.data);
                setIsLoading(false)
            });

        // Récupère les relations du client
        axios.post(`${API_BASE_URL}/get_customers_relations`, {id})
            .then(res => {
                console.log('ok2')
                console.log(res)
                setContactPeople(res.data.data);
                console.log(res.data)
                setIsLoading(false)
            });
    }, []);


    const handleChangeEmail = (val) => {
        setEmail(val)
    }

    const validateForm = () => {
        if (formData.subject === '') {
            alert("Vous devez sélectionner un modèle d'email");
            return false;
        }

        if (formData.subject === 'Votre nouveau site internet est en ligne' && formData.url === "") {
            alert("Vous devez remplir le champ URL pour cette demande");
            return false;
        }

        return true;
    };

    // Updated handleToggleTech that updates the parent state based on the selected option
    const handleToggleTech = (option) => {
        console.log('Selected Option:', option);
        setActiveTechOption(option.id);
    };

    const techComponents = {
        resume: <SectionTechniqueDetailsResume/>,
        contact_technique: <SectionTechniqueDetailsContactTechnique/>,
        hebergeur: <SectionTechniqueDetailsHebergeur/>,
        emails: <SectionTechniqueDetailsEmails/>,
        zoneDNS: <SectionTechniqueDetailsDNSZone/>,
        domain: <SectionTechniqueDetailsDomaine/>
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Voulez-vous vraiment envoyer la demande d'avis à ${email} (en copie à ${consultantEmail})`)) {

            const data = {
                consultant: null,
                clientID: id,
                email: email,
                consultantEmail: consultantEmail,
                subject: formData.subject,
                contentFile: formData.contentFile,
                clientName: formData.clientName,
                url: siteUrl
            };

            console.log('ok')

            axios.post(`${API_BASE_URL}/send_mail_avis`, data)
                .then(response => {
                    console.log(response);
                    if (response.data.status === 'ok') {
                        setMessageState('<i class="fa-solid fa-check"></i>');

                        setAvis(response.data.data)

                        // Revert to the initial state after 3 seconds
                        setTimeout(() => {
                            setMessageState("Envoyer la demande d'avis");
                        }, 3000);
                    }
                    setFeeback(response.data);
                })
                .catch(error => {
                    alert('Erreur lors de l\'envoi : ', error);
                });
        }
    };

    const handleClick = (tab) => {
        setActiveTab(tab);
    };
    const handleUserClick = (user) => {
        console.log(user)
        setActiveUser(user);
    }

    return (
        <div className="md-dashboard-container p-0">
            <aside className="md-sidebar">
                <div className="md-sidebar-top">

                    <nav className="md-nav-links">
                        <ul>
                            <li>
                                <a
                                    className={activeTab === 'home' ? 'md-active' : ''}
                                    onClick={() => handleClick('home')}
                                >
                                    <h4 className={"nav-link"}>
                                        Infos Bexio
                                    </h4>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={activeTab === 'details' ? 'md-active' : ''}
                                    onClick={() => handleClick('details')}
                                >
                                    <h4 className={"nav-link"}>
                                        Détails entreprise
                                    </h4>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={activeTab === 'products' ? 'md-active' : ''}
                                    onClick={() => handleClick('products')}
                                >
                                    <h4 className={"nav-link"}>
                                        Produits
                                    </h4>
                                </a>
                            </li>
                            <li>
                                <a
                                    className={activeTab === 'avis' ? 'md-active' : ''}
                                    onClick={() => handleClick('avis')}
                                >
                                    <h4 className={"nav-link"}>
                                        Livraison/Avis
                                    </h4>
                                </a>
                            </li>
                            {/*}
                            <li>
                                <a
                                    href="#overview"
                                    className={activeTab === 'subscriptions' ? 'md-active' : ''}
                                    onClick={() => handleClick('subscriptions')}
                                >
                                    <h4 className={"nav-link"}>

                                        Abonnements
                                    </h4>
                                </a>
                            </li>

                            <li>

                                <a
                                    href="#customer-list"
                                    className={activeTab === 'invoices' ? 'md-active' : ''}
                                    onClick={() => handleClick('invoices')}
                                >

                                    <h4 className={"nav-link"}>Factures

                                    </h4></a>
                            </li>*/}
                            <li>

                                <a
                                    className={activeTab === 'tickets' ? 'md-active' : ''}
                                    onClick={() => handleClick('tickets')}
                                >
                                    <h4 className={"nav-link"}>Tickets
                                    </h4>
                                </a>
                            </li>
                            <li>

                                <a
                                    className={activeTab === 'tech' ? 'md-active' : ''}
                                    onClick={() => handleClick('tech')}
                                >
                                    <h4 className={"nav-link"}>Informations techniques</h4>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>

            {/* MAIN CONTENT */}
            <div className="md-main-content">
                {/* TOP BAR */}
                <header className="md-top-bar">
                    <div className="md-search-bar">
                        <strong>{customerName1} {customerName2}</strong>
                    </div>
                </header>

                {activeUser === 'details' &&
                    <InfosBexio businessData={businessData} activeUser={activeUser} handleUserClick={handleUserClick} contactPeople={contactPeople} />
                }

                <div className="md-content-layout">
                    {/* USER LIST */}
                    {(activeTab !== 'tickets' && activeTab !== "products" && activeTab !== "avis" && activeTab !== "details") &&

                        <section className="md-user-list">

                            {(activeTab === 'tech') ?
                                <SectionTechnique data={techComponents} onOptionSelect={handleToggleTech}/>
                                :
                                <InfosBexio businessData={businessData} activeUser={activeUser} handleUserClick={handleUserClick} contactPeople={contactPeople} />
                                }
                        </section>}

                    {modalTickets && <TicketModal closeModal={() => setModalTickets(false)}/>}
                    {/* USER DETAIL */}
                    {activeTab === 'home' && <div className={"display-flex mb-40 no-radius-top "}>
                        {/* Fiche client */}

                        <div className={"flex"}>
                            <div>
                                <div className={`display-flex justify-content-space-between p-20 bg-white`}>

                                    <div className={'w-100 gap-20 display-flex flex-direction-column'}>

                                        {activeUser.customer_id === id &&
                                            <>
                                                <div className={"display-flex flex-direction-row"}>
                                                    <span className="icon-info-client display-flex">
                                                        <i className="fa-sharp fa-solid fa-location-dot color-third"></i>
                                                    </span>
                                                    <div className={"display-flex flex-direction-column gap-5"}>
                                                        {businessData.name_1 && <p>{businessData.name_1}</p>}
                                                        {businessData.name_2 && <p>{businessData.name_2}</p>}
                                                        {businessData.postcode &&
                                                            <p>{businessData.postcode} {businessData.city}</p>}
                                                    </div>

                                                </div>
                                                {businessData.adresse &&
                                                    <p style={{marginLeft: "40px"}}>
                                                        {businessData.adresse}
                                                    </p>}
                                                {businessData.mail &&
                                                    <div className={"display-flex flex-direction-row"}>
                                                        <span
                                                            className="icon-info-client display-flex align-items-center">
                                                            <i className="fa-solid fa-envelope mr-10 color-third"/>
                                                        </span>
                                                        <p>{businessData.mail}</p>
                                                    </div>
                                                }

                                                {businessData.phone_fixed &&
                                                    <div className={"display-flex flex-direction-row"}>
                                                        <span
                                                            className="icon-info-client display-flex align-items-center">
                                                            <i className="fa-solid fa-phone mr-10 color-third"/>
                                                        </span>
                                                        <p>{businessData.phone_fixed}</p>
                                                    </div>
                                                }


                                                {businessData.branch_names && (
                                                    <div className={"display-flex flex-direction-row"}>
                                                        <span
                                                            className="icon-info-client display-flex align-items-center">
                                                            <i className="fa-solid fa-building mr-10 color-third"/>
                                                        </span>
                                                        <div className={"display-flex gap-10 flexwrap-wrap"}>
                                                            {businessData.branch_names.split(',').map((branch, index, branches) => (
                                                                <span key={index}
                                                                      className="background-primary p-5-10 rounded gap-10">
                                                                    {branch.trim()}
                                                                    {index !== branches.length - 1}
                                                                </span>))}
                                                        </div>
                                                    </div>
                                                )}

                                                {businessData.firstname && (
                                                    <div className={"display-flex flex-direction-row"}>
                                                        <span
                                                            className="icon-info-client display-flex align-items-center">
                                                            {businessData.salutation_type === 'male' ?
                                                                <i className="mr-10 color-third fa-solid fa-user-tie-hair"></i>
                                                                :
                                                                <i className="mr-10 color-third fa-solid fa-user-tie-hair-long"></i>
                                                            }
                                                        </span>
                                                        <div className={"display-flex gap-10 flexwrap-wrap"}>
                                                             <span>
                                                                {businessData.firstname}&nbsp;{businessData.lastname}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        }

                                        {activeUser.phone_mobile && (<p>
                                            <a style={{textDecoration: "none"}}
                                               href={"tel:" + activeUser.phone_fixed}>
                                                <span className={'icon-info-client'}>
                                                    <i className="fa-solid fa-phone mr-10 color-third"/>
                                                </span>
                                                {activeUser.phone_mobile}
                                            </a>
                                        </p>)}

                                        {activeUser.mail && (<p>
                                            <a style={{textDecoration: "none"}} href={"mailto:" + activeUser.mail}>
                                                <span className={'icon-info-client'}>
                                                    <i className="fa-solid fa-envelope mr-10 color-third"/>
                                                </span>
                                                <span>{activeUser.mail}</span>
                                            </a>
                                        </p>)}

                                        {businessData.branch_name && (<p>
                                            <span className={'icon-info-client'}>
                                                <i className="fa-solid fa-industry color-third"/>
                                            </span>
                                            <span>{businessData.branch_name}</span>
                                        </p>)}

                                        {businessData.url && (<p>
                                            <a
                                                style={{textDecoration: "none"}}
                                                target="_blank"
                                                href={businessData.url.startsWith("http://") || businessData.url.startsWith("https://") ? businessData.url : `https://${businessData.url}`}
                                                rel="noreferrer"
                                            >
                                            <span className="icon-info-client">
                                                <i
                                                className="fa-solid fa-link color-third"
                                                style={{marginRight: "10px"}}
                                                />
                                            </span>
                                            <span>{businessData.url}</span>
                                            </a>
                                        </p>)}


                                        {/* On check que la vue affichée soit celle client et pas celle de l'entreprise pour pas rajouter encore le nom de la boite */}
                                        {((activeUser.name_1 || activeUser.name_2) && !activeUser.customer_id) && (
                                            <p className={"m-0"}>
                                            <span style={{textDecoration: "none"}}>
                                                <span className={'icon-info-client'}>
                                                <i className="fa-solid fa-user color-third"
                                                   style={{
                                                       marginRight: "10px"
                                                   }}/>
                                                </span>
                                                <span>{activeUser.name_1} {activeUser.name_2}</span>
                                            </span>
                                            </p>)}


                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    }
                    {activeTab === 'details' &&
                        <DetailsEntreprise id={id}/>
                    }
                    {activeTab === 'overview' &&

                        <section className="md-user-detail">
                            {activeUser && (<>
                                <div className="md-detail-header">
                                    <img
                                        src={activeUser.avatar}
                                        alt={`${activeUser.name} avatar`}
                                        className="md-detail-avatar"
                                    />
                                    <div className="md-detail-user-info">
                                        <h3>{activeUser.name}</h3>
                                        <p className="md-detail-username">{activeUser.username}</p>
                                    </div>
                                    <div className="md-detail-actions">
                                        <button className="md-action-btn md-follow">Follow</button>
                                        <button className="md-action-btn md-message">Message</button>
                                    </div>
                                </div>

                                <div className="md-detail-body">
                                    <div className="md-private-note">
                                        <label htmlFor="private-note">Private note</label>
                                        <textarea
                                            id="private-note"
                                            rows="4"
                                            placeholder="Add your private note here..."
                                        />
                                    </div>

                                    <div className="md-product-history">
                                        <h4>Product info</h4>
                                        <div className="md-product-item">
                                            <div className="md-product-details">
                                                <img
                                                    src="https://via.placeholder.com/40"
                                                    alt="Product"
                                                    className="md-product-img"
                                                />
                                                <div>
                                                    <p className="md-product-name">Product title</p>
                                                    <p className="md-product-price">$84.00</p>
                                                </div>
                                            </div>
                                            <p className="md-product-date">Apr 5, 2023</p>
                                        </div>
                                        {/* Add more product items as needed */}
                                    </div>
                                </div>
                            </>)}
                        </section>}

                    {activeTab === 'subscriptions' &&
                        <div className={"text-left mb-50 flex-direction-column display-flex "}>
                            <h3 className={"color-third mb-10"}>Statuts des abonnements en cours</h3>
                            <div className={"display-flex flex-direction-row gap-20 flexwrap-wrap"}>

                                {websites.map((website, index) => (<React.Fragment key={index}>
                                    <RenewalCustomerCard data={website} type="website"
                                                         handleToggle={() => handleToggleRenewal(website)}
                                                         isChecked={isChecked}/>
                                </React.Fragment>))}
                                {posts.map((post, index) => (<React.Fragment key={index}>
                                    <RenewalCustomerCard data={post} type="post"
                                                         handleToggle={() => handleToggleRenewal(post)}
                                                         isChecked={isChecked}/>
                                </React.Fragment>))}
                            </div>

                        </div>}

                    {activeTab === 'avis' &&


                        <div className={"flex p-20 client-compo-right radius-bottom-right-10"}>
                            <div className={"text-left mb-50 flex-direction-column display-flex "}>
                                <h3 className={"color-third  mb-10"}>Gestion des avis</h3>
                                <div>
                                    <span>Statut de l'avis client sur Google</span>
                                </div>
                                <label className="switch mt-20">
                                    <input type="checkbox" checked={isChecked} onChange={handleToggle}/>
                                    <span className="slider"></span>
                                </label>
                                <div className={"mt-10"}>

                                <span className={"mt-10"} style={{fontStyle: "italic", fontSize: ".8rem"}}>
                                    Si la case est cochée, le client a déjà laissé son avis sur Google.
                                </span>
                                </div>
                            </div>

                            <div className={"text-left mb-50"}>
                                <h3 className={"color-third  mb-10"}>Gestionnaire d'envoi des avis (fiche Google)</h3>
                                <form onSubmit={handleSubmit}>
                                    <label><i>Pour mettre plusieurs emails, les séparer par une virgule.</i></label>
                                    <div className={"display-flex gap-10 mt-20 btn-avis-container-global"}>
                                        <div className={"btn-avis-container mb-20"}>
                                            <div className={"display-grid text-left"}>
                                                <input
                                                    className={"text-center input-base p-15 "}
                                                    name="clientEmail"
                                                    value={email}
                                                    onChange={e => handleChangeEmail(e.target.value)}
                                                    placeholder={"Email client (aucun trouvé)"}
                                                    style={{minWidth: 300}}
                                                />
                                            </div>
                                            <div className={""}>
                                                <select
                                                    id="subject"
                                                    name="subject"
                                                    className={"text-center input-base p-15 "}
                                                    value={formData.subject}
                                                    onChange={handleTemplateChange}
                                                    style={{minWidth: 300}}
                                                >
                                                    <option value="">Choisir un template d'email</option>
                                                    <option value="Demande d'avis">Demande d'avis</option>
                                                    <option value="Rappel demande d'avis">Rappel demande d'avis
                                                    </option>
                                                    <option value="Votre nouveau site est en ligne">Votre nouveau
                                                        site
                                                        internet
                                                        est en
                                                        ligne
                                                    </option>
                                                    <option value="Vos supports de communication sont prêts">Vos
                                                        supports de
                                                        communication sont prêts
                                                    </option>
                                                    <option value="Votre présence sur les réseaux sociaux">Votre
                                                        présence
                                                        sur
                                                        les
                                                        réseaux sociaux
                                                    </option>
                                                    <option value="Lancement de votre campagne Google Ads">Lancement
                                                        de
                                                        votre
                                                        campagne
                                                        Google Ads
                                                    </option>
                                                </select>
                                            </div>
                                            {(selectedTemplate === "Votre nouveau site est en ligne") && (
                                                <div className={"display-grid text-left"}>
                                                    <input
                                                        className={"text-center input-base p-15 "}
                                                        name="siteUrl"
                                                        value={siteUrl}
                                                        required={true}
                                                        onChange={handleSiteUrlChange}
                                                        placeholder={"URL du site"}
                                                        style={{minWidth: 300}}
                                                    />
                                                </div>)}
                                        </div>

                                    </div>
                                    <div className={"btn-avis-container w-100"}>
                                        <button type={"submit"}
                                                className={"btn-submit-account strong m-0 p-15 display-flex justify-content-center mt-20 min-w-200"}
                                                dangerouslySetInnerHTML={{__html: messageState}}></button>
                                    </div>
                                </form>
                            </div>

                            <div className={"text-left mb-50"}>
                                <h3 className={"color-third mb-10"}>Demandes d'avis envoyées</h3>
                                {avis ? avis.map((el, index) => (<div key={index}>
                                    <span><strong>{el.type}</strong> envoyé le {formatDateBeautiful(el.created_date)} à {el.email}</span>
                                </div>)) : <span>Aucune demande d'avis n'a pour le moment été envoyée.</span>}
                            </div>
                        </div>}

                    {activeTab === 'products' && <div className={"display-flex flex-direction-column w-100 m-20"}>

                        {console.log(totalPositionsSearch)}


                        <div className="md-search-bar mb-20">
                            <input onChange={handleSearchChange} type="text"
                                   placeholder="Rechercher un produit"/>
                        </div>
                        <div className={"w-100"}>
                            <table className="md-table">
                                <thead className="md-table-head">
                                <tr className="md-table-row">
                                    <th className="md-table-header" style={{borderRadius: "10px 0 0 0"}}>Nom du projet
                                    </th>
                                    <th className="md-table-header">Montant total</th>
                                    <th className="md-table-header" style={{borderRadius: "0 10px 0 0"}}>Date de
                                        création
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="md-table-body">
                                {totalPositionsSearch.map((position) => (
                                    <tr
                                        key={position.id}
                                        className="md-table-row"
                                        onClick={() => window.open(`/positions/${position.id}`, '_blank')}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <td
                                            className="md-table-cell"
                                            dangerouslySetInnerHTML={{__html: position.title}}
                                        />
                                        <td className="md-table-cell">{position.position_total} CHF</td>
                                        <td className="md-table-cell">{formatDateDDMMYYYY(position.order_start_date)}</td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>

                        </div>

                    </div>}
                    {activeTab === 'tech' && <div className={"display-flex flex-direction-column w-100 m-20"}>

                        <SectionTechniqueDetails data={techComponents} selectedOption={activeTechOption}/>

                    </div>}
                    {activeTab === 'tickets' && <div className={"display-flex flex-direction-column w-100 m-20"}>

                        <div className={"display-flex gap-20 mb-20"}>
                            <div className="md-search-bar">
                                <input onChange={handleSearchTicketChange} type="text"
                                       placeholder="Rechercher un ticket"/>
                            </div>
                            <div className={"display-flex"}>
                                <button className={"bg-primary color-white cursor-pointer p-5-10"}
                                        onClick={toggleModalTicket}><i
                                    className={"fas fa-regular fa-plus mr-10"}/> Créer
                                </button>
                            </div>

                        </div>


                        <div className={"w-100"}>
                            <table className="md-table">
                                <thead className="md-table-head">
                                <tr className="md-table-row">
                                    <th className="md-table-header">#</th>
                                    <th className="md-table-header">Type</th>
                                    <th className="md-table-header">Département</th>
                                    <th className="md-table-header">Date de création du ticket</th>
                                </tr>
                                </thead>

                                {totalTicketsSearch.length === 0 &&
                                    <div className={"m-20 text-center"}><span className={"m-20"}>Ce client n'a pas encore de ticket.</span>
                                    </div>}
                                <tbody className="md-table-body">
                                {totalTicketsSearch.map((position) => (<tr
                                    key={position.id}
                                    className="md-table-row"
                                    onClick={() => navigate(`/ticket/${position.id}`)}
                                >
                                    <td className={"md-table-cell"}>{position.id}</td>
                                    <td className="md-table-cell">{position.ticket_type_name}</td>
                                    <td className="md-table-cell">{position.department_name}</td>
                                    <td className="md-table-cell">{formatDateDDMMYYYY(position.created_at)}</td>
                                </tr>))}
                                </tbody>
                            </table>

                        </div>

                    </div>}
                </div>
            </div>

            {/*<div className={""}
                 style={(totalPositions.length === 0 && !isLoading) ? {
                     backgroundColor: "#ACDCE6", height: "calc(100vh - 80px)", borderRadius: 10, padding: 20
                 } : {}}>
                <div className={'display-flex flex-direction-column'}>

                    <div className={"display-flex"}>
                        <NavLink to={'/clients'} className={"mb-20 back-button"}>
                      <span className={"cursor-pointer display-flex align-items-baseline"}>
                        <i className="fa-sharp fa-left mr-10 "/>Retour
                      </span>
                        </NavLink>
                    </div>
                </div>
            </div>*/}
        </div>


    );
};

export default Client;