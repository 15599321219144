import React, {useState} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CommentsSection = ({comments, onReply, replyTo, comment, setComment, saveComment}) => {

    const groupComments = (comments) => {
        // Filter out only the main comments (comments without a `comment_to`)
        const mainComments = comments.filter(comment => !comment.comment_to);

        // Sort the main comments by date in descending order (most recent first)
        const sortedMainComments = [...mainComments].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
        );

        // Group replies under their respective parent comments
        const grouped = {};

        sortedMainComments.forEach((comment) => {
            grouped[comment.id] = {comment, replies: []};
        });

        // Add replies to their respective parent comments
        comments.forEach((comment) => {
            if (comment.comment_to && grouped[comment.comment_to]) {
                grouped[comment.comment_to].replies.push(comment);
            }
        });

        // Instead of using Object.values, map over the sorted array to maintain order.
        return sortedMainComments.map(comment => grouped[comment.id]);
    };

    const groupedComments = groupComments(comments);
    const [hoveredCommentId, setHoveredCommentId] = useState(null);

    // Quill modules and formats
    const quillModules = {
        toolbar: [
            [{header: [1, 2, false]}],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{list: "ordered"}, {list: "bullet"}],
            ["link", "image"],
            ["clean"],
        ],
    };

    const quillFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "image",
    ];

    return (
        <div className="mb-20">
            {groupedComments.map(({comment, replies}) => (
                <div key={comment.id}>
                    {/* Main Comment */}
                    <div
                        className="ticket-comment display-flex flex-direction-column justify-content-space-between p-0"
                        onMouseEnter={() => setHoveredCommentId(comment.id)}
                        onMouseLeave={() => setHoveredCommentId(null)}
                    >

                        <div className={"p-20"}>
                            <span dangerouslySetInnerHTML={{__html: comment.content}}/>
                        </div>

                        <div className={"display-flex flex-direction-row justify-content-space-between"}
                             style={{borderTop: "solid 1px #ddd"}}>
                            <div className={'p-10'}>
                                <strong><span
                                    dangerouslySetInnerHTML={{__html: comment.username}}/></strong>&nbsp;le&nbsp;
                                <span>{new Date(comment.date).toLocaleString()}</span>
                            </div>

                            <button
                                title={`Répondre à ${comment.username}`}
                                className="reply-button"
                                onClick={() => {
                                    onReply(comment);
                                }}
                            >
                                <i className="fa-solid fa-arrow-turn-down-left color-third"/>
                            </button>
                        </div>

                    </div>

                    {/* Replies */}
                    {replies.map((reply) => (
                        <div className={"display-flex align-items-flex-end flex-direction-column"} key={reply.id}>

                            <div
                                style={{
                                    marginBottom: 10,
                                    padding: "0 0 0 20px",
                                    borderRadius: "5px",
                                    flexDirection: "row",
                                    display: "flex",
                                    width: "70%",
                                    gap: 20,
                                    justifyContent: "flex-end"
                                }}
                            >
                                <i className="fa-solid fa-arrow-turn-down-right"
                                   style={{display: "flex", alignItems: "center"}}></i>
                                <div style={{
                                    marginLeft: "0 20px",
                                    marginBottom: 10,
                                    padding: "0 20px 20px 20px",
                                    backgroundColor: "#0004ff96",
                                    color: "white",
                                    width: "100%",
                                    borderRadius: "5px",

                                }}>
                                    <span dangerouslySetInnerHTML={{__html: reply.content}}/>
                                    Par <strong><span
                                    dangerouslySetInnerHTML={{__html: reply.username}}/></strong>&nbsp;le&nbsp;
                                    <span>{new Date(reply.date).toLocaleString()}</span>
                                </div>
                            </div>

                        </div>
                    ))}

                    {/* Text Editor for Replying */}
                    {replyTo === comment.id && (
                        <div className="ticket-resolution-notes display-flex "
                             style={{alignItems: "flex-end", flexDirection: "column-reverse"}}>
                            <span className={"my-20 text-right display-flex flex-direction-column"}></span>
                            <div className={"display-flex flex-direction-column"} style={{width: "75%"}}>
                                {/* The form component that we want to scroll to */}
                                <ReactQuill
                                    readOnly={false}
                                    style={{minHeight: 200}}
                                    modules={quillModules}
                                    formats={quillFormats}
                                    theme="snow"
                                    onChange={setComment} // Update the state when the editor content changes
                                />
                                <button
                                    style={{zIndex: 9, marginLeft: 0}}
                                    className={"button-third"}
                                    onClick={saveComment}
                                >
                                    Répondre
                                </button>
                            </div>

                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CommentsSection;