import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../config";
import {useParams} from 'react-router-dom';
import {formatDateBeautiful, formatDateDDMMYYYY} from "../utils/dateUtils";

const ClientCard = ({client, displayButtonFiche, headClasses, contentClasses}) => {

    const {id} = useParams();

    //console.log(id)

    const [tickets, setTickets] = useState([])
    const [isOpenTickets, setIsOpenTickets] = useState(false)
    const [customerNote, setCustomerNote] = useState(client.remarks)

    //console.log(client)

    const toggleTicket = () => {
        setIsOpenTickets((prev) => !prev)
    }

    useEffect(() => {
        if (!displayButtonFiche) {
            axios
                .post(`${API_BASE_URL}/get_customer_note`, {id: id})
                .then((res) => {
                    console.log(res)
                    setCustomerNote(res.data)
                })
                .catch((error) => console.error("Error fetching ticket:", error));
        }


    }, [id]);

    const saveCustomerNote = (e) => {

        axios
            .post(`${API_BASE_URL}/update_customer_note`, {id: client.customer_id, note: e})
            .then((res) => {

                setCustomerNote(e)
            })
            .catch((error) => console.error("Error fetching ticket:", error));
    }

    useEffect(() => {
        console.log(id);
        console.log(displayButtonFiche);

        if (displayButtonFiche) {
            const id = client.contact_id;
            axios
                .post(`${API_BASE_URL}/get_tickets_per_customer_client_query`, {id})
                .then((res) => {
                    setTickets(res.data);
                })
                .catch((error) => console.error("Error fetching ticket:", error));
        } else {
            axios
                .post(`${API_BASE_URL}/get_tickets_per_customer_client_query`, {id})
                .then((res) => {

                    setTickets(res.data);
                })
                .catch((error) => console.error("Error fetching ticket:", error));
        }
    }, [id, displayButtonFiche]);

    return (

        <div className={""}>
            <div>
                <div>
                    <div className={` display-flex color-third p-20 bg-eee ${headClasses}`}>
                        <i className="fa-solid fa-circle-info mr-10" style={{fontSize: "1.4rem"}}/>
                        <h3 className={"color-third m-0 top-0"}>
                            Informations du client
                        </h3>
                    </div>

                    <div className={`${contentClasses}`}>
                        <div className={"separator"}></div>

                        <div className={`display-flex justify-content-space-between p-20  bg-white`} style={{borderRadius: "0 0 10px 10px"}}>

                            <div className={'w-100 gap-10 display-flex flex-direction-column'}>
                                <div className={"display-flex flex-direction-row"}>
                                    <span className={'icon-info-client'}>
                                        <i className="fa-sharp fa-solid fa-location-dot color-third"></i>
                                    </span>
                                    <span>{client.name_1}</span>
                                </div>
                                {client.name_2 && <p style={{marginLeft: "40px"}}>{client.name_2}</p>}
                                <p style={{marginLeft: "40px"}}>{client.address}</p>
                                <p style={{marginLeft: "40px"}}>{client.postcode} {client.city}</p>

                                {client.branch_names && (
                                    <div  className={"my-20 display-flex flex-direction-row"}>
                                        <span className="icon-info-client display-flex align-items-center">
                                          <i className="fa-solid fa-building mr-10 color-third" />
                                        </span>
                                        <div className={"display-flex gap-10 flexwrap-wrap"}>

                                        {client.branch_names.split(',').map((branch, index, branches) => (
                                            <span key={index} className="background-primary p-5-10 rounded gap-10">
                                                {branch.trim()}
                                                {index !== branches.length - 1}
                                            </span>
                                        ))}
                                        </div>
                                    </div>
                                )}

                                {client.phone_fixed && (<p>
                                    <a style={{textDecoration: "none"}} href={"tel:" + client.phone_fixed}>
                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-phone mr-10 color-third"/>
                                    </span>
                                        {client.phone_fixed}
                                    </a>
                                </p>)}

                                {client.mail && (<p>
                                    <a style={{textDecoration: "none"}} href={"mailto:" + client.mail}>
                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-envelope mr-10 color-third"/>
                                    </span>
                                        <span>{client.mail}</span>
                                    </a>
                                </p>)}

                                {displayButtonFiche && (<p>
                                    <a style={{textDecoration: "none"}} target={"_blank"}
                                       href={"https://office.bexio.com/index.php/kb_order/show/id/" + client.order_id}
                                       rel="noreferrer">

                                    <span className={'icon-info-client'}>
                                        <i className="fa-solid fa-link color-third" style={{marginRight: "10px"}}/>
                                    </span>
                                        <span>
                                        Contrat Bexio
                                    </span>
                                    </a>
                                </p>)}


                                {client.branch_name && (<p>
                                <span className={'icon-info-client'}>
                                    <i className="fa-solid fa-industry color-third"/>
                                </span>
                                    <span>{client.branch_name}</span>
                                </p>)}

                                {client.url && (
                                    <p>
                                        <a
                                            style={{ textDecoration: "none" }}
                                            target="_blank"
                                            href={
                                                client.url.startsWith("http://") || client.url.startsWith("https://")
                                                    ? client.url
                                                    : `https://${client.url}`
                                            }
                                            rel="noreferrer"
                                        >
                                          <span className="icon-info-client">
                                            <i
                                                className="fa-solid fa-link color-third"
                                                style={{ marginRight: "10px" }}
                                            />
                                          </span>
                                            <span>{client.url}</span>
                                        </a>
                                    </p>
                                )}


                                {client.contact_firstname && (<p className={"m-0"}>
                            <span style={{textDecoration: "none"}}>
                                <span className={'icon-info-client'}>
                                <i className="fa-solid fa-user color-third"
                                   style={{
                                       marginRight: "10px"
                                   }}/>
                                </span>
                                <span>{client.contact_firstname} {client.contact_lastname}</span>
                            </span>
                                </p>)}


                            </div>

                        </div>

                    </div>
                    <div>

                        {!displayButtonFiche ? (<>
                                <div className={"separator"}></div>
                                <div
                                    onClick={toggleTicket}
                                    className={`display-flex gap-20 color-third p-20 bg-eee ${headClasses} no-radius-bottom`}>
                                    <i className="fa-solid fa-ticket color-third" style={{fontSize: "1.4rem"}}/>
                                    <h3 className={"color-third m-0 top-0"}>
                                        Tickets ({tickets.length})
                                    </h3>
                                </div>
                                {tickets.length > 0 && <>
                                    <div className={"separator"}></div>
                                    <div>

                                        <div
                                            className={"p-15 flex border-radius-10 no-radius-top no-border-top "}>
                                            {tickets.map((ticket) => (

                                                <NavLink to={`/ticket/${ticket.id}`}
                                                         className={"text-decoration-none"}>
                                                    <div
                                                        className={"p-0 w-100 display-flex search-item-found justify-content-space-between align-items-center"}>
                                                        <div
                                                            className={"p-10 display-flex justify-content-space-between align-items-center gap-15 flex-direction-row "}>

                                                            <div
                                                                className={"display-flex flex-direction-row gap-5"}>
                                                             <span
                                                                 className={"ticket-status ticket-span-alias p-5-10 bg-color-admin crop-text"}
                                                                 style={{width: 80, backgroundColor: "#66c4c6"}}>
                                                                {formatDateDDMMYYYY(ticket.created_at)}
                                                            </span>
                                                                <span
                                                                    className={"ticket-status ticket-span-alias p-5-10 bg-color-admin"}
                                                                    style={{
                                                                        width: 80, backgroundColor: ticket.dep_color
                                                                    }}>
                                                                {ticket.alias}
                                                            </span>
                                                                <span
                                                                    className={"ticket-status ticket-span-alias p-5-10"}
                                                                    style={{
                                                                        width: 100, backgroundColor: ticket.status_color
                                                                    }}>
                                                            {ticket.ticket_status_name}
                                                        </span>
                                                            </div>

                                                            <span
                                                                className={"ticket-status-historic-span flex-2 display-flex justify-content-space-between align-items-baseline"}>
                                                            {ticket.title}
                                                        </span>
                                                        </div>
                                                    </div>

                                                </NavLink>))}
                                        </div>
                                    </div>
                                </>

                                }


                                <div className={"separator"}></div>

                                <div className={` display-flex color-third p-20 bg-eee ${headClasses}`}>
                                    <i className="fa-solid fa-notes mr-10" style={{fontSize: "1.4rem"}}/>
                                    <h3 className={"color-third m-0 top-0"}>
                                        Notes générales
                                    </h3>
                                </div>

                                <div className={"separator"}></div>
                                <div className={"p-15 flex border-radius-10 no-radius-top no-border-top "}>

                         <textarea
                             className="position-note-textarea p-5 m-0 no-outline no-border bg-eee"
                             id="position_note"
                             style={{width: "100%", marginTop: 20, backgroundColor: "#eee"}}
                             onChange={(e) => saveCustomerNote(e.target.value)}
                             value={customerNote}
                         />

                                </div>


                            </>

                        ) : (<div className={"container-tickets-card my-20"}>
                            <div
                                onClick={toggleTicket}
                                className={`border-221-1px display-flex color-third p-20 bg-eee border-radius-10 hover-lightgray cursor-pointer ${isOpenTickets ? 'no-radius-bottom' : ''}`}
                            ><i className="fa-solid fa-ticket color-third mr-10"
                                style={{fontSize: "1.4rem"}}/>
                                <h3 className={"title-positions-menus color-third justify-content-space-between"}>
                                    Tickets ({tickets.length})
                                    <i
                                        className={`display-flex align-items-center fa-solid fa-chevron-down arrow-icon ${isOpenTickets ? 'rotate' : ''}`}
                                        style={{marginLeft: "10px"}}
                                    ></i>
                                </h3>
                            </div>
                            <div>

                                {isOpenTickets &&

                                    <div
                                        className={"p-15 flex bg-white border-radius-10 border-solid-1px no-radius-top no-border-top border-221-1px"}>
                                        {tickets.map((ticket) => (

                                            <NavLink to={`/ticket/${ticket.id}`}
                                                     className={"text-decoration-none"}>
                                                <div
                                                    className={"p-0 w-100 display-flex search-item-found justify-content-space-between align-items-center"}>
                                                    <div
                                                        className={"w-100 p-10 display-flex justify-content-space-between align-items-baseline gap-20 flex-direction-row  "}>

                                                                    <span style={{
                                                                        width: 100, backgroundColor: ticket.status_color
                                                                    }}
                                                                          className={"ticket-status ticket-span-alias p-5-10 crop-text"}>
                                                    {ticket.ticket_status_name}
                                                        </span>
                                                        <span
                                                            className={"ticket-status-historic-span flex-2 display-flex justify-content-space-between align-items-baseline crop-text"}>
                                                            {ticket.title}
                                                        </span>
                                                    </div>
                                                </div>

                                            </NavLink>))}
                                    </div>}
                            </div>

                        </div>)}


                    </div>

                </div>


            </div>

        </div>);
};

export default ClientCard;