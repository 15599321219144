import React, { useEffect, useState } from "react";
import '../../assets/css/Ticket.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../../config";
import { formatDateBeautiful } from '../../utils/dateUtils';
import Loading from "../Loading";

const TicketsTable = ({ tickets }) => {
    const navigate = useNavigate();
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios
            .post(`${API_BASE_URL}/get_user_name`, { ids: tickets })

            .catch((error) => console.error("Error fetching ticket:", error))
            .finally(() => setLoading(false)); // Stop loading once data is fetched
    }, [tickets]);
    const handleRowClick = (ticketId) => {
        navigate(`/ticket/${ticketId}`);
    };

    console.log((tickets))

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const sortedAndFilteredTickets = React.useMemo(() => {
        const search = searchTerm.toLowerCase();
        let filteredTickets = tickets.filter(ticket => {
            console.log(search)
            console.log(ticket.assigned_usernames_and_colors)
            if (!search) return true;

            const summary = ticket.ticket_type?.toLowerCase() || "";
            const department = ticket.alias?.toLowerCase() || "";
            const assigned = ticket.assigned_usernames_and_colors
                ? ticket.assigned_usernames_and_colors.map(u => u.username.toLowerCase()).join(" ")
                : "";
            const urgency = ticket.priority_level?.toLowerCase() || "";
            const title = ticket.title?.toLowerCase() || "";
            const status = ticket.status?.toLowerCase() || "";
            const customer_email = ticket.customer_email?.toLowerCase() || "";
            const client = ((ticket.name_1 || "") + " " + (ticket.name_2 || "")).toLowerCase().trim();

            const createdAt = new Date(ticket.created_at).toLocaleString().toLowerCase();

            return (
                summary.includes(search) ||
                department.includes(search) ||
                title.includes(search) ||
                assigned.includes(search) ||
                urgency.includes(search) ||
                status.includes(search) ||
                client.includes(search) ||
                customer_email.includes(search) ||
                createdAt.includes(search)
            );
        });

        if (sortConfig.key) {
            filteredTickets.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Custom sorting for assigned usernames
                if (sortConfig.key === 'assigned') {
                    const getAssignedNames = (ticket) =>
                        ticket.assigned_usernames_and_colors
                            ?.map(u => u.username?.toLowerCase())
                            .sort()
                            .join(', ') || '';
                    aValue = getAssignedNames(a);
                    bValue = getAssignedNames(b);
                }

                console.log(sortConfig.key)
                // Custom sorting for client name
                if (sortConfig.key === 'customer_name1') {
                    const getClientName = (ticket) =>
                        ((ticket.name_1 || '') + ' ' + (ticket.name_2 || '')).toLowerCase().trim();
                    aValue = getClientName(a);
                    bValue = getClientName(b);
                }

                if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
                if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
                return 0;
            });
        }

        return filteredTickets;
    }, [tickets, sortConfig, searchTerm]);

    return (
        <div className="bg-white flex-4 tickets-table-container display-flex flex-direction-column overflow-auto box-shadow p-20 border-radius-10 h-100">
            <div className="search-container-ticket" style={{ marginBottom: "20px" }}>
                <input
                    type="text"
                    placeholder="Rechercher un ticket..."
                    value={searchTerm}
                    autoFocus={true}
                    onChange={handleSearchChange}
                    style={{ width: "100%", padding: "10px", fontSize: "1rem" }}
                />
            </div>
            <div className="tickets-table-container" style={{ overflow: "auto" }}>
                {loading ? (
                    <Loading />
                )  : (
                    <table className="tickets-table">
                        <thead>
                        <tr>
                            <th className={"text-center"} onClick={() => handleSort('ticket_id')}>#</th>
                            <th onClick={() => handleSort('ticket_type')}>Résumé</th>
                            <th onClick={() => handleSort('customer_name1')}>Client</th>
                            <th onClick={() => handleSort('assigned')}>Personne assignée</th>
                            <th onClick={() => handleSort('priority_level')}>Priorité</th>
                            <th onClick={() => handleSort('created_at')}>Création</th>
                            <th onClick={() => handleSort('status')}>État</th>
                            <th onClick={() => handleSort('alias')}>Service concerné</th>
                            <th onClick={() => handleSort('time_elapsed')}>Temps écoulé</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sortedAndFilteredTickets.map((ticket) => (
                            <tr className={"cursor-pointer"} key={ticket.ticket_id}
                                style={ticket.title === null && ticket.ticket_type === null ? {
                                    fontWeight: 700,
                                    backgroundColor: "#ff000033"
                                } : {}}
                                onClick={() => handleRowClick(ticket.ticket_id)}>
                                <td style={{minWidth: 80, textAlign: "center"}}>
                                        <span
                                            className={`text-center`}>
                                            <strong style={{
                                                fontSize: ".9rem",
                                                textAlign: "center"
                                            }}>#{ticket.ticket_id}</strong>
                                        </span>
                                </td>
                                <td>
                                    {ticket.title ||
                                        ticket.ticket_type ||
                                        <>
                                            <i className="fas fa-regular color-red fa-warning"/> Non renseigné
                                        </>
                                    }
                                </td>
                                <td>
                                    {ticket.name_1 && ticket.name_2
                                        ? `${ticket.name_1} ${ticket.name_2}`
                                        : ticket.name_1 || ticket.name_2 || <i>{ticket.customer_email}</i>}
                                </td>
                                <td style={{minWidth: 120}} className="gap-5 ticket-assigned-people text-left">
                                    {ticket.assigned_usernames_and_colors?.map((user, index) => (
                                        <span className="span-ticket-people"
                                              style={{color: user.color, border: `solid 2px ${user.color}`}}>
                                                {user.username}
                                            </span>
                                    ))}
                                </td>
                                <td className="text-left" style={{minWidth: 120}}>
                                    <span style={{backgroundColor: ticket.priority_color}}
                                          className=" ticket-status">
                                        <strong>{ticket.priority_level || "Normal"}</strong>
                                    </span>
                                </td>
                                <td>{formatDateBeautiful(ticket.created_at).toLocaleString() || "N/A"}</td>
                                <td className="text-left" style={{minWidth: 110}}>
                                    <span
                                        style={{backgroundColor: ticket.status_color, color: ticket.status_text_color}}
                                        className={`ticket-status`}>{ticket.status}</span>
                                </td>
                                <td style={{minWidth: 80, maxWidth: 250, textAlign: "left"}}>
                                    <span
                                        style={{backgroundColor: ticket.color}}
                                        className={`ticket-status ticket-span-alias p-5-10`}>
                                        <strong style={{fontSize: ""}}>{ticket.alias}</strong>
                                    </span>
                                </td>
                                <td>
                                    {ticket.created_at ? (
                                        ticket.resolved_at && ticket.resolved_at !== "0000-00-00 00:00:00" ? (
                                            (() => {
                                                const diffMs = new Date(ticket.resolved_at) - new Date(ticket.created_at);
                                                const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
                                                const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                                                const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

                                                const dayText = days > 1 ? `${days} jours` : days === 1 ? `${days} jour` : "";
                                                const hourText = hours > 1 ? `${hours} heures` : hours === 1 ? `${hours} heure` : "";
                                                const minuteText = minutes > 1 ? `${minutes} minutes` : minutes === 1 ? `${minutes} minute` : "";

// Combine non-empty parts with spaces
                                                const timeParts = [dayText, hourText, minuteText].filter(Boolean);
                                                return `Terminé en ${timeParts.join(" ")}`;


                                                return `Terminé en ${dayText}${dayText && hourText ? " " : ""}${hourText}`;
                                            })()
                                        ) : (
                                            `${Math.floor((new Date() - new Date(ticket.created_at)) / (1000 * 60 * 60 * 24))} jours`
                                        )
                                    ) : (
                                        "N/A"
                                    )}
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default TicketsTable;
