import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_BASE_URL} from "../config";
import Tasks from "../components/Tasks";


const ListeTaches = () => {

    const [finishedTasks, setFinishedTasks] = useState([])
    const [finishedFoundTasks, setFinishedFoundTasks] = useState([])
    const [allTasks, setAllTasks] = useState({});

    useEffect(() => {
        document.title = 'X | Mes tâches'
    }, []);

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_all_tasks_by_user`, {user_id: localStorage.user_id})
            .then(res => {
                const tasks = res.data.datas;

                // Separate tasks based on progress
                const finished = tasks.filter(task => parseInt(task.progress, 10) === 100);
                const inProgress = tasks.filter(task => parseInt(task.progress, 10) < 100);

                setFinishedTasks(finished);
                setFinishedFoundTasks(finished);

                console.log(finished)

                setAllTasks( {
                    'finished_tasks': finished,
                    'pending_tasks': inProgress
                });

            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }, []);

    return (<div>
        <h2 className={"color-third"}>Mes tâches</h2>

        <div className={'position-component bg-unset display-flex flex-direction-column gap-20'}>

            <Tasks
                searchBar={true}
                title={"Tâches en cours"}
                searchLabel={"tâches ouvertes trouvées."}
                tasks={allTasks.pending_tasks}
                headers={["Progression", "Nom de la tâche", "Type de projet", "Date de réalisation", "Durée passée", "Terminé le", "Client"]}
                headersData={["progress", "title", "intern_name", "start_date", "time_spent", "finish_date", "name_1"]}
            />
            <Tasks
                searchBar={true}
                searchLabel={"tâches terminées trouvées."}
                title={"Tâches terminées"}
                searchBarDatas={[finishedTasks, setFinishedFoundTasks, finishedFoundTasks]}
                tasks={allTasks.finished_tasks}
                headers={["Nom de la tâche", "Client", "Type de projet", "Terminé le", "Durée passée"]}
                headersData={["title_finished", "name_1", "intern_name", "finished_at", "time_spent"]}
            />
        </div>
    </div>)
};

export default ListeTaches;