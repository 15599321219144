import React from 'react';

const SectionTechniqueDetails = ({ data, selectedOption }) => {
    return (
        <div>
            {data[selectedOption]}
        </div>
    );
};

export default SectionTechniqueDetails;
