import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {API_BASE_URL} from "../../config";

const SectionTechniqueDetailsDomaine = () => {
    let {id} = useParams(); // Get the 'id' parameter from the URL
    id = parseInt(id);
    const [provider, setProvider] = useState('')
    const [tempProvider, setTempProvider] = useState('')
    const [domainName, setDomainName] = useState("")
    const [domainURL, setDomainURL] = useState(null)
    const [domainMail, setDomainMail] = useState("")
    const [selectedValue, setSelectedValue] = useState(null);
    const [toBuy, setToBuy] = useState(false);
    const [otherRegistrar, setOtherRegistrar] = useState(false);
    const [registrarName, setRegistrarName] = useState("");
    const [existingUp, setExistingUp] = useState(false);


    const handleChange = (event) => {
        const value = event.target.value;

        setOtherRegistrar(true)
        setSelectedValue(value);
        console.log("Selected:", value);
    };

    const handleRegistrarName = (e) => {
        console.log(e)
        setRegistrarName(e)
    }
    const handleToBuy = () => {
        console.log(existingUp)
        setExistingUp(false)
        setToBuy(true)
    }

    const handleExisting = () => {
        setToBuy(false)
        setExistingUp(true)
    }

    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_customer_domain/`, {customer_id: id})
            .then(res => {
                const domainData = res.data.results;
                setProvider(domainData);
                setTempProvider(domainData);
                console.log(domainData);
                setToBuy(domainData.to_buy)
                setExistingUp(domainData.existing_up)

                console.log(domainData.name)
                // Domaine géré par SD
                if (domainData.name === "1"){
                    setSelectedValue('option1')
                    setDomainMail(null);
                    setDomainURL(domainData.url);

                } else {
                    setSelectedValue(`option${domainData.name}`);
                    setDomainURL(domainData.url);
                    setDomainMail(domainData.mail);
                    setOtherRegistrar(true)
                    setRegistrarName(domainData.registrar_name)
                }
            })
            .catch(err => console.error('Error fetching DNS zone:', err));
    }, [id]);


    const saveDomain = () => {
        let numeric_domain_id = 0;
        if (selectedValue === 'option1') {
            numeric_domain_id = 1
        }
        if (selectedValue === 'option2') {
            numeric_domain_id = 2
        }
        if (selectedValue === 'option3') {
            numeric_domain_id = 3
        }

        let bool_existingUp = 0;
        let bool_toBuy = 0;

        console.log(existingUp)
        console.log(toBuy)

        if (existingUp === true) bool_existingUp = 1;
        if (toBuy === true) bool_toBuy = 1;

        console.log(domainURL)
        axios.post(`${API_BASE_URL}/set_customer_domain/`, {
            customer_id: id,
            domain_name: numeric_domain_id,
            domain_url: domainURL,
            domain_mail: domainMail,
            registrar_name: registrarName,
            existing_up: bool_existingUp,
            to_buy: bool_toBuy

        })
            .catch(err => console.error('Error fetching DNS zone:', err));
    }

    return (<>
            <div className={"display-flex flex-direction-column gap-20"}>


                <h3>Gestion du domaine</h3>

                <div className={"display-flex flex-direction-column gap-20"}>
                    <label className={`client-radio-label ${selectedValue === 'option1' ? "radio-active" : ""}`}>
                        <input
                            className="client-radio-input"
                            type="radio"
                            name="option1"
                            value="option1"
                            checked={selectedValue === 'option1'}
                            onChange={handleChange}
                        />
                        Solution Digitale
                    </label>

                    {selectedValue === 'option1' &&
                        <div className="display-flex flex-direction-column gap-10 client-options">
                            <div className="display-flex gap-10">
                                <label className={"display-flex"}>
                                    <input
                                        className={"client-radio-input"}
                                        type="radio"
                                        name="clientOption"
                                        value="existing"
                                        checked={existingUp}
                                        placeholder="079/833.35.58"
                                        onChange={() => {handleExisting(); }}
                                        onClick={handleExisting}
                                    />
                                    Up existant
                                </label>
                            </div>
                            <div className="display-flex gap-10">
                                <label className={"display-flex"}>
                                    <input
                                        className={"client-radio-input"}
                                        type="radio"
                                        checked={toBuy}
                                        name="clientOption"
                                        value="toBuy"
                                        onChange={handleToBuy}
                                    />
                                    A acheter
                                </label>
                            </div>


                            {toBuy && (
                                <input
                                    placeholder="https://solution-digitale.ch"
                                    value={domainURL}
                                    onChange={(e) => setDomainURL(e.target.value)}
                                    className="input p-10"
                                />
                            )}
                        </div>}

                    <label className={`client-radio-label ${selectedValue === 'option2' ? "radio-active" : ""}`}>
                        <input
                            className="client-radio-input"
                            type="radio"
                            name="option2"
                            value="option2"
                            checked={selectedValue === 'option2'}
                            onChange={handleChange}
                        />
                        Autre registrar
                    </label>

                    {selectedValue === 'option2' &&
                        <div className={"display-flex flex-direction-column gap-10 client-options"}>

                            {otherRegistrar && (
                                <textarea placeholder={"Infomaniak..."} value={registrarName}
                                       onChange={(e) => handleRegistrarName(e.target.value)}
                                       className={"min-h-400 p-10"} style={{minHeight : "400px!important"}}/>
                                )}
                        </div>}

                    {/*}
                    <label className={`client-radio-label ${selectedValue === 'option2' ? "radio-active" : ""}`}>
                        <input
                            className="client-radio-input"
                            type="radio"
                            name="option2"
                            value="option2"
                            checked={selectedValue === 'option2'}
                            onChange={handleChange}
                        />
                        Client
                    </label>
                    {selectedValue === 'option2' &&
                        <div className={"display-flex flex-direction-column gap-10 client-options"}>
                            <label>Téléphone </label>
                            <input placeholder={"079/833.35.58"} value={domainPhone} className={"input"}
                                   onChange={(e) => handlePhone(e.target.value)}></input>
                            <label>Mail </label>
                            <input placeholder={"production@solution-digitale.ch"} value={domainMail}
                                   onChange={(e) => handleMail(e.target.value)}
                                   className={"input"}></input>
                        </div>}
                    <label className={`client-radio-label ${selectedValue === 'option3' ? "radio-active" : ""}`}>
                        <input
                            className={`client-radio-input `}
                            type="radio"
                            name="option3"
                            value="option3"
                            checked={selectedValue === 'option3'}
                            onChange={handleChange}
                        />
                        Informaticien externe
                    </label>
                    {selectedValue === 'option3' &&
                        <div className={"display-flex flex-direction-column gap-10 client-options"}>
                            <label>Téléphone </label>
                            <input placeholder={"079/833.35.58"} value={domainPhone} className={"input"}
                                   onChange={(e) => handlePhone(e.target.value)}></input>
                            <label>Mail </label>
                            <input placeholder={"production@solution-digitale.ch"} value={domainMail}
                                   onChange={(e) => handleMail(e.target.value)}
                                   className={"input"}></input>
                        </div>}*/}
                </div>


            </div>
            <button className={"button-third mt-20"} onClick={() => saveDomain()}>Sauvegarder</button>
        </>
    )
        ;
};

export default SectionTechniqueDetailsDomaine;