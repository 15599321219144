import React, {useEffect, useState} from 'react';

const MainContent = ({ isVisible, children }) => {
    const [screenWidth, setScreenWidth] = useState('')
    useEffect(() => {
        const handleResize = () => {
                setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize()
    }, [screenWidth]);


    const mainContainerStyle = {
        marginLeft: isVisible ? '400px' : '0', // Set margin-left based on visibility
        borderTopLeftRadius: screenWidth > 1200 ? '15px' : '0'
    };

    return (
        <div className="main-container-composant" style={mainContainerStyle}>
            <div>
                {children}
            </div>
        </div>
    );
};

export default MainContent;
