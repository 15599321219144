import React, { useEffect, useState } from 'react';
import axios from "axios";
import { API_BASE_URL } from "../../config";

const DetailsEntreprise = ({ id }) => {
    const [data, setData] = useState({
        phone_1: '',
        phone_2: '',
        phone_3: '',
        address_1: '',
        address_2: '',
        address_3: '',
        mail_1: '',
        mail_2: '',
        mail_3: '',
        business_name: ''
    });

    // Update state on input changes.
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    // Save function that calls the backend endpoint.
    const handleSave = () => {
        axios
            .post(`${API_BASE_URL}/set_customer_entreprise_details/`, {
                customer_id: id,
                ...data
            })
            .then((res) => {
                console.log('Enterprise details saved:', res.data);
            })
            .catch((err) => console.error('Error saving enterprise details:', err));
    };

    // Fetch customer enterprise details on mount (or when id changes).
    useEffect(() => {
        axios
            .post(`${API_BASE_URL}/get_customer_entreprise_details`, { customer_id: id })
            .then((res) => {
                // If the API returns an object with customer details, set it directly.
                // If it returns an array, you might need to use res.data[0] instead.
                setData(res.data[0]);
                console.log('Fetched enterprise details:', res.data);
            })
            .catch((err) => {
                console.error('Error fetching enterprise details:', err);
            });
    }, [id]);

    return (
        <div style={{ maxWidth: "unset", padding: 20 }} className={"w-100"}>
            <div className="display-flex gap-20 w-100 flex-direction-column mb-20">
                <div className="display-flex flex-direction-column gap-10">
                    <strong className="text-left color-third">Nom de l'entreprise</strong>
                    {console.log(data)}
                    <input
                        type="text"
                        name="business_name"
                        value={data ? data.business_name : ''}
                        onChange={handleChange}
                        placeholder="Solution Digitale SA"
                        className="input p-10"
                    />
                </div>
            </div>
            <div className={"display-flex flex-direction-row w-100 gap-20"}>

            <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                <strong className="text-left color-third">Email 1</strong>
                <input
                    type="text"
                    name="mail_1"
                    value={data && data.mail_1}
                    onChange={handleChange}
                    placeholder="info@solution-digitale.ch"
                    className="input p-10"
                />
            </div>
                <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                    <strong className="text-lef color-third">Email 2</strong>
                    <input
                        type="text"
                    name="mail_2"
                    value={data && data.mail_2}
                    onChange={handleChange}
                    placeholder="info@solution-digitale.ch"
                        className="input p-10"
                />
            </div>
                <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                    <strong className="text-left color-third">Email 3</strong>
                    <input
                        type="text"
                    name="mail_3"
                    value={data && data.mail_3}
                    onChange={handleChange}
                    placeholder="info@solution-digitale.ch"
                        className="input p-10"
                />
            </div>
            </div>

            <div className={"display-flex flex-direction-row w-100 gap-20"}>
                <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                    <strong className="text-left color-third">Téléphone 1</strong>
                    <input
                        type="text"
                        name="phone_1"
                        value={data &&data.phone_1}
                        onChange={handleChange}
                        placeholder="021/907.87.87"
                        className="input p-10"
                    />
                </div>
                <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                    <strong className="text-left color-third">Téléphone 2</strong>
                    <input
                        type="text"
                        name="phone_2"
                        value={data &&data.phone_2}
                        onChange={handleChange}
                        placeholder="021/907.87.87"
                        className="input p-10"
                    />
                </div>
                <div className="text-left mb-20 display-flex flex-direction-column gap-10 flex">
                    <strong className="text-left color-third">Téléphone 3</strong>
                    <input
                        type="text"
                        name="phone_3"
                        value={data &&data.phone_3}
                        onChange={handleChange}
                        placeholder="021/907.87.87"
                        className="input p-10"
                    />
                </div>
            </div>


            <div className="text-left mb-20 display-flex flex-direction-column gap-10">
                <strong className="text-left color-third">Adresse 1</strong>
                <textarea
                    type="text"
                    name="address_1"
                    value={data &&data.address_1}
                    onChange={handleChange}
                    placeholder="Route de Grivaz 17, 1607 Palézieux"
                    className="input p-10"
                    style={{minHeight: 150}}
                />
            </div>
            <div className="text-left mb-20 display-flex flex-direction-column gap-10">
                <strong className="text-left color-third">Adresse 2</strong>
                <textarea
                    type="text"
                    name="address_2"
                    value={data &&data.address_2}
                    onChange={handleChange}
                    placeholder="Route de Grivaz 17, 1607 Palézieux"
                    className="input p-10"
                    style={{minHeight: 150}}
                />
            </div>
            <div className="text-left mb-20 display-flex flex-direction-column gap-10">
                <strong className="text-left color-third">Adresse 3</strong>
                <textarea
                    type="text"
                    name="address_3"
                    value={data &&data.address_3}
                    onChange={handleChange}
                    placeholder="Route de Grivaz 17, 1607 Palézieux"
                    className="input p-10"
                    style={{minHeight: 150}}
                />
            </div>

            <button className="button-third" onClick={handleSave}>
                Enregistrer
            </button>
        </div>
    );
};

export default DetailsEntreprise;
