import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {API_BASE_URL} from "../config";

const OpenPositions = ({id}) => {

    const [openPositions, setOpenPositions] = useState([]);

    const user_id = id.contact_id;
    useEffect(() => {
        axios.post(`${API_BASE_URL}/get_open_positions/`, {user_id})
            .then(res => {
                setOpenPositions(res.data)
            })
    }, [id, user_id]);

    return (
        <div className={"p-15 flex bg-white border-radius-10 border-solid-1px no-radius-top no-border-top"} style={{borderColor: "#ddd"}}>

            <div className={" w-100"}>
                {
                    openPositions.map((el, index) => (

                            <div className={"positions-list-item p-10 border-radius-5 display-flex flex-direction-column finished-projects"}>

                                <Link to={'/positions/' + el.id} className={"w-100"} key={index} style={{textDecoration: "none", color: "var(--third)"}} >
                                <i className="fa-solid fa-link" style={{marginRight: "10px"}}></i>
                                <span>{el.id} </span>

                                <span className={"w-100 crop-text"}
                                   dangerouslySetInnerHTML={{__html: el.title}}/>

                                </Link>

                            </div>

                        )
                    )
                }
            </div>
        </div>
    );
};

export default OpenPositions;