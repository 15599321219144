import React from 'react';
import '../assets/css/ReportingCard.css';

const ReportingCard = ({ header, data, icon }) => {
    return (
        <div className="reporting-card h-100">
                <div className="display-flex flex-direction-column text-container h-100">
                    <div className={"flex-1 pt-10 pb-10"}>
                        {icon && (
                            <i
                                className={`fa-solid ${icon} m-10 reporting-icon`}
                                style={{ color: "var(--secondary)" }}
                            />
                        )}

                        {data && (
                            <h3
                                className="m-5 color-third title-label-reporting data "
                                style={{ fontWeight: 900 }}
                            >
                                {data}
                            </h3>
                        )}
                    </div>


                    {/* Separator */}
                    <div className="separator"></div>

                    {header && (
                        <div className={"flex-1 bg-eee  align-items-center display-flex p-10 justify-content-center"} style={{borderRadius: "0 0 5px 5px"}}>

                        <h4 className="text-primary header card-reporting color-third mb-0 m-0" style={{fontFamily: "Montserrat, sans-serif"}}>
                            {header}
                        </h4>
                        </div>
                    )}
                </div>
            </div>
    );
};

export default ReportingCard;
